<template>
    <div class="home">
        <vue-headful title="Юридический прогноз | Главная"
                     description="Аисуд сервис предназначен для расчета сроков или размеров наказания за преступления, предусмотренные Уголовным Кодексом РФ."
                     keywords="page.keywords"/>
        <div>
            <Carousel/>
        </div>
        <div style="margin-top: 2.5rem">
              <b-embed
                type="iframe"
                aspect="16by9"
                src="https://www.youtube.com/embed/oJQXxeYbY9s"
                allowfullscreen>
            </b-embed>
        </div>
    </div>
</template>

<script>
    import Carousel from "../components/Carousel";
    export default {
        name: "home",
        components: {
            Carousel
        },
        data(){
            return{
                page:{
                    title:'Юридический прогноз | Главная',
                    description: 'Аисуд сервис предназначен для расчета сроков или размеров наказания за преступления, предусмотренные Уголовным Кодексом РФ.',
                    keywords: "Aisud, Айсуд, Аисуд, Иисуд, Юридический прогноз, Уголовная ответственность, Уголовный Кодекс, " +
                     "Уголовное дело, Лишение свободы, Штраф, Прекращение уголовного дела, Освобождение от уголовной " +
                     "ответственности, Кража, Мошенничество, Разбой, Грабеж, Убийство, Наркотики, Рецидив, Судимость",
                },
                slide: 0,
                sliding: null
            }
        },
        methods:{
             onSlideStart: function() {
                this.sliding = true
            },
            onSlideEnd: function() {
                this.sliding = false
            }
        }
    }
</script>

<style scoped>

    h1{
        text-transform: uppercase;
        font-weight: bold;
        font-family: Slovic;
        margin-top: 1rem;
    }

    h5{
        font-family: Oswald;
        font-weight: bold;
        font-size: 1.4rem;
    }

    .container{
        padding-right: 0;
        padding-left: 0;
        padding: 0;
    }
    .jumbotron {
        padding: 1rem 2rem;
        background-color: #ffffff;
    }

</style>
