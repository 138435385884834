<template>
  <div id="app" >
    <vue-headful :title="page.header.title"
                 :description="page.header.description"
                 :keywords="page.header.keywords"/>
    <!-- navbar -->
    <NavBar></NavBar>
      <div class="container">
        <transition name="moveInUp">
          <router-view/>
        </transition>
    </div>
    <Footer />
    <!-- Метрики -->
    <Metriks />
  </div>
</template>

<script>
  import NavBar from './components/NavBar'
  import Footer from './components/Footer'
export default {
  name: "app",
  components: {
    NavBar,
    Footer
  },

  data(){
    return{
      page:{
        header:{
          title:'Юридический прогноз | Главная',
          description: 'Static description',
          keywords: 'keywords',
        },
        content: "",
      },
      Token: "",
    }
  },
};
</script>

<style>

  @font-face{
    font-family: 'Slovic';
    src: url('assets/fonts/Slovic_Demo-SemiSerif.ttf');
    font-weight: normal;
    font-style: normal;
  }

  @font-face{
    font-family: 'Oswald';
    src: url('assets/fonts/Oswald-Light.ttf');
    font-weight: normal;
    font-style: normal;
  }

.container{
  padding: .5rem;
}
</style>
