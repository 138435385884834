<template>
<div>
    <div class="not-mobile">
        <b-nav tabs>
            <b-navbar tag="div" toggleable="lg" variant="light">
                <b-navbar-toggle  target="nav-text-collapse"></b-navbar-toggle>
                <b-collapse id="nav-text-collapse" is-nav>
                    <b-navbar justified toggleable="lg" >
                        <b-nav-item href="/general-information" variant="secondary" class="ml-auto">
                            Общая информация
                        </b-nav-item>

                        <b-nav-item href="/user-notification" type="secondary" variant="secondary" >
                            Уведомления пользователя
                        </b-nav-item>

                        <b-nav-item href="/user-guide" variant="secondary">
                            Руководство пользователя
                        </b-nav-item>

                        <b-navbar-item class="chtonepozavivat">
                            <div class="logo">
                                <a href="/home">
                                <img alt="logo" class="d-block mb-4" style="margin: auto;" src="../assets/logo.png">
                            </a>
                            </div>
                            <b-button id="show-btn" @click="showModal" class="btn-sponsor" variant="warning">ПОДДЕРЖАТЬ ПРОЕКТ</b-button>
                        </b-navbar-item>

                        <b-nav-item href="/kodex" variant="secondary">
                            Уголовный Кодекс РФ
                        </b-nav-item>

                        <b-nav-dropdown id="dropd" text="Постановления Пленумов ВС РФ">
                            <b-nav-item class="drop-item" href="/plenums/about-punishment">О наказании</b-nav-item>
                            <b-nav-item class="drop-item" href="/plenums/about-punishment-minor">О наказании несовершеннолетних</b-nav-item>
                            <b-nav-item class="drop-item" href="/plenums/about-of-a-special-order">О применении особого порядка</b-nav-item>
                            <b-nav-item class="drop-item" href="/plenums/about-of-pre-trial-agreement">О применении досудебного соглашения</b-nav-item>
                        </b-nav-dropdown>
                        <b-nav-item href="/feedback" variant="secondary" class="mr-auto">
                            Обратная связь
                        </b-nav-item>
                    </b-navbar>
                </b-collapse>
            </b-navbar>
        </b-nav>
    </div>
    <div class="mobile">
        <b-nav tabs>
            <b-navbar tag="div" toggleable="lg" variant="light">
                <b-navbar-brand class="chtopokazivat">
                    <a href="/home">
                        <img alt="logo" class="logo d-block mb-4 " src="../assets/logo-m.png">
                    </a>
                </b-navbar-brand>
                <b-navbar-brand style="margin-left: -1%;">
                    <b-button id="show-btn" @click="showModal" class="btn-sponsor warning">ПОДДЕРЖАТЬ ПРОЕКТ</b-button>
                </b-navbar-brand>

                <b-navbar-toggle  target="nav-text-collapse"></b-navbar-toggle>
                <b-collapse id="nav-text-collapse" is-nav>
                    <b-navbar justified toggleable="lg" >
                        <b-nav-item href="/general-information" variant="secondary" class="ml-auto">
                            Общая информация
                        </b-nav-item>

                        <b-nav-item href="/user-notification" type="secondary" variant="secondary" >
                            Уведомления пользователя
                        </b-nav-item>

                        <b-nav-item href="/user-guide" variant="secondary">
                            Руководство пользователя
                        </b-nav-item>

                        <b-nav-item href="/kodex" variant="secondary">
                            Уголовный Кодекс РФ
                        </b-nav-item>

                        <b-nav-dropdown id="dropd" text="Постановления Пленумов ВС РФ">
                            <b-nav-item class="drop-item" href="/plenums/about-punishment">О наказании</b-nav-item>
                            <b-nav-item class="drop-item" href="/plenums/about-punishment-minor">О наказании несовершеннолетних</b-nav-item>
                            <b-nav-item class="drop-item" href="/plenums/about-of-a-special-order">О применении особого порядка</b-nav-item>
                            <b-nav-item class="drop-item" href="/plenums/about-of-pre-trial-agreement">О применении досудебного соглашения</b-nav-item>
                        </b-nav-dropdown>
                        <b-nav-item href="/feedback" variant="secondary" class="mr-auto">
                            Обратная связь
                        </b-nav-item>
                    </b-navbar>
                </b-collapse>
            </b-navbar>
        </b-nav>
    </div>

    <b-modal ref="my-modal" hide-footer size="lg" centered="true">
        <div class="d-block text-center">
            <p>Благодарим за то, что решили поддержать «Юридический прогноз»!</p>
            <p>Сервис существует только за счет поддержки пользователей.</p>
            <p>Вы можете поддержать проект, перечислив любую сумму удобным для вас способом:</p>
            <p>1.	По номеру карты: 2202 2061 2783 7917 Мария Александровна Ф.</p>
            <p>2.	По номеру телефона: +7-916-752-0418 Мария Александровна Ф.</p>
        </div>
        <b-button style="width: 40%; margin-left: 30%;" variant="success" block @click="hideModal">Спасибо</b-button>
    </b-modal>
</div>
</template>

<script>
export default {
    name: "NavBar",
    methods: {
        showModal() {
            this.$refs['my-modal'].show()
        },
        hideModal() {
            this.$refs['my-modal'].hide()
        },
    }
}
</script>

<style scoped>

    div {
        color: black;
    }

    .chtopokazivat {
        display: none;
    }
    .btn-sponsor {
        margin-top: -1.5em;
        overflow-wrap: break-word;
        inline-size: auto;
        background-color: rgb(209, 160, 101);
    }
    .btn-sponsor:hover {
        background-color: rgb(196, 142, 77);
    }
    .btn-sponsor:active {
        background-color: rgb(196, 142, 77);
    }
    .btn-sponsor:before {
        background-color: rgb(196, 142, 77);
    }
    .btn-sponsor:active::before {
        background-color: rgb(196, 142, 77);
    }
    .navbar{
        width: 100%;
        padding-right: 4%;
        padding-left: 2%;
        background: rgb(158,144,135);
        background: linear-gradient(180deg, rgba(158,144,135,1) 2%, rgba(255,255,255,1) 100%);
    }
    .nav-tabs .nav-item.show .nav-link {
        border-color: #a5a8a1;
        margin-top: 2px;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        .chtopokazivat {
            display: inline;
        }

        .logo {
            content: normal;
        }
        .navbar{
            width: 100%;
        }
        .chtonepozavivat {
            visibility: hidden;
            display: none;
        }
        .nav-item{
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            width: 90%;
        }
    }
    .navbar-brand{
        margin-left: 2rem;
        padding: 0;
        padding-top: 0.7rem;
    }

    .navbar-toggle{

    }

    .nav{
        border: 0;
    }



    .nav-item:hover{
        background-color: #e9ecef;
        color: black;
    }

    .drop-item{
        margin-right: 0;
        margin-left: 5%;
        width: 90%;
        text-align: center;
    }

    .dropd-menu{
        width: 100%;
    }

    .nav-link {
        color: black;
    }

</style>
