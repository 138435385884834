<template>
    <autocomplete :search="search" style="width: 100%"
                  placeholder="Ввод цифрами без частей или по ключевому слову"
                  :get-result-value="getResultValue"
                  @submit="handleSubmit">
        <template  #result="{ result, props }">
            <li v-bind="props" class="autocomplete-result search-result">
                <div class="search-title" v-html="result.title"/>
                <div class="search-content" v-html="result.content" />
            </li>

        </template>

    </autocomplete>
</template>

<script>
    import Autocomplete from "@trevoreyre/autocomplete-vue";

    export default {
        name: "AutocompleteArticle",
        components: {
            Autocomplete
        },
        props: {
            hostname: String,
            searchAll: Boolean
        },
        methods:{
            search(input) {
                if (input.length < 1) {
                    return []
                } else {
                    var url = `${encodeURI(this.hostname)}/api/getSearchVariants?input=${encodeURI(input)}`;
                    if (this.searchAll == true){
                        url = `${encodeURI(this.hostname)}/api/getSearchVariantsAll?input=${encodeURI(input)}`;
                    }
                    return new Promise(resolve => {
                        fetch(url)
                        .then(response => response.json())
                        .then(data => {
                            resolve(data);
                        })
                    });
                }

            },

            getResultValue(result) {
                return result.title.replace(/<b>/gi, '').replace(/<\/b>/gi, '');
            },

            handleSubmit(result) {
                let value = result.title.replace(/<b>/gi, '').replace(/<\/b>/gi, '');
                this.$emit('updateParent', {
                    value
                });

            }
        }
    }
</script>

<style scoped>
    .search-result {
        border-top: 1px solid #eee;
        padding: 16px;
        background: transparent;
    }

    .search-result:hover {
        border-top: 1px solid #eee;
        padding: 16px;
        background: rgba(130, 130, 143, 0.39);
    }

    .search-title {
        font-size: 16px;
        margin-bottom: 5px;
        color: black;
    }
    .search-content {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.7);
    }

</style>
