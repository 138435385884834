<template>
    <div class="apunishment">
        <vue-headful :title="page.title"
                     :description="page.description"
                     :keywords="page.keywords"/>
        <div class="calibre" id="calibre_link-0">
            <p class="block_">Постановление Пленума Верховного Суда Российской Федерации</p>
            <p class="block_1">"О судебной практике применения законодательства, регламентирующего особенности уголовной ответственности и наказания несовершеннолетних"</p>
            <p class="block_2">(от 1 февраля 2011 г. N 1)</p>
            <p class="block_3">Обсудив результаты обобщения судебной практики по уголовным делам в отношении несовершеннолетних, Пленум Верховного Суда Российской Федерации отмечает, что суды в основном правильно применяют уголовное и уголовно-процессуальное законодательство, общепризнанные принципы и нормы международного права, международные договоры Российской Федерации при рассмотрении уголовных дел и материалов в отношении несовершеннолетних, совершивших преступления, обеспечивая защиту их прав и законных интересов при осуществлении правосудия, а также реализуют предусмотренные законом иные процедуры, направленные на предупреждение правонарушений среди подростков и повышение предупредительного воздействия судебных процессов.</p>
            <p class="block_3">В связи с возникшими у судов вопросами при применении законодательства, общепризнанных принципов и норм международного права, международных договоров Российской Федерации Пленум Верховного Суда Российской Федерации, руководствуясь статьей 126 Конституции Российской Федерации, <b class="calibre1">постановляет:</b></p>
            <p class="block_3">1. Обратить внимание судов на их процессуальную обязанность обеспечения в разумные сроки качественного рассмотрения уголовных дел о преступлениях несовершеннолетних, имея в виду, что их правовая защита предполагает необходимость выявления обстоятельств, связанных с условиями жизни и воспитания каждого несовершеннолетнего, состоянием его здоровья, другими фактическими данными, а также с причинами совершения уголовно наказуемых деяний, в целях постановления законного, обоснованного и справедливого приговора, принятия других предусмотренных законом мер для достижения максимального воспитательного воздействия судебного процесса в отношении несовершеннолетних (статьи 73, 421 УПК РФ).</p>
            <p class="block_3">2. При рассмотрении уголовных дел в отношении несовершеннолетних судам наряду с соблюдением уголовного и уголовно-процессуального законодательства Российской Федерации надлежит учитывать положения Конвенции о защите прав человека и основных свобод (1950 г.), Конвенции о правах ребенка (1989 г.), Минимальных стандартных правил Организации Объединенных Наций, касающихся отправления правосудия в отношении несовершеннолетних (Пекинских правил, 1985 г.), Миланского плана действий и Руководящих принципов в области предупреждения преступности и уголовного правосудия в контексте развития и нового международного экономического порядка (1985 г.), Руководящих принципов Организации Объединенных Наций для предупреждения преступности среди несовершеннолетних (Эр-Риядских руководящих принципов, 1990 г.). Также подлежат учету и другие официальные документы, например Рекомендации N Rec (2003) 20 Комитета Министров Совета Европы государствам-членам о новых подходах к преступности среди несовершеннолетних и о значении правосудия по делам несовершеннолетних.</p>
            <p class="block_3">Если международным договором Российской Федерации установлены иные правила, чем предусмотренные законодательством Российской Федерации, судам в соответствии с требованиями части 3 статьи 1 УПК РФ надлежит применять правила международного договора.</p>
            <p class="block_3">3. Правосудие в отношении несовершеннолетних правонарушителей должно быть направлено на то, чтобы применяемые к ним меры воздействия обеспечивали максимально индивидуальный подход к исследованию обстоятельств совершенного деяния и были соизмеримы как с особенностями их личности, так и с обстоятельствами совершенного деяния, способствовали предупреждению экстремистских противозаконных действий и преступлений среди несовершеннолетних, обеспечивали их ресоциализацию, а также защиту законных интересов потерпевших.</p>
            <p class="block_3">4. Уголовные дела в отношении несовершеннолетних в судах как первой, так и второй инстанций должны рассматриваться наиболее опытными судьями.</p>
            <p class="block_3">В этих целях следует постоянно совершенствовать профессиональную квалификацию судей, рассматривающих дела о преступлениях несовершеннолетних, повышать их личную ответственность за выполнение требований законности, обоснованности, справедливости и мотивированности судебного решения.</p>
            <p class="block_3">Специализация судей по делам несовершеннолетних предусматривает необходимость обеспечения их профессиональной компетентности путем обучения и переподготовки не только по вопросам права, но и по вопросам педагогики, социологии, подростковой психологии, криминологии, виктимологии, применения ювенальных технологий, используемых в рамках процессуального законодательства. В этой связи рекомендовать судам также внедрять современные методики индивидуальной профилактической работы с несовершеннолетними обвиняемыми и подсудимыми.</p>
            <p class="block_3">5. В соответствии со статьями 19, 20 УК РФ, пунктом 1 части 1 статьи 421, статьей 73 УПК РФ установление возраста несовершеннолетнего обязательно, поскольку его возраст входит в число обстоятельств, подлежащих доказыванию, является одним из условий его уголовной ответственности.</p>
            <p class="block_3">Лицо считается достигшим возраста, с которого наступает уголовная ответственность, не в день рождения, а по его истечении, т.е. с ноля часов следующих суток. При установлении возраста несовершеннолетнего днем его рождения считается последний день того года, который определен экспертами, а при установлении возраста, исчисляемого числом лет, суду следует исходить из предлагаемого экспертами минимального возраста такого лица.</p>
            <p class="block_3">6. Заключение под стражу до судебного разбирательства может применяться к несовершеннолетнему лишь в качестве крайней меры и в течение кратчайшего периода времени. При рассмотрении ходатайства органов предварительного следствия о применении в отношении несовершеннолетнего подозреваемого или обвиняемого меры пресечения в виде заключения под стражу суду следует проверять обоснованность изложенных в нем положений о необходимости заключения несовершеннолетнего под стражу и невозможности применения в отношении него иной, более мягкой, меры пресечения.</p>
            <p class="block_3">В соответствии с частями 1 и 2 статьи 108 УПК РФ и частью 6 статьи 88 УК РФ избрание меры пресечения в виде заключения под стражу не допускается в отношении несовершеннолетнего, не достигшего 16 лет, который подозревается или обвиняется в совершении преступлений небольшой или средней тяжести впервые, а также в отношении остальных несовершеннолетних, совершивших преступления небольшой тяжести впервые.</p>
            <p class="block_3">Применение к несовершеннолетнему меры пресечения в виде заключения под стражу возможно лишь в случае, если он подозревается или обвиняется в совершении тяжкого или особо тяжкого преступления, с обязательным указанием правовых и фактических оснований такого решения.</p>
            <p class="block_3">7. Избирая меру пресечения в виде заключения под стражу в отношении несовершеннолетнего подозреваемого или обвиняемого, суду необходимо руководствоваться требованием статьи 423 УПК РФ об обязательном обсуждении возможности применения альтернативной меры пресечения в виде передачи его под присмотр родителей, опекунов, попечителей или других заслуживающих доверия лиц, а находящегося в специализированном детском учреждении - под присмотр должностных лиц этого учреждения (статья 105 УПК РФ).</p>
            <p class="block_3">8. При рассмотрении уголовных дел в отношении несовершеннолетних судам следует иметь в виду, что право на защиту, закрепленное в статье 16 УПК РФ в качестве принципа уголовного судопроизводства, а также право на дополнительные процессуальные гарантии, предусмотренные для несовершеннолетних Уголовно-процессуальным кодексом Российской Федерации, должны обеспечиваться на всех стадиях уголовного процесса в отношении не только подозреваемых, обвиняемых и подсудимых, но и в отношении осужденных несовершеннолетних. Начальный момент, с которого защитник допускается к участию в уголовном деле в отношении несовершеннолетнего, установлен частью 3 статьи 49 УПК РФ.</p>
            <p class="block_3">Право на защиту несовершеннолетние могут осуществлять лично, а также с помощью защитника, законного представителя (часть 1 статьи 16, статьи 48 и 428 УПК РФ). Приглашение, назначение и замена защитника осуществляются в порядке, предусмотренном статьей 50 УПК РФ, с учетом иных норм, устанавливающих дополнительные гарантии реализации права на защиту в отношении несовершеннолетних, действие которых заканчивается по достижении ими восемнадцатилетнего возраста, за исключением случаев, предусмотренных статьей 96 УК РФ.</p>
            <p class="block_3">В соответствии с пунктом 2 части 1 статьи 51 УПК РФ участие защитника при осуществлении уголовного судопроизводства в отношении несовершеннолетних является обязательным. Если защитник не приглашен в порядке, установленном частью 1 статьи 50 УПК РФ, его участие обеспечивает суд.</p>
            <p class="block_3">9. Судам следует иметь в виду, что статья 425 УПК РФ предусматривает обязательное участие педагога или психолога при допросе несовершеннолетнего подозреваемого, обвиняемого, подсудимого в возрасте от 14 до 16 лет, а в возрасте от 16 до 18 лет - при условии, что он страдает психическим расстройством или отстает в психическом развитии. Показания такого подозреваемого, обвиняемого, подсудимого, полученные без участия педагога или психолога, в силу части 2 статьи 75 УПК РФ являются недопустимыми доказательствами.</p>
            <p class="block_3">10. В соответствии со статьей 428 УПК РФ в судебное заседание вызываются законные представители несовершеннолетнего подсудимого, а с учетом обязательности установления условий его жизни и воспитания (пункт 2 части 1 статьи 421 УПК РФ) - представители учебно-воспитательных учреждений или общественных организаций по месту жительства, учебы или работы несовершеннолетнего.</p>
            <p class="block_3">В этих целях суд обязан известить о времени и месте рассмотрения дела в отношении несовершеннолетнего подсудимого предприятие, учреждение и организацию, в которых учился или работал несовершеннолетний, комиссию по делам несовершеннолетних и защите их прав, а при необходимости принять меры к обеспечению явки в суд представителей этих организаций, учебных и трудовых коллективов. Если несовершеннолетний состоял или состоит на учете в психоневрологическом диспансере либо материалы в отношении его рассматривались комиссией по делам несовершеннолетних и защите их прав, суд должен при наличии к тому оснований решить вопрос об их явке.</p>
            <p class="block_3">Законный представитель несовершеннолетнего допускается к участию в уголовном деле в качестве защитника при отсутствии к тому препятствий, предусмотренных законом (статья 72, часть 2 статьи 428 УПК РФ и др.). Если законный представитель несовершеннолетнего допущен в качестве защитника или гражданского ответчика, то он имеет права и несет ответственность, предусмотренные статьями 53 и 54 УПК РФ (часть 4 статьи 428 УПК РФ).</p>
            <p class="block_3">11. При неявке законного представителя в судебное заседание надлежит выяснять причины и при наличии к тому оснований привлекать к участию в деле в качестве законных представителей других лиц из числа указанных в пункте 12 статьи 5 УПК РФ.</p>
            <p class="block_3">Если несовершеннолетний подсудимый не имеет родителей и проживает один или у лица, не назначенного надлежащим образом его опекуном или попечителем, в суд в качестве его законного представителя вызывается представитель органа опеки или попечительства.</p>
            <p class="block_3">Суд вправе отстранить законного представителя несовершеннолетнего подсудимого от участия в судебном разбирательстве, если имеются основания полагать, что его действия наносят ущерб интересам несовершеннолетнего (часть 2 статьи 428 УПК РФ). В этом случае к участию в разбирательстве допускается другой законный представитель несовершеннолетнего из числа лиц, указанных в пункте 12 статьи 5 УПК РФ.</p>
            <p class="block_3">К действиям, наносящим ущерб интересам несовершеннолетнего подсудимого, следует относить невыполнение обязанностей, вытекающих из статуса законного представителя, в том числе по воспитанию несовершеннолетнего, либо уклонение от участия в деле в качестве законного представителя, а равно злоупотребление процессуальными и иными правами, отрицательное влияние на несовершеннолетнего, создание препятствий для выяснения обстоятельств, имеющих значение для дела.</p>
            <p class="block_3">Недопустимо привлечение к участию в деле в качестве законных представителей лиц, которые совершили преступление совместно с несовершеннолетним подсудимым, а также лиц, в отношении которых несовершеннолетний совершил преступление.</p>
            <p class="block_3">12. Если лицо, совершившее преступление в возрасте до 18 лет, на момент рассмотрения дела в суде достигло совершеннолетия, полномочия законного представителя по общему правилу прекращаются. В исключительных случаях реализация этих функций может быть продолжена путем принятия судом решения о распространении на лиц в возрасте от 18 до 20 лет положений об особенностях уголовной ответственности несовершеннолетних. Такое решение может быть принято исходя из характера совершенного этим лицом деяния и данных о его личности (статьи 88, 96 УК РФ) с приведением соответствующих мотивов.</p>
            <p class="block_3">Законные представители несовершеннолетнего, достигшего к моменту производства по делу в судах апелляционной и кассационной инстанций возраста 18 лет, вправе обжаловать судебное решение и принимать участие в судебных заседаниях этих судов (статья 354 УПК РФ).</p>
            <p class="block_3">Жалобы законных представителей в суде второй инстанции подлежат рассмотрению независимо от позиции, занимаемой по делу несовершеннолетним.</p>
            <p class="block_3">13. В соответствии со статьей 428 УПК РФ законный представитель несовершеннолетнего подсудимого вправе давать показания. Суд может принять решение о допросе законного представителя в качестве свидетеля при его согласии, о чем выносит постановление (определение), разъясняя ему права, указанные в части 4 статьи 56 УПК РФ. При допросе законный представитель из числа лиц, указанных в пункте 4 статьи 5 УПК РФ, предупреждается об уголовной ответственности только за дачу заведомо ложных показаний.</p>
            <p class="block_3">14. Вопрос о том, подлежит ли уголовной ответственности лицо, достигшее возраста, предусмотренного частями 1 и 2 статьи 20 УК РФ, но имеющее не связанное с психическим расстройством отставание в психическом развитии, ограничивающее его способность в полной мере осознавать фактический характер и общественную опасность своих действий (бездействия) либо руководить ими, решается судом исходя из положений части 3 статьи 20 УК РФ на основе материального и процессуального закона.</p>
            <p class="block_3">При наличии данных, свидетельствующих об отставании в психическом развитии несовершеннолетнего, в силу статей 195 и 196, части 2 статьи 421 УПК РФ следует назначать комплексную психолого-психиатрическую экспертизу в целях решения вопроса о его психическом состоянии и способности правильно воспринимать обстоятельства, имеющие значение для уголовного дела. При этом перед экспертами должен быть поставлен вопрос о влиянии психического состояния несовершеннолетнего на его интеллектуальное развитие с учетом возраста.</p>
            <p class="block_3">Психическое расстройство несовершеннолетнего, не исключающее вменяемости, учитывается судом при назначении наказания в качестве смягчающего обстоятельства и может служить основанием для назначения принудительных мер медицинского характера (часть 2 статьи 22 УК РФ, часть 2 статьи 433 УПК РФ).</p>
            <p class="block_3">15. Закон не предусматривает применение особого порядка принятия судебного решения в отношении несовершеннолетнего обвиняемого, поскольку производство по уголовному делу в отношении лица, совершившего преступление в несовершеннолетнем возрасте, осуществляется в общем порядке с изъятиями, предусмотренными главой 50 УПК РФ. Указанное положение распространяется на лиц, достигших совершеннолетия ко времени судебного разбирательства.</p>
            <p class="block_3">Если по уголовному делу обвиняется несколько лиц, хотя бы одно из которых является несовершеннолетним, то в случае заявления ходатайства о постановлении приговора без проведения судебного разбирательства и невозможности выделить материалы дела в отношении лиц, заявивших это ходатайство, в отдельное производство, такое уголовное дело в отношении всех обвиняемых должно рассматриваться в общем порядке.</p>
            <p class="block_3">16. Решая вопрос об уголовной ответственности несовершеннолетних и назначении им наказания, судам следует руководствоваться уголовным законом об особенностях их уголовной ответственности и учитывать положения соответствующих международных норм.</p>
            <p class="block_3">В связи с этим в каждом случае подлежит обсуждению вопрос о возможности применения к несовершеннолетнему положений статей 75 - 78 УК РФ (в том числе о примирении с потерпевшим по делам небольшой и средней тяжести) и статей 24 - 28 УПК РФ об освобождении от уголовной ответственности. Следует также учитывать сокращенные сроки давности и сроки погашения судимости, предусмотренные статьями 94 - 95 УК РФ.</p>
            <p class="block_3">17. Судам при назначении наказания несовершеннолетнему наряду с обстоятельствами, предусмотренными статьями 6, 60 УК РФ, надлежит учитывать условия его жизни и воспитания, уровень психического развития, иные особенности личности, а также обстоятельства, предусмотренные статьей 89 УК РФ, в том числе влияние на несовершеннолетнего старших по возрасту лиц. Наказание несовершеннолетнему в виде лишения свободы суд вправе назначить только в случае признания невозможности его исправления без изоляции от общества, с приведением мотивов принятого решения.</p>
            <p class="block_3">Если несовершеннолетнему не может быть назначено наказание в виде лишения свободы, а санкция статьи Особенной части Уголовного кодекса Российской Федерации, по которой он осужден, не предусматривает иного вида наказания, подлежит назначению другой, более мягкий вид наказания с учетом положений статьи 88 УК РФ. В этом случае ссылки на статью 64 УК РФ не требуется.</p>
            <p class="block_3">18. Согласно пункту "е" части 1 статьи 61 УК РФ к обстоятельствам, смягчающим наказание, относится совершение преступления в результате физического или психического принуждения либо иных фактов, связанных с материальной, служебной или иной зависимостью несовершеннолетнего. В связи с этим при установлении факта вовлечения его в совершение преступления взрослыми лицами для оценки этого обстоятельства в качестве смягчающего необходимо принимать во внимание характер применяемого к несовершеннолетнему принуждения.</p>
            <p class="block_3">19. Положения части 1 статьи 62 УК РФ в отношении несовершеннолетнего подлежат применению с учетом требований части 6 статьи 88 УК РФ.</p>
            <p class="block_3">Назначая наказание несовершеннолетнему осужденному за совершенное им преступление по статье Особенной части Уголовного кодекса Российской Федерации, санкция которой предусматривает пожизненное лишение свободы, судам при наличии обстоятельств, предусмотренных пунктами "и" или "к" статьи 61 УК РФ, надлежит руководствоваться правилами части 1 статьи 62 УК РФ. При этом положения части 3 статьи 62 УК РФ не применяются.</p>
            <p class="block_3">20. В соответствии с частью 4 статьи 18 УК РФ судимости за преступления, совершенные лицом в возрасте до 18 лет, не учитываются при признании рецидива преступлений, в том числе в случаях, когда судимость не снята или не погашена. Также не учитываются судимости, снятые или погашенные в порядке, предусмотренном статьей 95 УК РФ.</p>
            <p class="block_3">21. Минимальный размер штрафа, назначенного судом несовершеннолетнему, не может быть меньше одной тысячи рублей либо размера заработной платы или иного дохода несовершеннолетнего за период менее двух недель независимо от наличия у него самостоятельного заработка или имущества, на которое может быть наложено взыскание (часть 2 статьи 88 УК РФ).</p>
            <p class="block_3">В случае злостного уклонения несовершеннолетнего осужденного от уплаты штрафа, назначенного в качестве основного наказания, он в соответствии с частью 5 статьи 46 УК РФ заменяется другим видом наказания в пределах санкции, предусмотренной соответствующей статьей Особенной части Уголовного кодекса Российской Федерации, с учетом положений статьи 88 УК РФ. Штраф, назначенный несовершеннолетнему осужденному, по решению суда может взыскиваться с законных представителей, например с родителей, усыновителей с их согласия. Такое решение может быть принято и по их ходатайству после вступления приговора в законную силу в порядке, предусмотренном статьей 399 УПК РФ. В любом случае суду следует удостовериться в добровольности согласия и платежеспособности таких лиц, а также разъяснить последствия неисполнения судебного решения о взыскании штрафа.</p>
            <p class="block_3">С учетом обстоятельств, предусмотренных частью 3 статьи 46 УК РФ, штраф может быть назначен с рассрочкой выплаты определенными частями на срок до трех лет либо с его отсрочкой на тот же срок по основаниям, предусмотренным статьей 398 УПК РФ.</p>
            <p class="block_3">22. В соответствии с частью 3 статьи 88 УК РФ обязательные работы назначаются несовершеннолетним на срок от сорока до ста шестидесяти часов. При этом дифференцированная ежедневная продолжительность исполнения такого наказания лицами в возрасте от 14 до 15 лет, от 15 до 16 лет и от 16 до 18 лет относится к порядку его исполнения, поэтому указанный вопрос не подлежит отражению в приговоре.</p>
            <p class="block_3">23. Суд, назначая несовершеннолетнему наказание в виде исправительных работ, должен иметь в виду, что данный вид наказания может быть применен лишь к той категории несовершеннолетних осужденных, исправление которых возможно с помощью общественно полезного труда без изоляции от общества. При этом следует учитывать, как назначенное наказание может повлиять на учебу несовершеннолетнего, его поведение в быту, семье.</p>
            <p class="block_3">Назначение наказания в виде исправительных работ в соответствии с частью 4 статьи 88 УК РФ возможно и в отношении несовершеннолетнего, проходящего обучение в общеобразовательных учреждениях, учреждениях начального профессионального, среднего профессионального, высшего профессионального образования, кроме тех случаев, когда его исполнение может реально препятствовать продолжению обучения, например при очной форме обучения.</p>
            <p class="block_3">По общему правилу исправительные работы могут быть назначены несовершеннолетнему, достигшему возраста 16 лет, на срок от двух месяцев до одного года, а в случаях, предусмотренных частями второй и третьей статьи 63 Трудового кодекса Российской Федерации, и несовершеннолетнему, достигшему возраста 15 и 14 лет соответственно.</p>
            <p class="block_3">При назначении несовершеннолетнему наказания в виде исправительных работ, суду надлежит обсудить возможность его исправления без реального отбывания этого наказания.</p>
            <p class="block_3">24. На лиц, осужденных к наказаниям в виде исправительных работ и обязательных работ, распространяются нормы Трудового кодекса Российской Федерации об особенностях регулирования труда работников в возрасте до 18 лет.</p>
            <p class="block_3">Положения части 3 статьи 49, части 4 статьи 50 УК РФ о замене обязательных работ, исправительных работ в случае злостного уклонения от их отбывания наказанием в виде лишения свободы неприменимы к тем категориям несовершеннолетних осужденных, которым не может быть назначено наказание в виде лишения свободы.</p>
            <p class="block_3">25. Обратить внимание судов на то, что наказание в виде ограничения свободы назначается несовершеннолетним только в качестве основного наказания, срок которого определяется с учетом положений части 5 статьи 88 УК РФ.</p>
            <p class="block_3">26. Судам следует соблюдать правила индивидуализации наказания, имея в виду, что лишение свободы не назначается тем несовершеннолетним, которые впервые совершили преступление небольшой или средней тяжести в возрасте до 16 лет, а также остальным несовершеннолетним, совершившим преступления небольшой тяжести впервые (часть 6 статьи 88 УК РФ).</p>
            <p class="block_3">Впервые совершившим преступление небольшой или средней тяжести следует считать лицо, совершившее одно или несколько преступлений, ни за одно из которых оно ранее не было осуждено, либо когда предыдущий приговор в отношении его не вступил в законную силу или судимости за ранее совершенные преступления сняты и погашены в установленном законом порядке.</p>
            <p class="block_3">Несовершеннолетним осужденным, совершившим тяжкие преступления в возрасте до 16 лет, независимо от времени постановления приговора как за отдельное тяжкое преступление, так и по их совокупности не может быть назначено наказание на срок свыше шести лет лишения свободы. Этой же категории осужденных, совершивших особо тяжкие преступления, а также иным несовершеннолетним, достигшим шестнадцатилетнего возраста, максимальный срок назначенного лишения свободы за одно или несколько преступлений, в том числе по совокупности приговоров, не может превышать десять лет (часть 6 статьи 88 УК РФ).</p>
            <p class="block_3">27. В соответствии с частью 6 [1] статьи 88 УК РФ несовершеннолетнему, осужденному за совершение тяжкого или особо тяжкого преступления, нижний предел наказания в виде лишения свободы, предусмотренный соответствующей статьей Особенной части Уголовного кодекса Российской Федерации, сокращается наполовину. Ссылки на статью 64 УК РФ не требуется.</p>
            <p class="block_3">28. Положения части 6 [1] статьи 88 УК РФ о сокращении нижнего предела наказания, предусмотренного соответствующими статьями Особенной части Уголовного кодекса Российской Федерации, относятся к наказанию за совершение тяжкого либо особо тяжкого преступления в виде лишения свободы и на иные виды наказания, в том числе на обязательные работы и на исправительные работы, не распространяются.</p>
            <p class="block_3">29. Назначая несовершеннолетнему наказание с применением положений статьи 73 УК РФ, судам надлежит обсуждать вопрос о возложении на условно осужденного конкретных обязанностей, предусмотренных законом.</p>
            <p class="block_3">Помимо обязанностей, которые могут быть возложены на несовершеннолетнего осужденного в порядке, предусмотренном частью 5 статьи 73 УК РФ, суд в соответствии с Федеральным законом от 24 июня 1999 года N 120-ФЗ "Об основах системы профилактики безнадзорности и правонарушений несовершеннолетних" при наличии к тому оснований вправе обязать несовершеннолетнего осужденного пройти курс социально-педагогической реабилитации (психолого-педагогической коррекции) в учреждениях, оказывающих педагогическую и психологическую помощь гражданам (обучающимся, воспитанникам, детям), имеющим отклонения в развитии. Возложение на несовершеннолетнего обязанности возвратиться в образовательное учреждение для продолжения обучения возможно только при наличии положительного заключения об этом психолого-медико-педагогической комиссии органа управления образованием.</p>
            <p class="block_3">Принимая решение об условном осуждении несовершеннолетнего за совершение нового преступления, которое не является особо тяжким, следует иметь в виду, что по смыслу части 6 [2] статьи 88 УК РФ испытательный срок по каждому из приговоров исчисляется самостоятельно в пределах постановленных и исполняемых самостоятельно приговоров.</p>
            <p class="block_3">С учетом положений части 5 статьи 73 УК РФ при наличии сведений о злоупотреблении несовершеннолетним осужденным алкоголем, наркотическими или токсическими веществами суд вправе обязать его пройти обследование в наркологическом диспансере в сроки, установленные специализированным органом, осуществляющим исправление осужденного, а при необходимости и отсутствии противопоказаний - пройти курс лечения от алкоголизма (наркомании, токсикомании).</p>
            <p class="block_3">Продолжительность испытательного срока в отношении несовершеннолетнего осужденного определяется в минимальном размере, необходимом для достижения целей наказания. Испытательный срок и перечень обязанностей, возлагаемых судом на условно осужденного несовершеннолетнего, устанавливаются с учетом задач его исправления и не должны быть связаны с ограничениями его прав, не предусмотренными законом.</p>
            <p class="block_3">30. При совершении лицом нескольких преступлений, одни из которых были совершены в возрасте до 18 лет, а другие - по достижении 18 лет, суду надлежит учитывать, что за преступления, совершенные в несовершеннолетнем возрасте, наказание должно быть назначено с учетом положений, установленных статьей 88 УК РФ, а за преступления, совершенные в совершеннолетнем возрасте, - в пределах санкций статей Особенной части Уголовного кодекса Российской Федерации, установленных за соответствующие преступления. В этом случае при назначении окончательного наказания применяются правила назначения наказания по совокупности преступлений (статья 69 УК РФ) без учета положений статьи 88 УК РФ.</p>
            <p class="block_3">В исключительных случаях суд вправе применить правила о назначении наказания несовершеннолетним и к лицам, совершившим преступления в возрасте от 18 до 20 лет (статья 96 УК РФ). Такое решение должно быть мотивировано в приговоре наличием исключительных обстоятельств, характеризующих совершенное лицом деяние и его личность.</p>
            <p class="block_3">31. Суды не должны назначать уголовное наказание несовершеннолетним, совершившим преступления небольшой или средней тяжести, если их исправление может быть достигнуто путем применения принудительных мер воспитательного воздействия, предусмотренных статьей 90 УК РФ.</p>
            <p class="block_3">Решая вопрос о возможности освобождения несовершеннолетнего от уголовной ответственности с применением принудительных мер воспитательного воздействия в соответствии со статьей 90 УК РФ, необходимо учитывать, что в случае, когда суд придет к выводу о возможности его исправления путем применения мер воспитательного воздействия, уголовное дело по указанному основанию подлежит прекращению как на стадии подготовки к судебному заседанию по результатам предварительного слушания, так и по итогам судебного разбирательства с вынесением решения о применении к несовершеннолетнему таких мер.</p>
            <p class="block_3">Суду необходимо разъяснять несовершеннолетнему, а также его законному представителю положения части 4 статьи 90 УК РФ о том, что в случае систематического неисполнения этой принудительной меры воспитательного воздействия она подлежит отмене с направлением материалов дела в установленном порядке для привлечения несовершеннолетнего к уголовной ответственности, что следует отразить в протоколе судебного заседания.</p>
            <p class="block_3">32. Под систематическим неисполнением несовершеннолетним принудительной меры воспитательного воздействия следует понимать неоднократные (более двух раз) нарушения в течение назначенного судом срока применения принудительной меры воспитательного воздействия (например, ограничения досуга, установления особых требований к его поведению), которые были зарегистрированы в установленном порядке специализированным органом, осуществляющим контроль за поведением подростка.</p>
            <p class="block_3">Если несовершеннолетнему назначено одновременно несколько принудительных мер воспитательного воздействия (часть 3 статьи 90 УК РФ) и в течение определенного срока он допустил единичные нарушения (не более двух раз по каждой из них), такие нарушения не могут быть признаны систематическими, дающими основание для применения судом положений части 4 статьи 90 УК РФ об отмене принудительных мер воспитательного воздействия.</p>
            <p class="block_3">33. Поступившее в суд ходатайство о применении к несовершеннолетнему принудительной меры воспитательного воздействия, предусмотренной частью 2 статьи 90 УК РФ, по уголовному делу о преступлении небольшой или средней тяжести, прекращенному следователем в соответствии с частью 1 статьи 427 УПК РФ, рассматривается судьей единолично в соответствии с частью 2 статьи 427 УПК РФ.</p>
            <p class="block_3">При этом в судебное заседание должны быть вызваны несовершеннолетний, в отношении которого прекращено уголовное преследование, его законный представитель, защитник (адвокат), а также прокурор. Потерпевший уведомляется о времени и месте рассмотрения ходатайства о применении к несовершеннолетнему обвиняемому принудительных мер воспитательного воздействия. Неявка потерпевшего не препятствует проведению судебного разбирательства.</p>
            <p class="block_3">Заслушав мнение участников процесса о применении принудительных мер воспитательного воздействия, судья с учетом данных о личности обвиняемого, характера и степени совершенного им деяния выносит постановление о применении принудительных мер воспитательного воздействия с обоснованием принятого решения (статья 90 УК РФ).</p>
            <p class="block_3">34. В случае прекращения уголовного дела и применении к несовершеннолетнему в качестве принудительной меры воспитательного воздействия передачи под надзор родителей или лиц, их заменяющих (родственников, опекунов), либо специализированного государственного органа, ограничения досуга и установления особых требований к поведению в постановлении суда необходимо указать срок применения избранной меры (часть 2 статьи 90 УК РФ), действие которой прекращается по достижении им восемнадцатилетнего возраста.</p>
            <p class="block_3">Решая вопрос о передаче несовершеннолетнего под надзор родителей или лиц, их заменяющих, суд должен убедиться в том, что указанные лица имеют положительное влияние на него, правильно оценивают содеянное им, могут обеспечить его надлежащее поведение и повседневный контроль за ним. Для этого необходимо, например, истребовать данные, характеризующие родителей или лиц, их заменяющих, проверить условия их жизни и возможность материального обеспечения несовершеннолетнего. При этом должно быть получено согласие родителей или лиц, их заменяющих, на передачу им несовершеннолетнего под надзор.</p>
            <p class="block_3">35. В соответствии с частью 1 статьи 432 УПК РФ при рассмотрении уголовного дела о преступлении небольшой или средней тяжести суд вправе, постановив обвинительный приговор, освободить несовершеннолетнего от наказания и применить к нему в силу части 1 статьи 92 УК РФ принудительные меры воспитательного воздействия. В таком случае на основании пункта 3 части 5 статьи 302 УПК РФ суд постановляет обвинительный приговор без назначения наказания.</p>
            <p class="block_3">Если несовершеннолетний за совершение преступления средней тяжести, а также тяжкого преступления осужден к лишению свободы, суд вправе на основании части 2 статьи 92 УК РФ, за исключением лиц, перечисленных в части 5 статьи 92 УК РФ, освободить его от наказания с помещением в специальное учебно-воспитательное учреждение закрытого типа органа управления образованием. Такое решение принимается в порядке замены назначенного несовершеннолетнему осужденному лишения свободы другим видом наказания.</p>
            <p class="block_3">36. Вопрос о направлении несовершеннолетнего осужденного в специальное учебно-воспитательное учреждение закрытого типа органа управления образованием может быть решен судом лишь при наличии медицинского заключения о возможности его пребывания в таком учреждении. При этом необходимо учитывать, что в указанное специальное учебно-воспитательное учреждение направляются несовершеннолетние осужденные, которые нуждаются в особых условиях воспитания, обучения и требуют специального педагогического подхода.</p>
            <p class="block_3">Определяемый срок действия принудительной меры воспитательного воздействия не зависит от срока наказания, предусмотренного санкцией статьи УК РФ, по которой квалифицировано деяние несовершеннолетнего. При этом в соответствии с положениями части 2 статьи 92 УК РФ несовершеннолетний может быть помещен в указанное учреждение только до достижения им 18 лет и не более чем на 3 года.</p>
            <p class="block_3">37. Освобождение от наказания в виде лишения свободы, назначенного несовершеннолетнему за совершение преступления средней тяжести, а также тяжкого преступления, с помещением его в специализированное учебно-воспитательное учреждение закрытого типа как принудительной мерой воспитательного воздействия может быть осуществлено судом и позднее в порядке исполнения приговора (пункт 16 статьи 397 УПК РФ).</p>
            <p class="block_3">38. При установлении систематического неисполнения несовершеннолетним принудительной меры воспитательного воздействия суд вправе по ходатайству специализированного государственного органа отменить постановление о применении такой меры, назначенной в порядке части 1 статьи 431 или части 1 статьи 432 УПК РФ, и направить уголовное дело в отношении несовершеннолетнего на новое судебное рассмотрение. В том случае, когда мера воспитательного воздействия назначена несовершеннолетнему в порядке, предусмотренном частью 1 статьи 427 УПК РФ, т.е. при наличии постановления о прекращении уголовного дела, суд, отменяя такое постановление, направляет дело руководителю следственного органа или начальнику органа дознания для выполнения следователем (дознавателем) действий, связанных с окончанием его расследования и необходимостью составления обвинительного заключения (обвинительного акта). Указанные решения принимаются судом в порядке, предусмотренном статьей 399 УПК РФ.</p>
            <p class="block_3">Специализированным государственным органом, которому в соответствии со статьей 90 УК РФ может быть передан под надзор несовершеннолетний, а также органом, который вправе обращаться в суд с представлением об отмене принудительной меры воспитательного воздействия в случаях систематического ее неисполнения несовершеннолетним, является комиссия по делам несовершеннолетних и защите их прав.</p>
            <p class="block_3">39. Если органом предварительного расследования к участию в деле в качестве гражданских ответчиков не были привлечены родители, опекуны, попечители, а также лечебные учреждения, учреждения социальной защиты населения или другие учреждения, которые в силу закона несут материальную ответственность за ущерб, причиненный преступными действиями несовершеннолетнего, суд при наличии исковых требований должен вынести определение (постановление) о признании указанных лиц и организаций гражданскими ответчиками, разъяснить им права, предусмотренные статьей 54 УПК РФ, и обеспечить условия для реализации этих прав.</p>
            <p class="block_3">40. В соответствии со статьей 1074 ГК РФ несовершеннолетние в возрасте от 14 до 18 лет самостоятельно несут ответственность за причиненный моральный и материальный вред на общих основаниях. В случаях, когда у несовершеннолетнего осужденного, не достигшего возраста 18 лет, нет доходов или иного имущества, достаточных для возмещения вреда, он должен быть возмещен полностью или в недостающей части его родителями (усыновителями) или попечителем, если они не докажут, что вред возник не по их вине.</p>
            <p class="block_3">В силу положений статей 21 и 27 ГК РФ и статьи 13 Семейного кодекса Российской Федерации самостоятельную ответственность за причиненный вред несут несовершеннолетние, которые в момент причинения вреда, а также в момент рассмотрения судом вопроса о возмещении вреда обладали полной дееспособностью.</p>
            <p class="block_3">При определении размера компенсации морального вреда суду с учетом требований статьи 151 ГК РФ, принципов разумности и справедливости следует исходить из степени нравственных или физических страданий, связанных с индивидуальными особенностями лица, которому причинен вред, степени вины нарушителя и иных заслуживающих внимания обстоятельств дела.</p>
            <p class="block_3">41. В соответствии со статьей 93 УК РФ к лицам, совершившим преступление в несовершеннолетнем возрасте, должны применяться сокращенные сроки условно-досрочного освобождения от наказания в виде лишения свободы. При этом необходимо выяснять наличие для этого фактических оснований, определенных в общих нормах - в статье 79 УК РФ и статье 175 УИК РФ.</p>
            <p class="block_3">В отношении иных видов наказаний к несовершеннолетним осужденным могут быть применены общие положения статьи 80 УК РФ о замене неотбытой части наказания более мягким видом наказания с учетом определенных в статье 88 УК РФ видов наказаний, назначаемых несовершеннолетним.</p>
            <p class="block_3">42. При рассмотрении дел о преступлениях в отношении взрослых лиц, которые совершили преступление с участием несовершеннолетних, суду надлежит выяснять характер взаимоотношений между ними, поскольку эти данные могут иметь существенное значение для установления роли взрослого лица в вовлечении несовершеннолетнего в совершение преступлений или антиобщественных действий.</p>
            <p class="block_3">К уголовной ответственности за вовлечение несовершеннолетнего в совершение преступления или совершение антиобщественных действий могут быть привлечены лица, достигшие восемнадцатилетнего возраста и совершившие преступление умышленно. Судам необходимо устанавливать, осознавал ли взрослый, что своими действиями вовлекает несовершеннолетнего в совершение преступления или совершение антиобщественных действий. Если взрослый не осознавал этого, то он не может привлекаться к ответственности по статьям 150 и 151 УК РФ.</p>
            <p class="block_3">Под вовлечением несовершеннолетнего в совершение преступления или совершение антиобщественных действий следует понимать действия взрослого лица, направленные на возбуждение желания совершить преступление или антиобщественные действия. Действия взрослого лица могут выражаться как в форме обещаний, обмана и угроз, так и в форме предложения совершить преступление или антиобщественные действия, разжигания чувства зависти, мести и иных действий.</p>
            <p class="block_3">Преступления, ответственность за которые предусмотрена статьями 150 и 151 УК РФ, являются оконченными с момента совершения несовершеннолетним преступления, приготовления к преступлению, покушения на преступление или после совершения хотя бы одного из антиобщественных действий, предусмотренных диспозицией части 1 статьи 151 УК РФ (систематическое употребление спиртных напитков, одурманивающих веществ, занятие бродяжничеством или попрошайничеством). Если последствия, предусмотренные диспозициями названных норм, не наступили по не зависящим от виновных обстоятельствам, то их действия могут быть квалифицированы по части 3 статьи 30 УК РФ и по статье 150 УК РФ либо статье 151 УК РФ.</p>
            <p class="block_3">В случае совершения преступления несовершеннолетним, не подлежащим уголовной ответственности, лицо, вовлекшее его в совершение преступления, в силу части 2 статьи 33 УК РФ несет уголовную ответственность за содеянное как исполнитель путем посредственного причинения.</p>
            <p class="block_3">Действия взрослого лица по подстрекательству несовершеннолетнего к совершению преступления при наличии признаков состава указанного преступления должны квалифицироваться по статье 150 УК РФ, а также по закону, предусматривающему ответственность за соучастие (в виде подстрекательства) в совершении конкретного преступления.</p>
            <p class="block_3">43. По делам в отношении обвиняемых в вовлечении несовершеннолетнего в совершение преступления по мотивам политической, идеологической, расовой, национальной или религиозной ненависти или вражды либо по мотивам ненависти или вражды в отношении какой-либо социальной группы необходимо устанавливать и отражать в приговоре, в чем конкретно выразились преступные действия таких лиц, подтверждающие их виновность в совершении деяний, которые предусмотрены частью 4 статьи 150 УК РФ.</p>
            <p class="block_3">44. Судам следует повышать воспитательное значение судебных процессов по делам о преступлениях несовершеннолетних, уделяя особое внимание их профилактическому воздействию: по каждому делу устанавливать причины и условия, способствовавшие совершению несовершеннолетними преступления, не оставлять без реагирования установленные в судебном заседании недостатки и упущения в работе комиссий по делам несовершеннолетних и защите их прав, учебных заведений и общественных организаций, выносить частные определения (постановления) с указанием конкретных обстоятельств.</p>
            <p class="block_3">45. В соответствии с Пекинскими правилами, 1985 г. право на конфиденциальность информации о несовершеннолетнем подозреваемом, обвиняемом, подсудимом должно обеспечиваться на всех стадиях процесса, чтобы избежать причинения несовершеннолетнему вреда и ущерба его репутации.</p>
            <p class="block_3">Исходя из этих рекомендаций, судам надлежит не допускать рассмотрение уголовных дел в отношении несовершеннолетних и материалов о совершенных ими правонарушениях с участием представителей средств массовой информации, а также использование видео- и фотосъемки несовершеннолетних правонарушителей и потерпевших в залах судебных заседаний и в других помещениях судов, за исключением случаев, когда несовершеннолетний и (или) его законный представитель ходатайствуют об этом.</p>
            <p class="block_3">Следует также иметь в виду положения статьи 41 Закона Российской Федерации от 27 декабря 1991 года "О средствах массовой информации", согласно которой редакция средства массовой информации не вправе разглашать в распространяемых сообщениях и материалах сведения, прямо или косвенно указывающие на личность несовершеннолетнего, совершившего преступление либо подозреваемого в его совершении, а равно совершившего административное правонарушение или антиобщественное действие. Редакция средства массовой информации не вправе также разглашать в распространяемых сообщениях и материалах сведения, прямо или косвенно указывающие на личность несовершеннолетнего потерпевшего. Разглашение такой информации возможно лишь с согласия указанных лиц и (или) их законных представителей.</p>
            <p class="block_3">При исследовании в судебном заседании обстоятельств, которые могут оказать на несовершеннолетнего подсудимого отрицательное воздействие, суд по ходатайству сторон или по собственной инициативе вправе удалить его из зала судебного заседания, а после его возвращения обязан предоставить возможность задать вопросы лицам, допрошенным в его отсутствие (статья 429 УПК РФ).</p>
            <p class="block_3">46. Рекомендовать судам систематически изучать и обобщать практику рассмотрения уголовных дел о преступлениях несовершеннолетних, а также материалов о совершенных ими правонарушениях и при наличии к тому оснований информировать соответствующие организации либо должностных лиц для принятия мер по предупреждению преступлений и правонарушений среди несовершеннолетних.</p>
            <p class="block_3">47. В связи с принятием настоящего постановления признать утратившим силу постановление Пленума Верховного Суда Российской Федерации от 14 февраля 2000 г. N 7 "О судебной практике по делам о преступлениях несовершеннолетних".</p>
            <p class="block_4">&nbsp;</p>
            <p id="calibre_link-1" class="block_5">Председатель<br class="calibre2" />Верховного Суда<br class="calibre2" />Российской Федерации<br class="calibre2" />В. Лебедев</p>
            <p class="block_5">Секретарь Пленума,<br class="calibre2" />судья Верховного Суда<br class="calibre2" />Российской Федерации<br class="calibre2" />В. Дорошков</p>

        </div>
    </div>
</template>

<script>
    export default {
        name: "apunishment-minor",
        data (){
            return{
                page:{
                    title: 'Пленумы | О наказании несовершеннолетних',
                    description: 'Static description',
                    keywords: 'keywords',
                    content: ""
                }
            }
        },
        created: function () {
            this.axios.get(`${encodeURI(window.hostname)}/api/getPage?pageName=${encodeURI("apunishment-minor")}`).then(
                response => {
                    this.page = response.data;
                }
            );
            this.page.title = "Пленумы | О наказании несовершеннолетних";
        }
    }
</script>

<style scoped>
    .block_ {
        display: block;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 8pt;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-align: center;
        text-indent: 0
    }
    .block_1 {
        display: block;
        font-size: 1.125em;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 8pt;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-align: center;
        text-indent: 0
    }
    .block_2 {
        display: block;
        line-height: 1.2;
        margin-bottom: 8pt;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-align: center;
        text-indent: 0
    }
    .block_3 {
        display: block;
        line-height: 1.2;
        margin-bottom: 8pt;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-indent: 35.5pt
    }
    .block_4 {
        display: block;
        font-family: serif;
        font-size: 0.75em;
        line-height: 1.08;
        margin-bottom: 8pt;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-indent: 35.5pt
    }
    .block_5 {
        display: block;
        line-height: 1.2;
        margin-bottom: 8pt;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-align: right;
        text-indent: 35.5pt
    }
    .calibre {
        color: black;
        display: block;
        font-family: "Times New Roman", serif;
        font-size: 1em;
        margin-bottom: 0;
        margin-left: 5pt;
        margin-right: 5pt;
        margin-top: 0;
        padding-left: 0;
        padding-right: 0
    }
    .calibre1 {
        font-weight: bold;
        line-height: 1.2
    }
    .calibre2 {
        display: block;
        line-height: 1.2
    }

    @page {
        margin-bottom: 5pt;
        margin-top: 5pt
    }
</style>
