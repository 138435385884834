<template>
    <div class="UserNotification">
        <vue-headful title="Юридический прогноз | Уведомления пользователя"
                     description="Уведомления пользователя Пользователь уведомлен о том, что Сервис исходит из достоверности сведений, вводимых Пользователем, предполагающих доказанность вины и вменяемость лица по выбранному Пользователем преступлению."
                     :keywords="page.keywords"/>
        <div v-html="page.content"></div>
    </div>
</template>

<script>
    export default {
        name: "UserNotification",
        data (){
            return{
                page:{
                    title:'Юридический прогноз | Уведомления пользователя',
                    description: '',
                    keywords: 'keywords',
                    content: ""
                }
            }
        },
        created: function () {
            this.axios.get(`${encodeURI(window.hostname)}/api/getPage?pageName=${encodeURI("UserNotification")}`).then(
                response => {
                    this.page = response.data;
                }
            );

            this.page.title = "Юридический прогноз | Уведомления пользователя";
        }
    }
</script>

<style scoped>

</style>

