<template>
    <!-- Footer -->
    <footer class="page-footer font-small blue-grey lighten-5" style="background-color: #f8f9fa;">
        <div>
                <!-- Grid row-->
            <div class="row  d-flex align-items-center"  style="background-color: #f8f9fa">
            </div>
            <!-- Grid row-->
        </div>

        <!-- Footer Links -->
        <div class="container text-center text-md-left mt-4" >

            <!-- Grid row -->
            <div class="row mt-3 dark-grey-text">

                <!-- Grid column -->
                <div class="col-md-3 col-lg-4 col-xl-3 mb-4">

                    <!-- Content -->
                    <h6 class="title-footer text-uppercase font-weight-bold">Юридический Прогноз</h6>
                    <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
                    <p class=fotter-text>Сервис прогнозирования решений суда по уголовным делам.</p>
                    <p class=fotter-text>Версия в редакции УК РФ от {{this.dateEditionUkRf}}</p>
                    <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
                    <p class=fotter-text>Контакты:</p>
                    <p class=fotter-text>yurprognoz@gmail.com</p>
                </div>
                <!-- Grid column -->
                <div class="row mt-4">
                    <!-- Grid column -->
                    <div class="col-md-2 col-lg-3 col-xl-2 mb-4 ml-auto">
                        <p class=fotter-text>
                            <a class="dark-grey-text" href="/feedback">Обратная связь</a>
                        </p>
                    </div>
                    <!-- Grid column -->
                    <div class="col-md-2 col-lg-3 col-xl-2 mb-4 ml-auto">
                        <p class=fotter-text>
                            <a class="dark-grey-text" href="/faq">Часто задаваемые вопросы</a>
                        </p>
                    </div>
                    <!-- Grid column -->
                    <div class="col-md-2 col-lg-1 col-xl-2 mb-4 ml-auto">
                        <!-- Links -->
                        <p class=fotter-text>
                            <a class="dark-grey-text" href="/privacy-policy">Политика Конфиденциальности</a>
                        </p>
                    </div>
                </div>
            </div>
            <!-- Grid row -->

        </div>
        <!-- Footer Links -->

        <!-- Copyright -->

        <shareSocial></shareSocial>
        <div class="footer-copyright text-center text-black-50 py-3">© 2020 Copyright:
            <a class="dark-grey-text" href="https://aisud.ru/">aisud.ru</a>
            {{this.ver}}
        </div>
        <!-- Copyright -->

    </footer>
    <!-- Footer -->
</template>


<script>
    import shareSocial from "@/components/ShareSocialNetworks";
    export default {
        name: "Footer",
        components: {
            shareSocial
        },
        data (){
            return {
                ver: "0",
                dateEditionUkRf: "0"
            }
        },

        created: function () {
            this.axios.get(`${encodeURI(window.hostname)}/api/getVersion`).then(response => (this.ver = response.data));
            this.axios.get(`${encodeURI(window.hostname)}/api/getUkRfEdition`).then(response => (this.dateEditionUkRf = response.data));
        },

    }
</script>

<style>

    .viber:after{
        box-shadow: black;
    }

    .instagram {
        display: inline-block;
        width: 16px;
        height: 16px;
        text-align: center;
        margin-top: 4px;
        border-radius: 4px;
        color: #fff;
        font-size: 220px;
        line-height: 250px;
        vertical-align: top;
        background: #d6249f;
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
        box-shadow: 0px 3px 10px rgba(0,0,0,.25);
    }
    .container{
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    footer{
        background: rgb(158,144,135);
        background: linear-gradient(0deg, rgba(158,144,135,1) 1%, rgba(255,255,255,1) 60%);
    }

     @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

     }

</style>
