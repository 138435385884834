<template>
    <div class="test">
        <vue-headful title="Юридический прогноз | Тестирование "
                     :description="page.description"
                     :keywords="page.keywords"/>
        <b-modal ref="modal-redirect" hide-footer no-close-on-backdrop no-close-on-esc hide-header-close centered>
            <div class="d-block text-center">
                <h5>{{this.q.info}}</h5>
            </div>
            <b-button class="mt-3" variant="outline-info" block @click="closeModal">Перейти в общий порядок</b-button>
            <b-button class="mt-3" variant="outline-success" block @click="RepeatCloseModal">Начать сначала</b-button>
        </b-modal>

            <div v-if="isNotLoading">
                <div class="col-sm-12 justify-content-center pb-3" v-if="q.finished">
                    <div class="col-sm-12 justify-content-center pb-3 align-content-center" style="margin-top: 5%">
                        <b-alert variant="success" style="text-align: center" show>{{q.question}} </b-alert>
                    </div>
                </div>
                <div class="col-sm-12 justify-content-center pb-2" style="margin-top: 7%; padding: 0" v-else>
                    <b-card class="card border-info bg-light justify-content-lg-center" style="padding: 0px">
                        <div class="card-header text-center" style="background-color: aliceblue;" >
                            <div class="row justify-content-center" >
                                <p class="col-10 ">{{q.question}}</p>
                                <b-button class="btn-art-content" v-if="(this.q.step === 'CrimeItem') && (this.q.info !== '')" v-on:click="showMsgBoxInfo('Статья ' + 'УК РФ ' + currArticle + ' ' + q.articleName.info, q.info)" pill variant="outline-secondary" size="sm">Содержание статьи</b-button>
                                <b-button v-else-if="this.q.info !== ''" @click="showMsgBoxInfo(q.question, q.info)" style="width: 24px;height: 24px; border-radius: 12px; padding: 0px; font-size: 16px;" class="justify-content-lg-end" variant="outline-info">?</b-button>
                                <div v-else></div>
                            </div>
                        </div>
                        <div class="card-body " style="padding: 0px; margin-top: 1rem">
                            <div v-if="q.checker">
                                <b-container class="bv-example-row justify-content-center">
                                    <b-form-group>
                                        <b-form-checkbox-group
                                                id="checkbox-group-1"
                                                stacked
                                                switches
                                                name="flavour-1"
                                                v-model="Test.subject"
                                                :value="q.options.value"
                                                :options="q.options"
                                                :onkeydown="ChangeCheckBox()">
                                        </b-form-checkbox-group>
                                    </b-form-group>
                                </b-container>
                                <b-container class="bv-example-row justify-content-center" >
                                    <b-row class="justify-content-center pb-3">
                                        <b-button class="btn-back" v-on:click="GetPreviousStep()" style="margin: 0 5px 0 5px" variant="outline-success">Назад</b-button>
                                        <b-button v-if="!isChecked" v-on:click="SendEmptySubject()" style="margin: 0 5px 0 5px" variant="outline-success">Пропустить</b-button>
                                        <b-button v-else v-on:click="SendArticle()" style="margin: 0 5px 0 5px" variant="outline-success">Далее</b-button>
                                    </b-row>
                                </b-container>
                            </div>
                            <div v-else-if="q.input">
                                <b-container class="bv-example-row justify-content-center" >
                                    <b-row class="justify-content-center pb-3">
                                        <AutocompleteArticle :hostname="this.hostname" :searchAll="false" @updateParent="onUpdateSalaryFromSearch" />
                                    </b-row>
                                </b-container>
                            </div>
                            <div v-else>
                                <b-container class="bv-example-row justify-content-center">
                                    <b-row class="justify-content-center pb-3" v-for="(option, i) in q.options" :key="i">
                                        <b-button class="btn-option" variant="outline-primary" :disabled="option.disabled" v-on:click="SelectNextOption(option)">{{option.text}}</b-button>
                                        <b-button v-if="option.info !== ''" @click="showMsgBoxInfo('', option.info)" style="width: 24px;height: 24px; border-radius: 12px; margin: 6px; padding: 0px; font-size: 15px;" class="justify-content-lg-end" variant="outline-info">?</b-button>
                                        <div v-else></div>
                                    </b-row>
                                </b-container>
                                <b-container class="bv-example-row justify-content-center" >
                                    <b-row class="justify-content-center pb-3">
                                        <b-button class="btn-back" v-on:click="GetPreviousStep" style="margin: 0 5px 0 5px" variant="outline-success">Назад</b-button>
                                    </b-row>
                                </b-container>
                            </div>
                        </div>
                    </b-card>
                </div>
                <div class="CurrentTest" v-if="q.canGetPunishments">
                    <CurrentPunishments :articleName="q.articleName" :fine="q.fine" :disqualification="q.disqualification"
                                        :compulsoryWork="q.compulsoryWork" :correctionalWork="q.correctionalWork" :militaryRestriction="q.militaryRestriction"
                                        :restrictionFreedom="q.restrictionFreedom" :imposingWork="q.imposingWork" :disciplinaryBattalion="q.disciplinaryBattalion"
                                        :deprivationLiberty='q.deprivationLiberty' :pzhd="q.pzhd" :isFinished="q.finished"/>
                </div>
                <div v-else>
                </div>
                <div class="col-sm-12 justify-content-center pb-3" v-if="q.finished">
                    <b-container class="bv-example-row justify-content-center" style="margin-top: 5%">
                        <b-row class="justify-content-center pb-3">
                            <b-button v-on:click="Repeat()" variant="outline-success">Начать сначала</b-button>
                        </b-row>
                        <b-row class="justify-content-center pb-3">
                            <b-button class="btn-back" v-on:click="GetPreviousStep" style="margin: 0 5px 0 5px" variant="outline-success">Назад</b-button>
                        </b-row>
                    </b-container>
                </div>
                <div v-else>
                </div>
                <b-card class="col-lg-12" style="text-align: center; top:10px; padding-left: 25%; padding-right: 25%">
                    <b-card-text style="text-wrap: normal">{{this.GetShowSteps()}}</b-card-text>
                </b-card>
            </div>
            <div v-else>
                <b-spinner label="Loading..." style="margin: 20% 50% " variant="primary"></b-spinner>
            </div>

    </div>
</template>

<script>
    import CurrentPunishments from "../components/CurrentPunishmets";
    import AutocompleteArticle from "../components/AutocompleteArticle.vue";
    export default {
        name: "test",
        components: {
            CurrentPunishments,
            AutocompleteArticle
        },
        data (){
            return{
                hostname: window.hostname,
                page:{
                    title:'Юридический прогноз | Тестирование',
                    description: 'Static description',
                    keywords: 'keywords',
                    content: ""
                },
                errors:[],
                isNotLoading: true,
                responses: [],
                currArticle: "",
                ThisSubjects: [],
                isChecked: false,
                searchData: [""],
                steps:{
                    age: "Age",
                    sex: "Sex",
                    subject: "Subjects",
                    isNullSubjects: false,
                    crimeArticle: "CrimeArticle",
                    judProc: "JudProc",
                    crimeItem: "CrimeItem",
                    endOfCrime: "EndOfCrime",
                    criminalRecord: "CriminalRecord",
                    relapse: "Relapse",
                    mitigating: "CrimeMitigating",
                    mitigating_i: "CrimeMitigatingI",
                    aggravating: "Aggravating",
                },

                Test: {
                    age: "0",
                    sex: "0",
                    crimeItem: "0",
                    crimeArticle: "0",
                    endOfCrime: "0",
                    judProc: "0",
                    criminalRecord: "0",
                    relapse: "0",
                    mitigating: "0",
                    mitigating_i: "0",
                    aggravating: "0",
                    subject: [],
                    redirect: 0,
                },
                q: {
                    question: "Укажите",
                    options: [{
                        text: "",
                        value: "",
                        disabled: false,
                        info: "",
                        title: "",
                    }],
                    radioOptionsDisabled: [{
                        text: "",
                        value: "",
                        disabled: false,
                    }],
                    radioOptionsMilitary: [{
                        text: "",
                        value: "",
                        disabled: false,
                    }],
                    radioOptionsOldMans: [{
                        text: "",
                        value: "",
                        disabled: false,
                    }],
                    step: "0",
                    checker: false,
                    finished: false,
                    input: false,
                    error: 0,
                    info: "",
                    custom: "",
                    canGetPunishments: false,
                    articleName: {
                        text: "",
                        info: "",
                    },
                    pzhd:{
                        text: "",
                        info: "",
                    },
                    deprivationLiberty: {
                        text: "",
                        info: "",
                    },
                    disciplinaryBattalion: {
                        text: "",
                        info: "",
                    },
                    imposingWork: {
                        text: "",
                        info: "",
                    },
                    restrictionFreedom: {
                        text: "",
                        info: "",
                    },
                    militaryRestriction: {
                        text: "",
                        info: "",
                    },
                    correctionalWork: {
                        text: "",
                        info: "",
                    },
                    compulsoryWork: {
                        text: "",
                        info: "",
                    },
                    disqualification: {
                        text: "",
                        info: "",
                    },
                    fine: {
                        text: "",
                        info: "",
                    },
                },
            }
        },
        created: function () {
            this.steps.isNullSubjects = false;
            this.currArticle = "";
            this.Test.crimeArticle = "";
            this.q.canGetPunishments = false;
            this.q.error = 0;
            this.q.info = "";
            this.Test.judProc = "";
            this.Test.CurrentPunishments = "";
            this.q.custom = "";
            this.Test.redirect = 0;
            this.axios.get(`${encodeURI(window.hostname)}/api/getStart`).then(
                response => {
                    this.Test = response.data;
                    this.changeLoading();
                }
            );
            this.axios.get(`${encodeURI(window.hostname)}/api/getPage?pageName=${encodeURI("test")}`).then(
                response => {
                    this.page = response.data;
                }
            );
            this.GetNextStep();
        },
        methods: {
            GetPreviousStep: function(){
                this.changeLoading();
                console.log(this.q.step);
                switch (this.q.step) {
                    case "CrimeItem":
                        this.Test.crimeArticle = "0";
                        this.currArticle = "0";
                        break;
                    case "Age":
                        if (this.Test.crimeItem === "0") {
                            this.Test.crimeArticle = "0";
                            this.currArticle = "0";
                        }
                        this.Test.crimeItem = "0";
                        break;
                    case "Sex":
                        this.Test.age = "0";
                        break;
                    case "Subject":
                        this.steps.isNullSubjects = false;
                        this.Test.subject = [];
                        this.Test.sex = "0";
                        break;
                    case "EndOfCrime":
                        this.steps.isNullSubjects = false;
                        this.Test.subject = [];
                        break;
                    case "JudProc":
                        this.Test.endOfCrime = "0";
                        break;
                    case "CriminalRecord":
                        this.Test.endOfCrime = "0";
                        this.Test.judProc = "0";
                        break;
                    case "Relapse":
                        this.Test.criminalRecord = "0";
                        break;
                    case "CrimeMitigating":
                        this.Test.relapse = "0";
                        this.Test.criminalRecord = "0";
                        break;
                    case "CrimeMitigatingI":
                        this.Test.relapse = "0";
                        this.Test.criminalRecord = "0";
                        break;
                    case  "CrimeAggravating":
                        this.Test.relapse = "0";
                        this.Test.criminalRecord = "0";
                        this.Test.mitigating = "0";
                        break;
                    case "":
                        this.Test.mitigating = "0";
                        this.Test.mitigating_i = "0";
                        this.Test.aggravating = "0";
                        break;
                }
                this.axios.post(`${encodeURI(window.hostname)}/api/getPreviousStep`,
                    {
                        "age": this.Test.age,
                        "sex": this.Test.sex,
                        "crimeArticle": this.Test.crimeArticle,
                        "crimeItem": this.Test.crimeItem,
                        "endOfCrime": this.Test.endOfCrime,
                        "judProc": this.Test.judProc,
                        "criminalRecord": this.Test.criminalRecord,
                        "relapse": this.Test.relapse,
                        "mitigating": this.Test.mitigating,
                        "mitigating_i": this.Test.mitigating_i,
                        "aggravating": this.Test.aggravating,
                        "subject": this.Test.subject,
                        "redirect": this.Test.redirect,
                    },)
                    .then(response => {
                        this.q = response.data;
                        this.changeLoading();
                    })
                    .catch(e => {
                        this.errors.push(e.toString());
                    });
            },

            toast(title, message, append = false) {
                this.$bvToast.toast(`${message}`, {
                    title: title,
                    toaster: `b-toaster-top-center`,
                    solid: true,
                    appendToast: append,
                    noAutoHide: true,
                    variant: `info`
                })
            },

            showMsgBoxInfo(title, message) {
                const h = this.$createElement;
                const messageVNode = h('div', { domProps: { innerHTML: message } });
                this.$bvModal.msgBoxOk(messageVNode, {
                    title: title,
                    size: 'lg',
                    buttonSize: 'sm',
                    footerClass: 'p-1',
                    okVariant: 'success',
                    hideHeaderClose: true,
                    centered: true,
                    scrollable: true,
                })
            },

            onUpdateSalaryFromSearch(data) {
                this.currArticle = data.value;
                this.SendArticle();
            },

            GetNextStep: function () {
                this.axios.post(`${encodeURI(window.hostname)}/api/getNextStep`,
                    {
                        "age": this.Test.age,
                        "sex": this.Test.sex,
                        "crimeArticle": this.Test.crimeArticle,
                        "crimeItem": this.Test.crimeItem,
                        "endOfCrime": this.Test.endOfCrime,
                        "judProc": this.Test.judProc,
                        "criminalRecord": this.Test.criminalRecord,
                        "relapse": this.Test.relapse,
                        "mitigating": this.Test.mitigating,
                        "mitigating_i": this.Test.mitigating_i,
                        "aggravating": this.Test.aggravating,
                        "subject": this.Test.subject,
                        "redirect": this.Test.redirect,
                    },)
                    .then(response => {
                        this.q = response.data;
                        this.changeLoading();
                        if ((this.q.error === 5)){
                            this.ShowModalCustom();
                            this.Test.redirect = 0;
                            this.q.question = "";
                            this.q.options = [];
                        }

                    })
                    .catch(e => {
                        this.errors.push(e.toString());
                    });
            },

            ShowModalCustom: function(){
                console.log("this 2");
                this.Test.mitigating_i = "0";
                this.showModal();
            },

            showModal: function() {
                console.log("this 3");
                this.$refs['modal-redirect'].show();
            },

            closeModal() {
                this.$refs['modal-redirect'].toggle();
                this.q.error = 0;
                this.q.info = "";
                this.Test.judProc = "0";
                this.q.custom = "";
                this.Test.redirect = 1;
                this.changeLoading();
                this.GetNextStep();
            },

            ChangeCheckBox: function () {
                this.isChecked = this.Test.subject.length > 0;
            },
            /**
             * @return {boolean}
             */
            changeLoading(){
                this.isNotLoading = this.isNotLoading === false;
            },
            /**
             * @return {string}
             */
            GetShowSteps: function(){
                let str;
                str = "";
                if (this.Test.crimeArticle !== "0") str += "Статья: " + this.Test.crimeArticle + ";\n\r";
                if (this.Test.crimeItem !== "0") str += " Часть: " + this.Test.crimeItem + ";\n\r";
                if (this.Test.age !== "0") str += " Возраст: " + this.Test.age + ";\n\r";
                if (this.Test.sex  !== "0") str +=  " Пол: " + this.Test.sex + ";\n\r";
                if (this.Test.subject.length !== 0) {
                    str +=  " Субъекты: ";
                    for (let sub in this.Test.subject) {
                        if ( this.Test.subject[sub] !== "NULL") {
                            str += " " + this.Test.subject[sub] + ",";
                        }
                    }
                } else if ((this.Test.subject.length === 0) && this.steps.isNullSubjects) {
                    str +=  " Субъекты: Не выбраны";
                }
                str += "\n\r";
                if (this.Test.judProc !== "0") str += "Выбор порядка судопроизводства: " + this.Test.judProc + ";\n\r";
                if (this.Test.endOfCrime  !== "0") str +=  " Оконченность: " + this.Test.endOfCrime + ";\n\r";
                if (this.Test.criminalRecord  !== "0") str +=  " Судимость: " + this.Test.criminalRecord + ";\n\r";
                if (this.Test.relapse  !== "0") str +=  " Рецидив: " + this.Test.relapse + ";\n\r";
                if (this.Test.mitigating  !== "0") str +=  " Смягчающие: " + this.Test.mitigating + ";\n\r";
                if (this.Test.mitigating_i  !== "0") str +=  " Смягчающие И: " + this.Test.mitigating_i + ";\n\r";
                if (this.Test.aggravating  !== "0") str +=  " Отягчающие: " + this.Test.aggravating + ";\n\r";
                return str;
            },

            Starting: function(){
                this.changeLoading();
                this.GetNextStep();
            },

            SendEmptySubject: function(){
                if (this.Test.subject.length === 0) {
                    this.Test.subject.push("NULL");
                    this.steps.isNullSubjects = true;
                }
                this.changeLoading();
                this.GetNextStep();
            },

            SendArticle: function (){
                if (this.q.step === "CrimeArticle") {
                    this.Test.crimeArticle = this.currArticle;
                }
                this.changeLoading();
                this.GetNextStep();
            },

            SelectNextOption: function(message){
                switch (this.q.step) {
                    case "Age":
                        this.Test.age = message.text;
                        break;
                    case  "Sex":
                        this.Test.sex = message.text;
                        break;
                    case  "CrimeItem":
                        this.Test.crimeItem = message.text;
                        break;
                    case  "EndOfCrime":
                        this.Test.endOfCrime = message.text;
                        break;
                    case  "JudProc":
                        this.Test.judProc = message.text;
                        break;
                    case  "CriminalRecord":
                        this.Test.criminalRecord = message.text;
                        break;
                    case "Relapse":
                        this.Test.relapse = message.text;
                        break;
                    case  "CrimeMitigating":
                        this.Test.mitigating = message.text;
                        break;
                    case  "CrimeMitigatingI":
                        this.Test.mitigating_i = message.text;
                        break;
                    case  "CrimeAggravating":
                        this.Test.aggravating = message.text;
                        break;
                }

                this.changeLoading();
                this.GetNextStep();
            },

            RepeatCloseModal: function(){
                this.Repeat();
                this.Test.judProc = "0";
                this.closeModal();
            },

            Repeat: function(){

                this.steps.isNullSubjects = false;
                this.currArticle = "";
                this.q.canGetPunishments = false;
                this.q.error = 0;
                this.q.info = "";
                this.q.custom = "";
                this.Test.crimeArticle = "0";
                this.Test.judProc = "0";
                this.Test.age = "0";
                this.Test.sex = "0";
                this.Test.crimeItem = "0";
                this.Test.endOfCrime = "0";
                this.Test.criminalRecord = "0";
                this.Test.relapse = "0";
                this.Test.mitigating = "0";
                this.Test.mitigating_i = "0";
                this.Test.aggravating = "0";
                this.Test.subject = [];
                this.Test.redirect = 0;
                this.changeLoading();
                this.GetNextStep();
            }
        },
    }
</script>

<style scoped>

.ver{
    position: fixed; /* Фиксированное положение */
    bottom: 1px;
    right: 10px; /* Расстояние от правого края окна браузера */
}
.CurrentTest{
    top: 15%;
    margin: auto;
    text-align: center;
}
.test{
    margin: 5% 0%;
}
 @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .btn-option {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .btn-art-content {
        width: 100%;
    }
    .btn-back {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}
</style>
