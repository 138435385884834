<template>
    <div class="apunishment">
        <vue-headful :title="page.title"
                     :description="page.description"
                     :keywords="page.keywords"/>
        <div class="calibre" id="calibre_link-0">

            <p aling="center" class="block_1">
                <font face="Times New Roman, serif">
                    <font size=3>
                        <font color="#000000">
                            <B>
                                Постановление Пленума Верховного Суда Российской Федерации
                            </B>
                        </font>
                    </font>
                </font>
            </p>
            <p aling=CENTER class="block_1">
                <font face="Times New Roman, serif">
                    <font size=3>
                        <font color="#000000">
                            <font size=4 style="font-size: 13pt">
                                <B>
                                    &quot;О применении судами особого порядка судебного разбирательства уголовных дел&quot;
                                </B>
                            </font>
                        </font>
                    </font>
                </font>
            </p>
            <p aling=CENTER class="block_1">
                <font face="Times New Roman, serif">
                    <font size=3>
                        <font color="#000000">
                            (от 05.12.2006 N 60 с изменениями от 29.06.2021)
                        </font>
                    </font>
                </font>
            </p>
            <p class="block_2">
                <font face="Times New Roman, serif">
                    <font size=3>
                        В связи с возникшими у судов вопросами по применению особого порядка судебного разбирательства, установленного&nbsp;главой 40&nbsp;Уголовно-процессуального кодекса Российской Федерации,
                        и в целях обеспечения единства судебной практики Пленум Верховного Суда Российской Федерации, руководствуясь&nbsp;статьей 126&nbsp;Конституции Российской Федерации,&nbsp;статьями
                        2&nbsp;и&nbsp;5&nbsp;Федерального конституционного закона от 5 февраля 2014 года N 3-ФКЗ &quot;О Верховном Суде Российской Федерации&quot;,
                    </font>
                </font>
            <font face="Times New Roman, serif">
                <font size=3>
                    <B>
                        постановляет
                    </B>
                </font>
            </font>
            <font face="Times New Roman, serif">
                <font size=3>
                    дать судам следующие разъяснения:
                </font>
            </font>
        </p>
        <p class="block_2">
            <br>
        </p>
        <p class="block_2">
            <font face="Times New Roman, serif">
                <font size=3>
                    1. Обратить внимание судов на недопустимость ограничения прав участников судебного разбирательства при рассмотрении уголовных дел в особом порядке и необходимость соблюдения принципов уголовного
                    судопроизводства при их разрешении.
                </font>
            </font>
        </p>
        <p class="block_2">
            <br>
        </p>
        <p class="block_2">
            <font face="Times New Roman, serif">
                <font size=3>
                    2. При рассмотрении вопроса о возможности принятия судебного решения по ходатайству обвиняемого о постановлении приговора без проведения судебного разбирательства в общем порядке судам надлежит
                    устанавливать, имеются ли по уголовному делу необходимые для этого условия. Согласно требованиям норм&nbsp; главы 40 &nbsp;Уголовно-процессуального кодекса Российской Федерации (далее - УПК РФ)
                    таковыми следует считать: заявление обвиняемого о согласии с предъявленным обвинением в совершении преступления; понимание обвиняемым существа обвинения и согласие с ним в полном объеме; заявление
                    такого ходатайства в присутствии защитника добровольно и после консультации с ним в период, установленный&nbsp; частью 2 статьи 315 &nbsp;УПК РФ; осознание обвиняемым характера и последствий заявленного
                    им ходатайства; обвинение лица в совершении преступления небольшой и (или) средней тяжести; отсутствие возражений у государственного или частного обвинителя и потерпевшего против рассмотрения уголовного дела в
                    особом порядке; обоснованность обвинения и его подтверждение собранными по делу доказательствами (часть 7 статьи 316&nbsp;УПК РФ); отсутствие оснований для прекращения уголовного дела.
                </font>
            </font>
        </p>
        <p class="block_2">
        <br>
        </p>

        <p class="block_2">
            <font face="Times New Roman, serif">
                <font size=3>
                    3. Решая вопрос о возможности применения особого порядка судебного разбирательства по уголовному делу, следует иметь в виду, что в нормах&nbsp;главы 40&nbsp;УПК РФ указаны условия постановления
                    приговора без проведения судебного разбирательства в общем порядке, а не условия назначения уголовного дела к рассмотрению. Поэтому при наличии ходатайства обвиняемого о применении
                    особого порядка принятия судебного решения и отсутствии обстоятельств, препятствующих разбирательству уголовного дела в особом порядке, судья при назначении судебного заседания
                    принимает решение о рассмотрении данного дела в особом порядке.
                </font>
            </font>
        </p>
        <p class="block_2">
            <font face="Times New Roman, serif">
                <font size=3>
                    Если впоследствии в ходе судебного заседания будет установлено, что все условия соблюдены, суд продолжает рассмотрение уголовного дела в особом порядке.
                </font>
            </font>
        </p>
        <p class="block_2">
            <br>
        </p>

        <p class="block_2">
            <font face="Times New Roman, serif">
                <font size=3>4. Невыполнение
                органами предварительного расследования
                возложенной на них частью 1&nbsp;статьи
                11&nbsp;и пунктом 2 части 5&nbsp;статьи
                217&nbsp;УПК РФ обязанности по разъяснению
                обвиняемому права ходатайствовать при
                ознакомлении с материалами уголовного
                дела о применении особого порядка
                судебного разбирательства влечет
                нарушение права обвиняемого на защиту
                и в соответствии с пунктом 5 части
                1&nbsp;статьи
                237&nbsp;УПК РФ является основанием
                проведения предварительного слушания
                для решения вопроса о возвращении
                уголовного дела прокурору.
                </font>
            </font>
        </p>
        <p class="block_2">
            <br>
        </p>
        <p class="block_2">
            <font face="Times New Roman, serif">
                <font size=3>
                    Если в ходе
                    проводимого с участием обвиняемого,
                    его защитника, прокурора и потерпевшего
                    предварительного слушания имеется
                    возможность с соблюдением условий,
                    указанных в&nbsp;пункте
                    2&nbsp;настоящего Постановления,
                    восстановить права обвиняемого, судья
                    по ходатайству обвиняемого принимает
                    решение о назначении судебного заседания
                    в особом порядке. При невозможности
                    устранить допущенное в ходе предварительного
                    расследования нарушение
                    уголовно-процессуального закона дело
                    подлежит возвращению прокурору.
                </font>
            </font>
        </p>
        <p class="block_2">
            <br>
        </p>
        <p class="block_2">
            <font face="Times New Roman, serif">
                <font size=3>
                    В соответствии
                    с требованиями&nbsp;части
                    2 статьи 315&nbsp;УПК РФ обвиняемый вправе
                    заявить ходатайство о постановлении
                    приговора без проведения судебного
                    разбирательства в момент ознакомления
                    с материалами уголовного дела и на
                    предварительном слушании, когда оно
                    является обязательным в соответствии
                    со&nbsp;статьей
                    229&nbsp;УПК РФ, поэтому ходатайство о
                    применении особого порядка судебного
                    разбирательства может быть удовлетворено
                    лишь в том случае, если оно заявлено до
                    назначения судебного заседания.
                </font>
            </font>
        </p>
        <p class="block_2">
            <br>
        </p>
        <p class="block_2">
            <font face="Times New Roman, serif"><font size=3>5 - 6. Исключены.
            </font></font>
        </p>
        <p class="block_2">
            <br>
        </p>
        <p class="block_2">
            <font face="Times New Roman, serif">
                <font size=3>
                7. Если по
                уголовному делу обвиняется несколько
                лиц, а ходатайство о постановлении
                приговора без проведения судебного
                разбирательства заявили лишь некоторые
                из них либо хотя бы один из обвиняемых
                является несовершеннолетним, то при
                невозможности выделить дело в отношении
                лиц, заявивших ходатайство об особом
                порядке судебного разбирательства, и
                несовершеннолетних в отдельное
                производство такое дело в отношении
                всех обвиняемых должно рассматриваться
                в общем порядке.
                </font>
            </font>
        </p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>8. В особом
        порядке могут рассматриваться уголовные
        дела как публичного и частно-публичного,
        так и частного обвинения. С учетом
        особенностей судопроизводства по делам
        частного обвинения ходатайство об
        особом порядке судебного разбирательства
        по ним может быть заявлено в период от
        момента вручения лицу заявления
        потерпевшего о привлечении его к
        уголовной ответственности до вынесения
        судьей постановления о назначении
        судебного заседания.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>При этом
        мировой судья в соответствии с
        требованиями&nbsp;статьи
        11&nbsp;УПК РФ при вручении заявления
        обязан в присутствии защитника разъяснить
        лицу, в отношении которого оно подано,
        право ходатайствовать о применении
        особого порядка судебного разбирательства
        и выяснить у него, желает ли он
        воспользоваться этим правом, а при
        проведении примирительной процедуры
        - выяснить у потерпевшего, не возражает
        ли он против удовлетворения ходатайства
        лица, привлекаемого к ответственности.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>9. При назначении
        рассмотрения уголовного дела в особом
        порядке, включая принятие такого решения
        по результатам предварительного
        слушания, необходимо соблюдать
        установленное&nbsp;частью
        4 статьи 231&nbsp;УПК РФ требование об
        извещении сторон о месте, дате и времени
        судебного заседания не менее чем за 5
        суток до его начала.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>Извещение
        участников уголовного судопроизводства
        допускается, в том числе посредством
        СМС-сообщения в случае их согласия на
        уведомление таким способом и при фиксации
        факта отправки и доставки СМС-извещения
        адресату. Факт согласия на получение
        СМС-извещения подтверждается распиской,
        в которой наряду с данными об участнике
        судопроизводства и его согласием на
        уведомление подобным способом указывается
        номер мобильного телефона, на который
        оно направляется.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>10. В соответствии
        с&nbsp;частью
        5 статьи 316&nbsp;УПК РФ по делу,
        рассматриваемому в особом порядке, в
        ходе судебного заседания могут быть
        исследованы обстоятельства, характеризующие
        личность подсудимого, и обстоятельства,
        смягчающие и отягчающие наказание.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>Исследование
        указанных обстоятельств может проводиться
        всеми предусмотренными уголовно-процессуальным
        законом способами, в том числе путем
        оглашения имеющихся в деле и дополнительно
        представленных сторонами материалов,
        а также допросов свидетелей по этим
        обстоятельствам.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>11. Обратить
        внимание судов на недопустимость
        рассмотрения уголовных дел в особом
        порядке без подсудимого, его защитника,
        государственного или частного обвинителя,
        поскольку от позиции указанных участников
        судебного разбирательства зависит
        возможность применения особого порядка
        принятия судебного решения. В судебном
        заседании следует также удостовериться
        в отсутствии у потерпевшего, надлежащим
        образом извещенного о месте и времени
        судебного заседания, в том числе
        посредством СМС-сообщения в случае его
        согласия на уведомление таким способом
        и при фиксации факта отправки и доставки
        СМС-извещения адресату, возражений
        против заявленного обвиняемым ходатайства
        о постановлении приговора без проведения
        судебного разбирательства в общем
        порядке.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>Абзац исключен.
        </font></font>
        </p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>11.1. Суд в ходе
        рассмотрения уголовного дела в особом
        порядке проверяет, соблюдены ли условия
        постановления приговора без проведения
        судебного разбирательства в общем
        порядке, в том числе предусмотренное&nbsp;частью
        1 статьи 314&nbsp;УПК РФ условие о согласии
        обвиняемого с предъявленным обвинением.</font></font></p>
        <p class="block_2">
            <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>По смыслу&nbsp;пункта
        22 статьи 5,&nbsp;пунктов 4,&nbsp;5
        части 2 статьи 171&nbsp;и&nbsp;части
        1 статьи 220&nbsp;УПК РФ применительно к
        особому порядку судебного разбирательства
        под обвинением, с которым соглашается
        обвиняемый, заявляя ходатайство о
        постановлении приговора без проведения
        судебного разбирательства в общем
        порядке, следует понимать фактические
        обстоятельства содеянного обвиняемым,
        форму вины, мотивы совершения деяния,
        юридическую оценку содеянного, а также
        характер и размер причиненного вреда.</font></font></p>
        <p class="block_2">
            <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>Если подсудимый
        ранее оспаривал обвинение либо
        воспользовался правом на отказ от дачи
        показаний, то суду при выяснении вопросов,
        указанных в&nbsp;части
        4 статьи 316&nbsp;УПК РФ, следует уточнить
        его позицию и убедиться в том, что
        подсудимый действительно согласен с
        обвинением.</font></font></p>
        <p class="block_2">
            <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>11.2. В силу&nbsp;пункта
        3 части 2 статьи 314&nbsp;УПК РФ суд обязан
        удостовериться, что у государственного
        обвинителя (прокурора), частного
        обвинителя и (или) потерпевшего, если
        он участвует в деле, не имеется возражений
        против заявленного обвиняемым ходатайства.
        При этом следует учитывать, что несогласие
        с ходатайством обвиняемого, выраженное
        хотя бы одним из названных лиц, независимо
        от мотивов возражений является основанием
        для проведения судебного разбирательства
        в общем порядке.</font></font></p>
        <p class="block_3">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>11.3. В случае,
        когда до удаления в совещательную
        комнату для постановления приговора
        будут выявлены какие-либо обстоятельства,
        препятствующие принятию судебного
        решения в особом порядке, судья на
        основании&nbsp;части
        6 статьи 316&nbsp;УПК РФ выносит постановление
        о прекращении особого порядка судебного
        разбирательства, назначении рассмотрения
        уголовного дела в общем порядке и о
        вызове в судебное заседание лиц по
        спискам, представленным сторонами.
        Такое постановление выносится судьей
        по правилам&nbsp;части
        2 статьи 256&nbsp;УПК РФ, не предусматривающим
        для этого обязательного удаления суда
        в совещательную комнату и изложения
        принятого решения в виде отдельного
        процессуального документа.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>При этом
        возвращения к стадии назначения судебного
        заседания с вынесением постановления
        в соответствии со&nbsp;статьей
        231&nbsp;УПК РФ, а также повторного
        проведения выполненных в ходе судебного
        производства следственных и иных
        процессуальных действий не требуется.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>Суд, переходя
        к общему порядку судебного разбирательства,
        обеспечивает сторонам возможность
        дополнительной подготовки к судебному
        заседанию и с этой целью, с учетом мнения
        сторон, объявляет перерыв в судебном
        заседании либо выносит постановление
        о его отложении на определенный срок.</font></font></p>
        <p class="block_3">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>11.4. Судам
        необходимо иметь в виду, что рассмотрение
        уголовного дела в особом порядке не
        освобождает суд от обязанности исследовать
        вопросы, касающиеся гражданского иска,
        и принять по нему решение. В частности,
        суд при постановлении обвинительного
        приговора вправе удовлетворить
        гражданский иск, если его требования
        вытекают из обвинения, с которым
        согласился обвиняемый, и не имеется
        препятствий для разрешения его судом
        по существу. При наличии соответствующих
        оснований гражданский иск может быть
        оставлен без рассмотрения, производство
        по нему прекращено, в его удовлетворении
        может быть отказано либо по иску принято
        решение о передаче его на рассмотрение
        в порядке гражданского судопроизводства.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>Вопросы,
        указанные в&nbsp;пунктах
        10.1&nbsp;-&nbsp;12
        части 1 статьи 299&nbsp;УПК РФ, в приговоре
        или постановлении о прекращении
        уголовного дела (часть
        9.1 статьи 316&nbsp;УПК РФ) разрешаются
        судом с учетом положений&nbsp;части
        5 статьи 316&nbsp;УПК РФ на основании
        материалов уголовного дела с указанием
        мотивов принятого решения. При
        необходимости в целях уточнения
        обстоятельств, значимых для разрешения
        указанных вопросов, суд оглашает
        имеющиеся в деле и дополнительно
        представленные сторонами материалы.</font></font></p>
        <p class="block_3">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>12. Исключен.
        </font></font>
        </p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>12.1. С учетом
        особенностей судопроизводства,
        осуществляемого в соответствии с
        нормами&nbsp;главы
        40&nbsp;УПК РФ, в описательно-мотивировочной
        части приговора или постановления о
        прекращении уголовного дела необходимо
        отразить, что суд удостоверился в
        соблюдении установленных законом
        условий.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>В частности,
        следует указать, что обвинение обоснованно,
        подтверждается собранными по делу
        доказательствами, а подсудимый понимает
        существо предъявленного ему обвинения
        и соглашается с ним в полном объеме; он
        своевременно, добровольно и в присутствии
        защитника заявил ходатайство об особом
        порядке, осознает характер и последствия
        заявленного им ходатайства; государственным
        или частным обвинителем и потерпевшим
        не высказано возражений против
        рассмотрения дела в особом порядке.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>Изменение
        обвинения в части квалификации содеянного,
        которое допускается, если для этого не
        требуется исследования собранных по
        делу доказательств и фактические
        обстоятельства при этом не изменяются,
        должно быть мотивированно.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>13. Следует
        иметь в виду, что указанные в&nbsp;части
        7 статьи 316&nbsp;УПК РФ требования о
        назначении подсудимому при рассмотрении
        дела в особом порядке наказания не более
        двух третей максимального срока или
        размера наиболее строгого вида наказания,
        предусмотренного за совершенное
        преступление, распространяются только
        на случаи рассмотрения уголовных дел
        в порядке, предусмотренном&nbsp;главой
        40&nbsp;УПК РФ (часть
        5 статьи 62&nbsp;УК РФ). При назначении
        наказания ссылка на&nbsp;часть
        7 статьи 316&nbsp;УПК РФ не требуется,
        наказание назначается в соответствии
        с&nbsp;частью
        5 статьи 62&nbsp;УК РФ.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>Положения&nbsp;части
        5 статьи 62&nbsp;УК РФ и&nbsp;части
        7 статьи 316&nbsp;УПК РФ не распространяются
        на дополнительные наказания.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>14. Обратить
        внимание судов на то, что в случае
        применения особого порядка судебного
        разбирательства, установленного&nbsp;главой
        40 &nbsp;УПК РФ, при наличии оснований,
        предусмотренных&nbsp;статьями
        62 ,&nbsp;64 ,&nbsp;66 ,&nbsp;68 &nbsp;и&nbsp;69 &nbsp;УК
        РФ, наказание назначается следующим
        образом:</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>при наличии
        оснований, предусмотренных&nbsp;частью
        1 статьи 62 &nbsp;УК РФ, - вначале применяются
        положения&nbsp;части
        5 статьи 62 &nbsp;УК РФ, затем -&nbsp;части
        1 статьи 62 &nbsp;УК РФ. При применении
        положений&nbsp;части
        1 статьи 62 &nbsp;УК РФ две трети исчисляются
        от максимального срока или размера
        наказания, которое может быть назначено
        с учетом положений&nbsp;части
        5 &nbsp;этой статьи;</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>при наличии
        оснований, предусмотренных&nbsp;статьей
        64 &nbsp;УК РФ, - закон не предусматривает
        каких-либо ограничений применения этой
        нормы по делам, рассмотренным в порядке,
        установленном&nbsp;главой
        40 &nbsp;УПК РФ;</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>при наличии
        оснований, предусмотренных&nbsp; статьей
        66 &nbsp;УК РФ, - вначале применяются
        положения&nbsp;статьи
        66 &nbsp;УК РФ, затем -&nbsp; части
        5 статьи 62 &nbsp;УК РФ. При применении
        положений&nbsp;части
        5 статьи 62 &nbsp;УК РФ две трети исчисляются
        от максимального срока или размера
        наказания, которое может быть назначено
        с учетом положений&nbsp; статьи
        66 &nbsp;УК РФ;</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>при наличии
        оснований, предусмотренных как&nbsp;частью
        1 статьи 62 &nbsp;УК РФ, так и&nbsp; статьей
        66 &nbsp;УК РФ, - вначале применяются
        положения&nbsp; статьи
        66 &nbsp;УК РФ, затем -&nbsp; части
        5 статьи 62 &nbsp;УК РФ, после этого -&nbsp; части
        1 статьи 62 &nbsp;УК РФ. При применении
        положений&nbsp; части
        1 статьи 62 &nbsp;УК РФ две трети исчисляются
        от максимального срока или размера
        наказания, которое может быть назначено
        с учетом последовательного применения
        положений&nbsp; статьи
        66 &nbsp;и&nbsp; части
        5 статьи 62 &nbsp;УК РФ;</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>при наличии
        оснований, предусмотренных&nbsp;статьей
        68 &nbsp;УК РФ, - одна треть исчисляется
        от максимального срока наиболее строгого
        вида наказания, предусмотренного
        санкцией соответствующей статьи&nbsp; Особенной
        части &nbsp;УК РФ, при назначении наказания
        за оконченное преступление либо от
        максимального срока наиболее строгого
        вида наказания, которое может быть
        назначено с учетом положений&nbsp; статьи
        66 &nbsp;УК РФ за неоконченное преступление;</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>при наличии
        оснований, предусмотренных&nbsp; статьей
        69 &nbsp;УК РФ, - вначале следует с учетом
        требований&nbsp; части
        5 статьи 62 &nbsp;УК РФ определить
        максимальный срок или размер наказания,
        которое может быть назначено в связи с
        рассмотрением дела в особом порядке за
        каждое из совершенных преступлений,
        затем назначить окончательное наказание,
        размер которого определяется исходя
        из срока или размера наказания за
        наиболее тяжкое из совершенных
        преступлений без учета положений&nbsp; части
        5 статьи 62 &nbsp;УК РФ, а равно положений&nbsp; части
        1 статьи 62 &nbsp;УК РФ. При назначении
        окончательного наказания судам необходимо
        иметь в виду, что за каждое из совершенных
        преступлений наказание назначено с
        применением правил&nbsp; статьи
        62 &nbsp;УК РФ.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>Если лицо
        совершило несколько неоконченных
        преступлений, то за каждое из них
        назначается наказание в соответствии
        со&nbsp; статьей
        66 &nbsp;и&nbsp; частью
        5 статьи 62 &nbsp;УК РФ. Наказание по
        совокупности преступлений при этом не
        может превышать более чем наполовину
        максимальный срок или размер наказания,
        которое может быть назначено за наиболее
        тяжкое из совершенных неоконченных
        преступлений по правилам&nbsp; статьи
        66 &nbsp;УК РФ.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>15. Согласно&nbsp; статье
        317 &nbsp;УПК РФ судебные решения, принятые
        без проведения судебного разбирательства
        в общем порядке, не могут быть обжалованы
        сторонами в связи с несоответствием
        выводов суда фактическим обстоятельствам
        дела. Поэтому производство по таким
        жалобам при отсутствии иных доводов и
        оснований для пересмотра судебных
        решений по делу в судах апелляционной
        и кассационной инстанций подлежит
        прекращению.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>Вместе с тем,
        если в апелляционных (кассационных)
        жалобах, представлениях содержатся
        данные, указывающие на существенное
        нарушение уголовно-процессуального
        закона, неправильное применение
        уголовного закона либо на несправедливость
        приговора, судебные решения, принятые
        в особом порядке, могут быть отменены
        или изменены, если при этом не изменяются
        фактические обстоятельства дела
        (например, в связи с изменением уголовного
        закона, неправильной квалификацией
        преступного деяния судом первой
        инстанции, истечением сроков давности,
        амнистией и т.п.).</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>Для решения
        вопроса о законности приговора или
        постановления о прекращении уголовного
        дела, вынесенных в особом порядке, наряду
        с доводами апелляционных (кассационных)
        жалоб, представлений суд проверяет,
        соблюдены ли условия принятия судебного
        решения без проведения судебного
        разбирательства в общем порядке, в том
        числе установленное в&nbsp; части
        7 статьи 316 &nbsp;УПК РФ требование,
        обязывающее суд убедиться в обоснованности
        обвинения.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>16. Суд
        апелляционной инстанции не вправе
        исследовать доказательства, подтверждающие
        либо опровергающие обвинение, поскольку
        приговор, постановленный без проведения
        судебного разбирательства в общем
        порядке, не может быть обжалован в связи
        с несоответствием выводов суда фактическим
        обстоятельства дела.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>При рассмотрении
        уголовного дела по апелляционному
        представлению прокурора либо апелляционной
        жалобе потерпевшего, частного обвинителя
        или их представителей на несправедливость
        приговора вследствие чрезмерной мягкости
        суду следует иметь в виду, что обоснованность
        применения особого порядка судебного
        разбирательства не обжалуется, поэтому
        вновь назначенное виновному более
        строгое наказание не может превышать
        две трети максимального срока или
        размера наиболее строгого вида наказания,
        предусмотренного за совершенное
        преступление.</font></font></p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>16.1. Суд при
        повторном рассмотрении дела, в том числе
        в общем порядке судебного разбирательства,
        не вправе назначить осужденному более
        строгое наказание в тех случаях, когда
        приговор, постановленный по правилам&nbsp; главы
        40 &nbsp;УПК РФ, отменен судом вышестоящей
        инстанции по основаниям, не связанным
        с ухудшением положения осужденного.</font></font></p>
        <p class="block_3">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>17. Исключен.
        </font></font>
        </p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>17.1. Исключен.
        </font></font>
        </p>
        <p class="block_2">
        <br>
        </p>
        <p class="block_2">
        <font face="Times New Roman, serif"><font size=3>18. В связи с
        принятием настоящего Постановления
        признать утратившими силу&nbsp;абзацы
        первый&nbsp;и&nbsp;второй&nbsp;пункта 28
        Постановления Пленума Верховного Суда
        Российской Федерации от 5 марта 2004 года
        N 1 &quot;О применении судами норм
        Уголовно-процессуального кодекса
        Российской Федерации&quot;.</font></font></p>
        <p class="block_1">
        <br><br>
        </p>
        <p aling=RIGHT class="block_3">
        <font face="Times New Roman, serif"><font size=3><font color="#000000">Председатель<br>Верховного
        Суда<br>Российской Федерации<br>В.М. Лебедев</font></font></font></p>
        <p aling=RIGHT class="block_3">
        <font face="Times New Roman, serif"><font size=3><font color="#000000">И.о.
        секретаря Пленума,<br>судья Верховного
        Суда<br>Российской Федерации<br>С.А. Разумов</font></font></font></p>
        <p style="margin-bottom: 0.11in"><br><br>
        </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "aspecialorder",
        data (){
            return{
                page:{
                    title:'Пленумы | О применении особого порядка',
                    description: 'Static description',
                    keywords: 'keywords',
                    content: ""
                }
            }
        },
        created: function () {
            this.axios.get(`${encodeURI(window.hostname)}/api/getpage?pageName=${encodeURI("aspecialorder")}`).then(
                response => {
                    this.page = response.data;
                }
            );

            this.page.title = "Пленумы | О применении особого порядка";
        }
    }
</script>

<style scoped>
    .block_1 {
        text-indent: 0in;
        margin-top: 0.19in;
        margin-bottom: 0.19in;
        background: #ffffff;
        line-height: 100%;
        text-align: center;
    }
    .block_2 {
        text-indent: 0.38in;
        margin-bottom: 0in;
        background: #ffffff;
        line-height: 0.22in
    }
    .block_3 {
        text-indent: 0in;
        margin-top: 0.19in;
        margin-bottom: 0.19in;
        background: #ffffff;
        line-height: 100%;
        text-align: right;
    }
    @page {
        margin-bottom: 5pt;
        margin-top: 5pt
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        .mycontainer {
            padding: 15px;
        }

    }
</style>
