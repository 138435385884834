<template>
    <div class="test-punishment">
        <vue-headful title="Юридический прогноз | Погашение судимости "
                     :description="page.description"
                     :keywords="page.keywords"/>
        <div v-if="isNotLoading">
            <div class="col-sm-12 justify-content-center pb-3" v-if="q.finished">
                <div class="col-sm-12 justify-content-center pb-3 align-content-center" style="margin-top: 5%">
                    <b-alert variant="success" style="text-align: center" show>{{q.question}} </b-alert>
                    <b-card-text class="text-muted" style="text-align: center"> {{q.Info}} </b-card-text>
                    <b-button class="mt-3" variant="outline-success" block v-on:click="Repeat()">Начать сначала</b-button>
                </div>
            </div>
            <div class="col-sm-12 justify-content-center pb-2" style="margin-top: 7%; padding: 0" v-else>
                <b-card class="card border-info bg-light justify-content-lg-center" style="padding: 0px">
                    <div class="card-header text-center" style="background-color: aliceblue;" >
                        <div class="row justify-content-center" >
                            <p class="col-10 ">{{q.question}}</p>
                            <b-button class="btn-art-content" v-if="(this.q.step === 'CrimeItem') && (this.q.Info !== '')" v-on:click="showMsgBoxInfo('Статья ' + 'УК РФ ' + currArticle + ' ' + q.articleName.info, q.Info)" pill variant="outline-secondary" size="sm">Содержание статьи</b-button>
                            <b-button v-else-if="this.q.Info !== ''" @click="showMsgBoxInfo(q.question, q.Info)" style="width: 24px;height: 24px; border-radius: 12px; padding: 0px; font-size: 16px;" class="justify-content-lg-end" variant="outline-info">?</b-button>
                            <div v-else></div>
                        </div>
                    </div>
                    <div class="card-body " style="padding: 0px; margin-top: 1rem">
                        <div v-if="q.input">
                                <b-container class="bv-example-row justify-content-center" >
                                    <b-row class="justify-content-center pb-3">
                                        <AutocompleteArticle :hostname="this.hostname" :searchAll="false" @updateParent="onUpdateSalaryFromSearch" />
                                    </b-row>
                                </b-container>
                        </div>
                        <div v-else-if="q.getData">
                                <b-container class="bv-example-row justify-content-center align-items-center" >
                                    <b-row class="justify-content-center align-items-center pb-3" align-content="center" style="margin: auto;" >
                                        <b-form-datepicker
                                            id="datepicker-buttons"
                                            @context="onContext"
                                            locale="ru"
                                            v-bind="labels[locale] || {}"
                                            start-weekday="weekday"
                                            :show-decade-nav="showDecadeNav"
                                            :hide-header="true"
                                            v-model="value">
                                        </b-form-datepicker>
                                    </b-row>
                                </b-container>
                                <b-container class="bv-example-row justify-content-center" >
                                    <b-row class="justify-content-center pb-3">
                                        <b-button class="btn-back" v-on:click="GetPreviousStep()" style="margin: 0 5px 0 5px" variant="outline-success">Назад</b-button>
                                        <b-button v-on:click="SendDate()" style="margin: 0 5px 0 5px" variant="outline-success">Далее</b-button>
                                        <b-button v-on:click="SendDate()" style="margin: 0 5px 0 5px" variant="outline-success">Пропустить</b-button>
                                    </b-row>
                                </b-container>
                        </div>
                        <div v-else>
                            <b-container class="bv-example-row justify-content-center">
                                <b-row class="justify-content-center pb-3" v-for="(option, i) in q.options" :key="i">
                                    <b-button class="btn-option" variant="outline-primary" :disabled="option.disabled" v-on:click="SelectNextOption(option)">{{option.text}}</b-button>
                                    <b-button v-if="option.info !== ''" @click="showMsgBoxInfo('', option.info)" style="width: 24px;height: 24px; border-radius: 12px; margin: 6px; padding: 0px; font-size: 15px;" class="justify-content-lg-end" variant="outline-info">?</b-button>
                                    <div v-else></div>
                                </b-row>
                            </b-container>
                            <b-container class="bv-example-row justify-content-center" >
                                <b-row class="justify-content-center pb-3">
                                    <b-button class="btn-back" v-on:click="GetPreviousStep()" style="margin: 0 5px 0 5px" variant="outline-success">Назад</b-button>
                                </b-row>
                            </b-container>
                        </div>
                    </div>
                </b-card>
            </div>
        </div>
        <div v-else>
            <b-spinner label="Loading..." style="margin: 20% 50% " variant="primary"></b-spinner>
        </div>
    </div>
</template>

<script>
import AutocompleteArticle from "../components/AutocompleteArticle.vue";

export default {
    name: "test",
    components: {
        AutocompleteArticle
    },
    data() {
        return{
            hostname: window.hostname,
            page:{
                title:'Юридический прогноз | Погашение судимости',
                description: 'Static description',
                keywords: 'keywords',
                content: ""
            },
            isNotLoading: true,
            currArticle: "0",
            value: "",
            Test: {
                crimeArticle: "0",
                crimeItem: "0",
                punishment: "0",
                age: "0",
                typeOfPunishment: "0",
                datePunishment: "0"
            },
            context: null,
            q: {
                question: "0",
                options: "0",
                step: "0",
                checker: false,
                finished: false,
                getData: false,
                input: false,
                articleName: {
                    text: "",
                    info: "",
                },
                error: 0,
                custom: "0",
                Info: "0",
            },
            weekday: 0,
            weekdays: [
                { value: 0, text: 'Воскресенье' },
                { value: 1, text: 'Понедельник' },
                { value: 2, text: 'Вторник' },
                { value: 3, text: 'Среда' },
                { value: 4, text: 'Четверг' },
                { value: 5, text: 'Пятница' },
                { value: 6, text: 'Суббота' }
            ],
            locale: 'ru',
            labels: {
                ru: {
                labelPrevDecade: 'Предыдущее десятилетие',
                labelPrevYear: 'Предыдущий год',
                labelPrevMonth: 'Прошлый месяц',
                labelCurrentMonth: 'Текущий месяц',
                labelNextMonth: 'Следующий месяц',
                labelNextYear: 'В следующем году',
                labelNextDecade: 'Следующее десятилетие',
                labelToday: 'Cегодня',
                labelSelected: 'Выбранная дата',
                labelNoDateSelected: 'Дата не выбрана',
                labelCalendar: 'Календарь',
                labelNav: 'Навигация по календарю',
                labelHelp: 'Перемещайтесь по календарю с помощью клавиш со стрелками'
                },
            }
        };
    },
    created: function () {
        this.changeLoading();
        this.axios.get(`${encodeURI(window.hostname)}/api/getStartPunishment`).then(
                response => {
                    this.Test = response.data;
                    this.GetNextStep();
                }
        );

    },
    methods:{
        GetNextStep: function () {
            this.axios.post(`${encodeURI(window.hostname)}/api/getNextStepPunishment`,
                {
                "crimeArticle": this.Test.crimeArticle,
                "crimeItem": this.Test.crimeItem,
                "punishment": this.Test.punishment,
                "age": this.Test.age,
                "typeOfPunishment": this.Test.typeOfPunishment,
                "datePunishment": this.Test.datePunishment
                },)
                .then(response => {
                    this.q = response.data;
                    this.changeLoading();
                })
                .catch(e => {
                    this.errors.push(e.toString());
                });
        },

        onUpdateSalaryFromSearch(data) {
            this.currArticle = data.value;
            this.SendArticle();
        },
        Repeat(){
            this.Test.crimeArticle = "0"
            this.Test.crimeItem = "0"
            this.Test.punishment = "0"
            this.Test.age = "0"
            this.Test.typeOfPunishment = "0"
            this.Test.datePunishment = "0"
            this.context = null;
            this.value = "";
            this.changeLoading();
            this.GetNextStep();
        },

        SelectNextOption: function(message){
            switch (this.q.step) {
                case "Article":
                    this.Test.crimeArticle = message.text;
                    break;
                case  "CrimeItem":
                    this.Test.crimeItem = message.text;
                    break;
                case "TypePunishment":
                    this.Test.typeOfPunishment = message.text;
                    break;
                case "Age":
                    this.Test.age = message.value;
                    break;
                case "Punishment":
                    this.Test.punishment = message.text;
                    break;
            }

            this.changeLoading();
            this.GetNextStep();
        },

        onContext(ctx) {
            this.context = ctx;
        },

        SendDate() {
            this.Test.datePunishment = "check";
            if (this.context != null)
            {
                this.Test.datePunishment = this.context.selectedYMD;
            }

            this.changeLoading();
            this.GetNextStep();

        },

        GetPreviousStep: function() {
            switch (this.q.step) {
                case "GetDate":
                    this.Test.punishment = "0";
                    break;
                case "Punishment":
                    this.Test.age = "0";
                    break;
                case "Age":
                    this.Test.typeOfPunishment = "0";
                    break;
                case "TypePunishment":
                    this.Test.crimeItem = "0";
                    break;
                case "CrimeItem":
                    this.Test.crimeArticle = "0";
                    break;
            }
            this.changeLoading();
            this.GetNextStep();
        },

        showMsgBoxInfo(title, message) {
            const h = this.$createElement;
            const messageVNode = h('div', { domProps: { innerHTML: message } });
            this.$bvModal.msgBoxOk(messageVNode, {
                title: title,
                size: 'lg',
                buttonSize: 'sm',
                footerClass: 'p-1',
                okVariant: 'success',
                hideHeaderClose: true,
                centered: true,
                scrollable: true,
            })
        },

        SendArticle: function (){
            if (this.q.step === "CrimeArticle") {
                this.Test.crimeArticle = this.currArticle;
            }
            this.changeLoading();
            this.GetNextStep();
        },

        changeLoading(){
            this.isNotLoading = this.isNotLoading === false;
        },
    }
}
</script>


<style scoped>

.ver{
    position: fixed; /* Фиксированное положение */
    bottom: 1px;
    right: 10px; /* Расстояние от правого края окна браузера */
}
.CurrentTest{
    top: 15%;
    margin: auto;
    text-align: center;
}
.test{
    margin: 5% 0%;
}
 @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    .btn-option {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .btn-art-content {
        width: 100%;
    }
    .btn-back {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}
</style>
