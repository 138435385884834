<template>
<div>
    <div class="not-mobile">
        <b-carousel
            id="carousel-fade"
            controls
            fade
            interval="10000000000">

            <b-carousel-slide style="color:black;" img-src="https://i.ibb.co/nRt32Nw/slide-background.jpg">
                <h3 class="carusel-title-first">ЮРИДИЧЕСКИЙ ПРОГНОЗ</h3>
                <p class="p-class-carusel-first">Онлайн-прогноз</p>
                <p class="p-class-carusel-first">по уголовным делам.</p>
                <p class="p-class-carusel-first">Онлайн-калькулятор</p>
                <p class="p-class-carusel-first">погашения судимости.</p>

                <div class="btn-slide-position">
                    <b-button id="btn-carousel" class="button-collapse-mobile" href="/test" variant="success" size="lg">СОСТАВИТЬ ПРОГНОЗ</b-button>
                    <b-button id="btn-carousel" class="button-collapse-mobile" href="/test-punishment" variant="success" size="lg">ПОГАШЕНИЕ СУДИМОСТИ</b-button>
                </div>
            </b-carousel-slide>

            <b-carousel-slide style="color:black;" img-src="https://i.ibb.co/nRt32Nw/slide-background.jpg">
                <h3 class="carusel-title">«ЮРИДИЧЕСКИЙ ПРОГНОЗ» применяет:</h3>
                <p class="p-class-carusel">&#x2713; Актуальные редакции УК И УПК РФ;</p>
                <p class="p-class-carusel">&#x2713; Положения статей 62, 66 и 68 УК РФ в последовательности,</p>
                <p class="p-class-carusel">определенной постановлениями Пленумов ВС РФ.</p>

                <div class="btn-slide-position">
                    <b-button id="btn-carousel" class="button-collapse-mobile" href="/test" variant="success" size="lg">СОСТАВИТЬ ПРОГНОЗ</b-button>
                    <b-button id="btn-carousel" class="button-collapse-mobile" href="/test-punishment" variant="success" size="lg">ПОГАШЕНИЕ СУДИМОСТИ</b-button>
                </div>
            </b-carousel-slide>

            <b-carousel-slide style="color:black;" img-src="https://i.ibb.co/nRt32Nw/slide-background.jpg">
                <h3 class="carusel-title">«ЮРИДИЧЕСКИЙ ПРОГНОЗ» учитывает:</h3>
                <p class="p-class-carusel">&#x2713; Возраст, пол, специальный субъект;</p>
                <p class="p-class-carusel">&#x2713; Покушение или приготовление к преступлению;</p>
                <p class="p-class-carusel">&#x2713; Порядок судопроизводства;</p>
                <p class="p-class-carusel">&#x2713; Судимость и рецидив;</p>
                <p class="p-class-carusel">&#x2713; Смягчающие и отягчающие обстоятельства.</p>

                <div class="btn-slide-position">
                    <b-button id="btn-carousel" class="button-collapse-mobile" href="/test" variant="success" size="lg">СОСТАВИТЬ ПРОГНОЗ</b-button>
                    <b-button id="btn-carousel" class="button-collapse-mobile" href="/test-punishment" variant="success" size="lg">ПОГАШЕНИЕ СУДИМОСТИ</b-button>
                </div>
            </b-carousel-slide>

            <b-carousel-slide style="color:black;" img-src="https://i.ibb.co/nRt32Nw/slide-background.jpg">
                <h3 class="carusel-title">«ЮРИДИЧЕСКИЙ ПРОГНОЗ» исключает ошибки:</h3>
                <p class="p-class-carusel">&#x2713; Назначения неприменимого вида наказания;</p>
                <p class="p-class-carusel">&#x2713; Выбора субъекта, который не подлежит уголовной ответственности;</p>
                <p class="p-class-carusel">&#x2713; Признания рецидива преступления;</p>
                <p class="p-class-carusel">&#x2713; Выбора особого порядка, когда он не применяется.</p>

                <div class="btn-slide-position">
                    <b-button id="btn-carousel" class="button-collapse-mobile" href="/test" variant="success" size="lg">СОСТАВИТЬ ПРОГНОЗ</b-button>
                    <b-button id="btn-carousel" class="button-collapse-mobile" href="/test-punishment" variant="success" size="lg">ПОГАШЕНИЕ СУДИМОСТИ</b-button>
                </div>
            </b-carousel-slide>

            <b-carousel-slide style="color:black;" img-src="https://i.ibb.co/nRt32Nw/slide-background.jpg">
                <h3 class="carusel-title">«ЮРИДИЧЕСКИЙ ПРОГНОЗ» информирует:</h3>
                <p class="p-class-carusel">&#x2713; О возможности прекращения уголовного дела;</p>
                <p class="p-class-carusel">&#x2713; О возможности освобождения от уголовной ответственности;</p>
                <p class="p-class-carusel">&#x2713; О возможности назначения условного наказания.</p>

                <div class="btn-slide-position">
                    <b-button id="btn-carousel" class="button-collapse-mobile" href="/test" variant="success" size="lg">СОСТАВИТЬ ПРОГНОЗ</b-button>
                    <b-button id="btn-carousel" class="button-collapse-mobile" href="/test-punishment" variant="success" size="lg">ПОГАШЕНИЕ СУДИМОСТИ</b-button>
                </div>
            </b-carousel-slide>
        </b-carousel>
    </div>
    <div class="mobile">
         <b-carousel
            id="carousel-fade"
            controls
            fade
            interval="10000">

            <b-carousel-slide style="color:black;" img-src="https://i.ibb.co/nRt32Nw/slide-background.jpg">
                <h3 class="carusel-title-first">ЮРИДИЧЕСКИЙ ПРОГНОЗ</h3>
                <p class="p-class-carusel-first">Онлайн-прогноз</p>
                <p class="p-class-carusel-first">по уголовным делам.</p>
                <p class="p-class-carusel-first">Онлайн-калькулятор</p>
                <p class="p-class-carusel-first">погашения судимости.</p>
            </b-carousel-slide>

            <b-carousel-slide style="color:black;" img-src="https://i.ibb.co/nRt32Nw/slide-background.jpg">
                <h3 class="carusel-title">«ЮРИДИЧЕСКИЙ ПРОГНОЗ» применяет:</h3>
                <p class="p-class-carusel">&#x2713; Актуальные редакции УК И УПК РФ;</p>
                <p class="p-class-carusel">&#x2713; Положения статей 62, 66 и 68 УК РФ</p>
                <p class="p-class-carusel">в последовательности, определенной</p>
                <p class="p-class-carusel">постановлениями Пленумов ВС РФ.</p>
            </b-carousel-slide>

            <b-carousel-slide style="color:black;" img-src="https://i.ibb.co/nRt32Nw/slide-background.jpg">
                <h3 class="carusel-title">«ЮРИДИЧЕСКИЙ ПРОГНОЗ» учитывает:</h3>
                <p class="p-class-carusel">&#x2713; Возраст, пол, специальный субъект;</p>
                <p class="p-class-carusel">&#x2713; Покушение или приготовление к преступлению;</p>
                <p class="p-class-carusel">&#x2713; Порядок судопроизводства;</p>
                <p class="p-class-carusel">&#x2713; Судимость и рецидив;</p>
                <p class="p-class-carusel">&#x2713; Смягчающие и отягчающие </p>
                <p class="p-class-carusel">обстоятельства.</p>
            </b-carousel-slide>

            <b-carousel-slide style="color:black;" img-src="https://i.ibb.co/nRt32Nw/slide-background.jpg">
                <h3 class="carusel-title">«ЮРИДИЧЕСКИЙ ПРОГНОЗ» исключает ошибки:</h3>
                <p class="p-class-carusel">&#x2713; Назначения неприменимого вида наказания;</p>
                <p class="p-class-carusel">&#x2713; Выбора субъекта, который не подлежит</p>
                <p class="p-class-carusel">уголовной ответственности;</p>
                <p class="p-class-carusel">&#x2713; Признания рецидива преступления;</p>
                <p class="p-class-carusel">&#x2713; Выбора особого порядка,</p>
                <p class="p-class-carusel">когда он не применяется.</p>
            </b-carousel-slide>

            <b-carousel-slide style="color:black;" img-src="https://i.ibb.co/nRt32Nw/slide-background.jpg">
                <h3 class="carusel-title">«ЮРИДИЧЕСКИЙ ПРОГНОЗ» информирует:</h3>
                <p class="p-class-carusel">&#x2713; О возможности прекращения уголовного дела;</p>
                <p class="p-class-carusel">&#x2713; О возможности освобождения от уголовной ответственности;</p>
                <p class="p-class-carusel">&#x2713; О возможности назначения</p>
                <p class="p-class-carusel">условного наказания.</p>
            </b-carousel-slide>
        </b-carousel>
        <div class="text-center m-sm-3 m-3">
            <div class="row m-sm-3 row-btn-mobile">
                <div class="col-sm">
                    <b-button id="btn-carousel" class="button-collapse-mobile" href="/test" variant="success" size="lg">СОСТАВИТЬ ПРОГНОЗ</b-button>
                </div>
            </div>
            <div class="row m-sm-3 row-btn-mobile">
                <div class="col-sm">
                    <b-button id="btn-carousel" class="button-collapse-mobile" href="/test-punishment" variant="success" size="lg">ПОГАШЕНИЕ СУДИМОСТИ</b-button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
  export default {
    data() {
      return {
        sliding: null
      }
    }
  }
</script>

<style>
    .carousel-control-next-icon{
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e")
    }

    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
    }
    .p-class-carusel-first{
        font-family: Oswald;
        font-size: 1.9rem;
    }
    #btn-carousel {
        margin-left: 0.3rem;
    }
    .row-btn-mobile {
        margin-bottom: 0.3rem;
    }
    .p-class-carusel {
        font-family: Oswald;
        font-size: 1.6rem;
    }
    .btn-slide-position{
        position: absolute;
        right: inherit;
        left: inherit;
        bottom: 1.7rem;
    }
    .carousel-caption{
        padding-top: 0;
        margin-bottom: 1.5rem;
        color: black;
        top: 15%;
    }
    .text-center{
        margin-top: 1.5rem;
    }
    .carusel-title {
        font-family: Slovic;
        font-weight: bold;
        margin-bottom: 2rem;
        font-size: 2rem;
    }
    .carusel-title-first{
        font-family: Slovic;
        font-weight: bold;
        margin-bottom: 2rem;
        font-size: 2.9rem;
    }
    .mobile{
        display: none;
    }
    .not-mobile{
            display: inline;
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        .carusel-title-first{
            font-family: Slovic;
            font-weight: bold;
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
        }

        .carusel-title{
            font-family: Slovic;
            font-weight: bold;
            font-size: 1rem;
            margin-bottom: 0.5rem;
        }
        .p-class-carusel-first {
            font-family: Oswald;
            font-weight: bold;
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: 0.1rem;
        }
        .p-class-carusel {
            font-family: Oswald;
            margin-top: 0.1rem;
            font-weight: bold;
            margin-bottom: 0.1rem;
            font-size: 0.8rem;
        }
        .carousel-caption{
            color: black;
            top: 2%;
            padding-top:0;
            padding-bottom:0;
        }

        .carousel-control-next, .carousel-control-prev{
            width: 10%;
        }

        .btn{
            margin: 0;
            font-size: 0.8rem;
            padding: 0.3rem 0.3rem; 
        }
        .mobile{
            display: inline;
        }
        .not-mobile{
             display: none;
        }
        .text-center{
            margin-top: 3%;
        }
    }

</style>