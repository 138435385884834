<template>
    <div class="Feedback">
        <vue-headful title="Юридический прогноз | Обратная связь"
                     description="Обратная связь"
                     :keywords="page.keywords"/>
        <div>
            <b-form>
                <b-form-group
                        id="input-group-1"
                        label="Адрес почты:"
                        label-for="input-1">
                    <b-form-input
                            id="input-1"
                            v-model="email"
                            placeholder="Введите адрес почты, по которой можно с вами связаться">
                    </b-form-input>
                    <b-form-text id="email-help-block">
                        Заполняя форму обратной связи вы соглашаетесь с <a href="/privacy-policy">"Политикой конфиденциальности"</a>
                    </b-form-text>
                </b-form-group>

                <b-form-group id="input-group-2" label="Ваше Имя:" label-for="input-2">
                    <b-form-input
                            id="input-2"
                            v-model="name"
                            required
                            placeholder="Введите ваше Имя">
                    </b-form-input>
                </b-form-group>

                <b-form-textarea
                        id="textarea"
                        v-model="text"
                        placeholder="Введите ваше сообщение"
                        rows="3"
                        max-rows="6">
                </b-form-textarea>

            </b-form>
            <div>
                <button
                        style="margin-top: 1rem"
                        type="submit"
                        class="btn btn-primary"
                        v-on:click="Send">
                    Отправить
                </button>
            </div>
            <div>
                <b-alert
                        :show="dismissCountDown"
                        dismissible
                        variant="info"
                        @dismissed="dismissCountDown=0"
                        @dismiss-count-down="countDownChanged"
                        style="margin-top: .5rem">
                    <p>Сообщение отправлено, Спасибо.</p>
                    <b-progress
                            variant="info"
                            :max="dismissSecs"
                            :value="dismissCountDown"
                            height="4px"
                    ></b-progress>
                </b-alert>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Feedback",
    data () {
        return {
            page: {
                title: 'Юридический прогноз | Обратная связь',
                description: 'Static description',
                keywords: 'keywords',
                content: ""
            },
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            email: '',
            name: '',
            text: '',
            response: "",
        }
    },
    methods: {
        Send() {
            this.axios.post(`${encodeURI(window.hostname)}/api/sendFeedback`,
                {
                    "email": this.email,
                    "name": this.name,
                    "text": this.text,
                })
                .then(response => {
                    this.response = response.data;
                })
                .catch(e => {
                    this.errors.push(e.toString());
                });
            this.dismissCountDown = this.dismissSecs;
            this.response = "1";
            this.onReset();
        },

        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },

        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },

        onReset(){
            // Reset our form values
            this.email = '';
            this.name = '';
            this.text = '';
        },

        validate () {
            // тут можно добавить проверку на валидацию
            // например, с помощью vee validate
            // если с валидацией наших полей все хорошо, запускаем каптчу
            this.$refs.recaptcha.execute()
        },

        onCaptchaExpired () {
            this.$refs.recaptcha.reset()
        }
    }
}

</script>

<style scoped>

</style>
