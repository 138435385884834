import Vue from 'vue'
import Router from 'vue-router'
import services from '@/pages/test'
import servicesPunishment from '@/pages/testPunishment'
import contacts from '@/pages/contacts'
import home from '@/pages/home'

import Feedback from '@/pages/Feedback'
import GeneralInformation from '@/pages/GeneralInformation'
import PrivacyPolicy from '@/pages/PrivacyPolicy'
import UserGuide from '@/pages/UserGuide'
import UserNotification from '@/pages/UserNotification'
import FAQ from '@/pages/FAQ'
import kodex from '@/pages/kodex'

import apunishment from "@/pages/plenums/apunishment";
import apunishment_minor from "@/pages/plenums/apunishment-minor";
import aspecialorder from "@/pages/plenums/aspecialorder";
import apre_trial_agreement from "@/pages/plenums/apre-trial-agreement";

Vue.use(Router);
export default new Router({
    mode: 'history',
    base: __dirname,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/home',
            name: 'home',
            component: home,
        },
        {
            path: '/test',
            name: 'Test',
            component: services
        },
        {
            path: '/test-punishment',
            name: 'TestPunishment',
            component: servicesPunishment
        },
        /*****************************/
        {
            path: '/contacts',
            name: 'contacts',
            component: contacts
        },
        {
            path: '/feedback',
            name: 'Feedback',
            component: Feedback
        },
        {
            path: '/privacy-policy',
            name: 'PrivacyPolicy',
            component: PrivacyPolicy
        },
        {
            path: '/faq',
            name: 'FAQ',
            component: FAQ
        },
        /***********************************/
        {
            path: '/general-information',
            name: 'GeneralInformation',
            component: GeneralInformation
        },
        {
            path: '/user-notification',
            name: 'UserNotification',
            component: UserNotification
        },
        {
            path: '/user-guide',
            name: 'UserGuide',
            component: UserGuide
        },
        /*********************************/
        {
            path: '/kodex',
            name: 'kodex',
            component: kodex
        },
        /*********************************/
        {
            path: '/plenums/about-punishment',
            name: 'about-punishment',
            component: apunishment
        },
        {
            path: '/plenums/about-punishment-minor',
            name: 'about-punishment-minor',
            component: apunishment_minor
        },
        {
            path: '/plenums/about-of-a-special-order',
            name: 'about-of-a-special-order',
            component: aspecialorder
        },
        {
            path: '/plenums/about-of-pre-trial-agreement',
            name: 'about-of-pre-trial-agreement',
            component: apre_trial_agreement
        },
    ]
})
