<template>
    <div class="FAQ">
        <vue-headful title="Юридический прогноз | Часто задачаемые вопросы"
                     description="Часто задачаемые вопросы"
                     :keywords="page.keywords"/>
        <div class="text-center m-sm-3 m-3">
            <h3>Часто задаваемые вопросы</h3>
        </div>
        <div>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-1 variant="outline-primary">
                        В некоторых статьях УК не указана нижняя граница наказания. Почему в тесте по этим статьям
                        отображается нижняя граница – например, лишение свободы от 2 месяцев, штраф от 5 000 рублей?
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            Если в статье УК РФ не прописана нижняя граница наказания, это не значит, что ее нет.
                            Минимальное наказание по каждому виду предусмотрено «Общей частью» УК РФ. Мы указываем нижние
                            границы наказания в тесте для удобства Пользователя.
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2 variant="outline-primary">
                        Выбираю статью 100 УК РФ, нажимаю «далее» и ничего не происходит.
                        То же самое со статьей 37 УК РФ. Почему некоторые статьи не работают?
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            Вы выбираете статьи «Общей части» УК РФ, которые не содержат наказания за конкретные
                            преступления. Поэтому расчет по ним не может быть произведен. Сервис «Юридический прогноз»
                            предназначен для расчета уголовного наказания только по статьям «Особенной части» УК РФ,
                            которая начинается со статьи 105 и заканчивается статьей 361.
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 variant="outline-primary">
                        Ввожу статью 159.3 УК РФ, однако данная статья не отображается, как будто ее не существует.
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            Вам необходимо ввести в строке поиска статью 159, потом выбрать из предложенного списка нужную
                            вам статью 159.3 и нажать «далее». Полностью вводить номер статьи «159.3» не нужно.
                            В дальнейшем процесс ввода статей будет оптимизирован.
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-4 variant="outline-primary">
                        Ввожу статью 114 часть 2. Преступление оконченное, вину признаю, судим, рецидива нет, есть
                        смягчающее – явка с повинной. В итоге тест выдает лишение свободы от 2 месяцев до 10 месяцев.
                        Пересчитал вручную, получается - от 2 месяцев до 10,6 месяца. Почему тест округляет результаты?
                        Разве наказание не может быть назначено с точностью до дней?
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            Судебная практика показывает, что лишение свободы практически никогда не назначается с точностью
                            до дней. Дни высчитываются только в случае замены наказания. Именно поэтому за минимальную
                            единицу при расчете результатов теста по всем видам наказаний, кроме штрафа и обязательных
                            работ, взят один месяц. Когда в результате получается не целое число, происходит округление
                            в меньшую сторону, как в приведённом вами примере.
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-5 variant="outline-primary">
                        В Уголовном кодексе предусмотрен такой вид наказания, как «арест». Почему в тесте он отсутствует?
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            В настоящее время такой вид уголовного наказания как «арест» не применяется. В случае изменения
                            законодательства в работу теста будут внесены соответствующие изменения.
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-6 variant="outline-primary">
                        Почему в итоговом результате отображается несколько видов наказаний? Мне что назначат сразу
                        несколько наказаний?
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            Нет, суд назначит только один вид основного наказания. Сервис «Юридический прогноз» на последнем
                            шаге отображает все применимые в вашем случае виды наказаний за исключением дополнительных.
                            Если введенные вами параметры были корректными, то сервис исключил те виды,
                            которые суд не может вам назначить.
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-7 variant="outline-primary">
                        Выбрала в тесте статью 115 часть 2. По мере ответов на вопросы теста все наказания, которые
                        изначально отобразились по этой статье, исчезли, и в итоге появился штраф, которого сначала
                        вообще не было. Почему?
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            На основании вводимых вами параметров сервис исключает из санкции статьи те виды наказаний,
                            которые не могут быть вам назначены. В вашем случае сервис исключил все виды наказаний,
                            которые были предусмотрены санкцией ч. 2 ст. 115 УК РФ. В таком случае сервис в соответствии со
                            статьей 44 УК РФ «Виды наказаний» отображает более мягкие из применимых вам видов наказаний.
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-8 variant="outline-primary">
                        Обвиняюсь по части 1 статьи 105. Дело будет рассматриваться в общем порядке. На шаге выбора
                        смягчающих обстоятельств тест задаёт вопрос только про обстоятельства «И» и «К». У меня этих
                        обстоятельств нет, но есть другие. Почему тест не даёт возможности их ввести?
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-8" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            На данном этапе тест при расчетах сроков или размеров наказания учитывает только те параметры,
                            которые строго определены законодателем. В частности, на основании части 1 статьи 62 УК РФ при
                            наличии смягчающих обстоятельств, предусмотренных пунктами «И» и (или) «К» части 1 статьи
                            61 УК РФ, и отсутствии отягчающих обстоятельств срок или размер наказания не могут превышать
                            2/3 максимального срока или размера наиболее строгого вида наказания. Влияние других смягчающие
                            обстоятельств, предусмотренных статей 61 УК РФ,
                            на снижение наказания менее значительно и определяется судом.
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-9 variant="outline-primary">
                        Получил 10 месяцев исправительных работ по статье 159.3 УК РФ. Не судим, дело рассматривалось в
                        особом порядке, суд признал, что я активно содействовал следствию, отягчающих обстоятельств по делу
                        нет. Проверил по тесту, как было изначально наказание «до 1 года исправительных работ», так в
                        результате и осталось. Получается, что содействие следствию и особые порядки ни на что не влияют?
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-9" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            Правила статьи 62 УК РФ, которая предусматривает более мягкое наказание при рассмотрении дел в
                            порядке особого производства с учетом смягчающих обстоятельств «И» или «К», не распространяются
                            на случаи назначения менее строгого наказания, указанного в санкции статьи. Самый строгий вид
                            наказания по части 1 статьи 159.3 УК РФ - лишение свободы до 3 лет. Но оно к вам не применимо,
                            также как и принудительные работы. Поэтому тест их исключает, а исправительные работы
                            назначаются в пределах санкции. Не думаем, что это справедливо, но такова позиция* Верховного
                            Суда РФ на сегодняшний день. Следует заметить, что вы могли быть освобождены от уголовной
                            ответственности в связи с возмещением ущерба, если бы воспользовались подсказкой теста.
                        </b-card-text>
                        <b-card-text>
                            * Более подробно данный вопрос раскрыт в пункте 33 постановления Пленума Верховного суда
                            РФ № 58 от 22.12.2015 «О практике назначения судами РФ уголовного наказания» в редакции от
                            18.12.2018 года. Текст данного постановления Пленума Верховного суда вы можете посмотреть на
                            нашем сайте во вкладке «Постановления Пленумов ВС РФ».
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-10 variant="outline-primary">
                        Мой муж украл бутылку вина в сетевом магазине, стоимостью 275 рублей. Прокурор по  статье 158.1
                        УК РФ при сокращенной  форме дознания и явке с повинной запросил 4 месяца лишения свободы и пояснил,
                        что это минимальный срок при рецидиве преступлений.  А тест мне выдал результат от 2 месяцев до 6
                        месяцев лишения свободы. Кто же прав? И возможно ли в этом случае условное наказание?
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-10" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            По общему правилу при любом виде рецидива срок наказания не может быть менее 1/3 максимального
                            срока наиболее строгого вида наказания. В вашем случае это 4 месяца. Однако тест учитывает и
                            другое правило, предусмотренное  ч. 3 ст. 68 УК РФ, согласно которого, при любом виде рецидива
                            и наличии любых смягчающих обстоятельств, срок наказания может быть назначен менее 1/3.
                            Таким образом, суд был вправе назначить наказание при наличии смягчающих обстоятельств менее 4
                            месяцев лишения свободы, сославшись на ч. 3 ст. 68 УК РФ или применить условное наказание.
                            Если этого не произошло, у вас есть возможность обжаловать данный приговор.
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-11 variant="outline-primary">
                        Обвиняюсь по статье 112 части 2 УК РФ. Подрались возле шашлычной, у  потерпевшего сломана челюсть.
                        Ранее судим по малолетке за хулиганство, рецидива нет. Вину признаю, с потерпевшим примирился,
                        выплатил ущерб. Почему тест не показывает возможность
                        прекращения дела, если я примирился с потерпевшим?
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-11" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            Тест определяет такую возможность при совпадении двух параметров. Во-первых, преступление должно
                            быть небольшой или средней степени тяжести. Во-вторых, преступление должно быть совершено
                            впервые - то есть лицо должно быть либо не судимым, либо судимость должна быть погашена.
                            При выборе опции «Судим» освобождение от уголовной ответственности за примирением сторон или с
                            выплатой судебного штрафа невозможно. Сроки погашения судимости вы можете посмотреть в статье
                            86 УК РФ, кликнув в главном меню сайта на иконку «Уголовный Кодекс РФ».
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-12 variant="outline-primary">
                        Мы с другом обвиняемся по ч. 2 ст. 158 УК РФ в хищении шпал б/у с подъездных путей  РЖД. Шпалы
                        возвращены. РЖД  к нам претензий не имеет, но их юрист заявление на примирение не подает, говорит,
                        что руководство против. Судя по тесту, мы можем прекратить дело, так как ранее мы не судимы.
                    </b-button>
                </b-card-header>
                <b-collapse id="accordion-12" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>
                            Тест в таких случаях предусматривает два основания освобождения от уголовной ответственности:
                            по статье 76 УК РФ  в связи с примирением с потерпевшим и по статье 76.2 УК РФ с назначением
                            судебного штрафа. При этом освобождение от уголовной ответственности по статье 76.2 УК РФ может
                            применяться и в тех случаях, когда потерпевший не согласен на примирение и прекращение
                            уголовного дела. Данное правило также применимо, если потерпевший по делу отсутствует.
                        </b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FAQ",
        data (){
            return{
                page:{
                    title:'',
                    description: '',
                    keywords: '',
                    content: ""
                }
            }
        },
        created: function () {
            this.axios.get(`${encodeURI(window.hostname)}/api/getPage?pageName=${encodeURI("FAQ")}`).then(
                response => {
                    this.page = response.data;
                }
            );
        }
    }
</script>

<style scoped>

</style>
