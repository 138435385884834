import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import axios from 'axios'
import VueAxios from 'vue-axios'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'

import router from './router'

import vueHeadFull from 'vue-headful';
Vue.component('vue-headful', vueHeadFull);

import VueGoodshareFacebook from 'vue-goodshare/src/providers/Facebook';
import VueGoodshareVk from 'vue-goodshare/src/providers/Vkontakte';
import VueGoodshareTelegram from 'vue-goodshare/src/providers/Telegram';
import VueGoodshareViber from 'vue-goodshare/src/providers/Viber';
import VueGoodshareTwitter from 'vue-goodshare/src/providers/Twitter';
import VueGoodshareWhatsApp from 'vue-goodshare/src/providers/WhatsApp';


import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoffee, faChild, faCircle, faArchive } from '@fortawesome/free-solid-svg-icons'
import { faComment } from '@fortawesome/free-regular-svg-icons'
import { fab, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

import VueSocialSharing from 'vue-social-sharing'

import { VueReCaptcha } from 'vue-recaptcha-v3'

library.add(
    faCoffee, faFacebookF,
    faChild,
    faCircle,
    faArchive,
    faComment,
    fab
);

Vue.use(VueSocialSharing, {
  networks: {
    fakeblock: 'https://fakeblock.com/share?url=@url&title=@title'
  }
});

Vue.use(VueReCaptcha, {
  siteKey: '6LfocKoZAAAAAEdqtezI551m68sRAXRMnJom9pAc',
  loaderOptions: {
    useRecaptchaNet: false,
    autoHideBadge: true,
  }
});

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('vue-goodshare-facebook', VueGoodshareFacebook);
Vue.component('vue-goodshare-vkontakte', VueGoodshareVk);
Vue.component('vue-goodshare-telegram', VueGoodshareTelegram);
Vue.component('vue-goodshare-viber', VueGoodshareViber);
Vue.component('vue-goodshare-twiter', VueGoodshareTwitter);
Vue.component('vue-goodshare-whats-app', VueGoodshareWhatsApp);


Vue.use(Autocomplete);
Vue.use(BootstrapVue);
Vue.use(VueAxios, axios);
Vue.config.productionTip = false;
window.hostname = 'https://aisud.ru';

new Vue({
  render: h => h(App),
  router
}).$mount('#app');
