<template>
    <div class="PrivacyPolicy">
        <vue-headful :title="page.title"
                     :description="page.description"
                     :keywords="page.keywords"/>
        <div v-html="page.content"></div>
    </div>
</template>

<script>
    export default {
        name: "PrivacyPolicy",
        data (){
            return{
                page:{
                    title:'',
                    description: '',
                    keywords: '',
                    content:""
                }
            }
        },
        created: function () {
            this.axios.get(`${encodeURI(window.hostname)}/api/getPage?pageName=${encodeURI("PrivacyPolicy")}`).then(
                response => {
                    this.page = response.data;
                }
            );
            this.page.title = "Юридический прогноз | Политика конфиденциальности";
            this.page.description = "Политика конфиденциальности";
        }
    }
</script>

<style scoped>
</style>
