<template>
    <div class="apunishment">
        <vue-headful :title="page.title"
                     :description="page.description"
                     :keywords="page.keywords"/>
        <div class="calibre" id="calibre_link-0">
            <p class="block_">Постановление Пленума Верховного суда Российской Федерации</p>
            <p class="block_1">«О практике назначения судами Российской Федерации уголовного наказания» </p>
            <p class="block_2">(от 22 декабря 2015 г. N 58 с изменениями от 18.12.2018 N 43)</p>
            <p class="block_3">В связи с вопросами, возникающими у судов при назначении уголовного наказания, и изменениями, внесенными в Уголовный кодекс Российской Федерации и Уголовно-процессуальный кодекс Российской Федерации, в целях обеспечения правильного и единообразного применения закона Пленум Верховного Суда Российской Федерации, руководствуясь статьей 126 Конституции Российской Федерации, статьями 2 и 5 Федерального конституционного закона от 5 февраля 2014 года N 3-ФКЗ "О Верховном Суде Российской Федерации", <b class="calibre1">постановляет</b> дать судам следующие разъяснения:</p>
            <p class="block_3">1. Обратить внимание судов на необходимость исполнения требований закона о строго индивидуальном подходе к назначению наказания, имея в виду, что справедливое наказание способствует решению задач и достижению целей, указанных в статьях 2 и 43 Уголовного кодекса Российской Федерации (далее - УК РФ).</p>
            <p class="block_3">Согласно статье 6 УК РФ справедливость наказания заключается в его соответствии характеру и степени общественной опасности преступления, обстоятельствам его совершения и личности виновного.</p>
            <p class="block_3">Характер общественной опасности преступления определяется уголовным законом и зависит от установленных судом признаков состава преступления. При учете характера общественной опасности преступления судам следует иметь в виду прежде всего направленность деяния на охраняемые уголовным законом социальные ценности и причиненный им вред.</p>
            <p class="block_3">Степень общественной опасности преступления устанавливается судом в зависимости от конкретных обстоятельств содеянного, в частности от характера и размера наступивших последствий, способа совершения преступления, роли подсудимого в преступлении, совершенном в соучастии, от вида умысла (прямой или косвенный) либо неосторожности (легкомыслие или небрежность). Обстоятельства, смягчающие или отягчающие наказание (статьи 61 и 63 УК РФ) и относящиеся к совершенному преступлению (например, совершение преступления в силу стечения тяжелых жизненных обстоятельств либо по мотиву сострадания, особо активная роль в совершении преступления), также учитываются при определении степени общественной опасности преступления.</p>
            <p class="block_3">К сведениям о личности, которые подлежат учету при назначении наказания, относятся характеризующие виновного сведения, которыми располагает суд при вынесении приговора. К таковым могут, в частности, относиться данные о семейном и имущественном положении совершившего преступление лица, состоянии его здоровья, поведении в быту, наличии у него на иждивении несовершеннолетних детей, иных нетрудоспособных лиц (супруги, родителей, других близких родственников). Исходя из положений части 6 статьи 86 УК РФ суды не должны учитывать в качестве отрицательно характеризующих личность подсудимого данные, свидетельствующие о наличии у него погашенных или снятых в установленном порядке судимостей.</p>
            <p class="block_3">В силу требований статей 307 и 308 Уголовно-процессуального кодекса Российской Федерации (далее - УПК РФ) в приговоре следует указывать мотивы принятых решений по всем вопросам, относящимся к назначению уголовного наказания, освобождению от него или его отбыванию.</p>
            <p class="block_4">Штраф</p>
            <p class="block_3">2. В соответствии со статьей 46 УК РФ штраф при любом способе его исчисления должен быть определен в виде денежного взыскания. В резолютивной части приговора следует указывать способ исчисления штрафа и сумму штрафа в денежном выражении.</p>
            <p class="block_3">Размер штрафа определяется судом с учетом тяжести совершенного преступления, имущественного положения осужденного и его семьи, а также с учетом возможности получения осужденным заработной платы или иного дохода (часть 3 статьи 46 УК РФ). В этих целях следует выяснять наличие или отсутствие места работы у осужденного, размер его заработной платы или иного дохода, возможность трудоустройства, наличие имущества, иждивенцев и т.п.</p>
            <p class="block_3">Исходя из положений части 2 статьи 46 УК РФ минимальный размер штрафа, назначенного за совершенное преступление в определенной сумме, в том числе с применением положений статьи 64 УК РФ, не может быть ниже пяти тысяч рублей, а при его назначении в размере заработной платы или иного дохода осужденного - за период менее двух недель. К иным доходам следует относить доходы, подлежащие налогообложению в соответствии с действующим законодательством.</p>
            <p class="block_3">3. Размер штрафа, исчисляемый исходя из величины, кратной стоимости предмета или сумме коммерческого подкупа, подкупа работника контрактной службы, контрактного управляющего, члена комиссии по осуществлению закупок и иных уполномоченных лиц, представляющих интересы заказчика в сфере закупок товаров, работ, услуг для обеспечения государственных или муниципальных нужд, взятки или сумме незаконно перемещенных денежных средств и (или) стоимости денежных инструментов, не может быть менее двадцати пяти тысяч рублей, даже если сумма, рассчитанная с учетом кратной величины, меньше двадцати пяти тысяч рублей. В таком случае штраф назначается в размере двадцати пяти тысяч рублей.</p>
            <p class="block_3">(в ред. Постановления Пленума Верховного Суда РФ от 18.12.2018 N 43)</p>
            <p class="block_3">При назначении в качестве основного наказания штрафа, исчисляемого указанным способом, в случае применения статьи 64 УК РФ может быть уменьшена величина кратности, предусмотренная соответствующей статьей Особенной части УК РФ (в таком случае размер штрафа также не может быть менее двадцати пяти тысяч рублей). Изменение способа исчисления штрафа (например, назначение штрафа в определенном размере без применения кратности) в таком случае не допускается.</p>
            <p class="block_3">4. При назначении штрафа в качестве основного наказания осужденному, содержавшемуся под стражей, суд вправе с учетом срока содержания под стражей либо полностью освободить такое лицо от отбывания данного наказания, либо смягчить его (часть 5 статьи 72 УК РФ). В случае смягчения наказания размер штрафа может быть ниже минимального предела, установленного санкцией соответствующей статьи Особенной части УК РФ, но не ниже минимального предела, установленного частью 2 статьи 46 УК РФ для конкретного способа исчисления штрафа.</p>
            <p class="block_3">5. Одновременно с штрафом, назначенным в качестве основного наказания, допускается назначение за то же преступление дополнительного наказания в виде лишения права занимать определенные должности или заниматься определенной деятельностью, а также лишение специального, воинского или почетного звания, классного чина и государственных наград при условии соблюдения правил применения этих видов наказаний, установленных частью 3 статьи 47 и статьей 48 УК РФ.</p>
            <p class="block_3">6. Исходя из положений части 3 статьи 46 УК РФ штраф может быть назначен как без рассрочки, так и с рассрочкой выплаты до пяти лет. При назначении штрафа с рассрочкой выплаты суду необходимо мотивировать свое решение в приговоре и определить конкретные сроки выплат частями с учетом положений части 3 статьи 31 Уголовно-исполнительного кодекса Российской Федерации (далее - УИК РФ), а также суммы (размеры) выплат в пределах установленного судом срока.</p>
            <p class="block_3">7. В случае назначения штрафа в качестве основного наказания за одно из преступлений при определении окончательного наказания по совокупности преступлений или приговоров в резолютивной части приговора должно быть указано на применение статьи 69 или статьи 70 УК РФ, а также на самостоятельное исполнение штрафа.</p>
            <p class="block_3">Частями 2 и 3 статьи 32 УИК РФ предусмотрен различный порядок исполнения основного наказания и дополнительного наказания в виде штрафа. Исходя из этого при назначении наказания по совокупности преступлений не допускается сложение сумм штрафа, назначенного в качестве основного и дополнительного видов наказаний за разные преступления.</p>
            <p class="block_3">7.1. С учетом того, что судебный штраф, назначаемый на основании статьи 76.2 УК РФ лицу, освобожденному от уголовной ответственности, не является уголовным наказанием, а относится к иным мерам уголовно-правового характера, предусмотренным разделом VI УК РФ "Иные меры уголовно-правового характера", правила статьи 46 УК РФ к назначению и исполнению судебного штрафа не применяются.</p>
            <p class="block_4">Лишение права занимать определенные должности</p>
            <p class="block_4">или заниматься определенной деятельностью</p>
            <p class="block_3">8. По смыслу закона, лишение права занимать определенные должности или заниматься определенной деятельностью, по общему правилу, может быть назначено в качестве основного или дополнительного (в том числе в соответствии с частью 3 статьи 47 УК РФ) наказания за преступление, которое связано с определенной должностью или деятельностью лица.</p>
            <p class="block_3">В случаях, когда статья Особенной части УК РФ предусматривает обязательное назначение такого дополнительного наказания, оно назначается и при отсутствии связи преступления с определенной должностью или деятельностью лица. При этом запрещение занимать определенные должности или заниматься определенной деятельностью должно быть обусловлено обстоятельствами совершенного преступления (например, лишение права заниматься деятельностью, связанной с работой с детьми, при осуждении по части 5 статьи 131 УК РФ).</p>
            <p class="block_3">Лишение права занимать определенные должности или заниматься определенной деятельностью может быть назначено и тем лицам, которые выполняли соответствующие служебные обязанности временно, по приказу или распоряжению вышестоящего уполномоченного лица либо к моменту постановления приговора уже не занимали должности и не занимались деятельностью, с которыми были связаны совершенные преступления.</p>
            <p class="block_3">9. Обратить внимание судов на то, что лишение права занимать определенные должности состоит в запрещении занимать должности только на государственной службе или в органах местного самоуправления. В приговоре необходимо указывать не конкретную должность (например, главы органа местного самоуправления, старшего бухгалтера) либо категорию и (или) группу должностей по соответствующему реестру должностей (например, категорию "руководители", группу "главные должности муниципальной службы"), а определенный конкретными признаками круг должностей, на который распространяется запрещение (например, должности, связанные с осуществлением функций представителя власти, организационно-распорядительных и (или) административно-хозяйственных полномочий).</p>
            <p class="block_3">Лишение права заниматься определенной деятельностью может выражаться в запрещении заниматься как профессиональной, так и иной деятельностью. В приговоре следует конкретизировать вид такой деятельности (педагогическая, врачебная, управление транспортом и т.д.).</p>
            <p class="block_3">Рекомендовать судам при назначении дополнительного наказания в виде лишения права заниматься определенной деятельностью при наличии к тому оснований и с учетом обстоятельств, смягчающих и отягчающих наказание, обсуждать вопрос о целесообразности его применения в отношении лица, для которого соответствующая деятельность связана с его единственной профессией.</p>
            <p class="block_3">10. Если соответствующая статья Особенной части УК РФ предусматривает лишение права занимать определенные должности или заниматься определенной деятельностью в качестве одного из основных видов наказаний, то в случае назначения другого вида основного наказания суд вправе применить положения части 3 статьи 47 УК РФ (например, в случае назначения по части 3 статьи 160 УК РФ наказания в виде лишения свободы может быть назначено лишение права занимать определенные должности или заниматься определенной деятельностью, хотя этот же вид наказания входит в число основных наказаний).</p>
            <p class="block_3">Для применения части 3 статьи 47 УК РФ не имеет значения, предусмотрен ли соответствующей статьей Особенной части УК РФ другой вид дополнительного наказания (например, в случае назначения по части 3 статьи 160 УК РФ наказания в виде лишения свободы со штрафом допускается назначение и лишения права занимать определенные должности или заниматься определенной деятельностью).</p>
            <p class="block_3">Если санкция соответствующей статьи предусматривает лишение права занимать определенные должности или заниматься определенной деятельностью в качестве дополнительного наказания только к отдельным видам основного наказания, то в случае назначения другого вида основного наказания такое дополнительное наказание может быть применено на основании части 3 статьи 47 УК РФ (например, по части 1 статьи 264 УК РФ данное дополнительное наказание может быть назначено при назначении основного наказания не только в виде лишения свободы, но и в виде ограничения свободы).</p>
            <p class="block_3">11. За одно и то же преступление осужденному не может быть назначено одновременно лишение права занимать определенные должности и заниматься определенной деятельностью. Это правило распространяется на назначение как основного, так и дополнительного наказания за одно преступление. Осужденному за одно преступление указанные наказания не могут быть назначены одновременно в качестве основного и дополнительного.</p>
            <p class="block_3">Вместе с тем при назначении наказания по совокупности преступлений или приговоров допускается одновременное назначение лишения права занимать определенные должности и заниматься определенной деятельностью, если эти наказания назначены за разные преступления или по разным приговорам. Кроме этого, в таких случаях не исключается назначение лишения права занимать несколько определенных должностей и заниматься несколькими видами определенной деятельности, если запреты касаются разных должностей или сфер деятельности. При этом сроки таких наказаний сложению не подлежат.</p>
            <p class="block_4">Лишение специального, воинского или почетного звания,</p>
            <p class="block_4">классного чина и государственных наград</p>
            <p class="block_3">12. Дополнительное наказание в виде лишения специального, воинского или почетного звания, классного чина и государственных наград может быть назначено при осуждении лишь за совершение тяжкого или особо тяжкого преступления (статья 48 УК РФ). При применении к осужденному данного вида наказания судам наряду с тяжестью преступления следует учитывать и другие указанные в статье 60 УК РФ обстоятельства.</p>
            <p class="block_3">При условном осуждении в приговоре должно быть указано, почему невозможно сохранение подсудимому соответствующего звания или классного чина и наград при одновременном применении к нему условного осуждения.</p>
            <p class="block_3">13. Судам следует иметь в виду, что перечень правоограничений, указанных в статье 48 УК РФ, является исчерпывающим и расширительному толкованию не подлежит. Суд не вправе лишать осужденного ученых степеней, ученых званий и других званий, носящих квалификационный характер.</p>
            <p class="block_4">Обязательные работы и исправительные работы</p>
            <p class="block_3">14. С учетом того, что наказания в виде обязательных работ и исправительных работ предполагают привлечение осужденного к труду, суд должен выяснять трудоспособность такого лица, наличие или отсутствие у него основного места работы, место постоянного жительства, а также другие свидетельствующие о возможности исполнения этих видов наказаний обстоятельства, в том числе указанные в части 4 статьи 49 и части 5 статьи 50 УК РФ.</p>
            <p class="block_3">15. По смыслу закона, указание в приговоре места отбывания осужденным исправительных работ (по основному месту работы либо в местах, определяемых органами местного самоуправления по согласованию с уголовно-исполнительными инспекциями, но в районе места жительства осужденного) не требуется, место отбывания определяется уголовно-исполнительной инспекцией при исполнении приговора в зависимости от наличия или отсутствия у лица основного места работы.</p>
            <p class="block_3">16. При назначении наказания по совокупности преступлений, за каждое из которых назначены исправительные работы, при применении принципа полного или частичного сложения наказаний сложению подлежат только сроки исправительных работ. Проценты удержаний не складываются.</p>
            <p class="block_3">При назначении наказания в виде исправительных работ по совокупности приговоров могут присоединяться лишь сроки исправительных работ. В этих случаях, назначив по последнему приговору наказание в виде исправительных работ с удержанием из заработной платы определенного процента в доход государства, суд полностью или частично присоединяет к этому наказанию неотбытый срок исправительных работ по предыдущему приговору, оставляя для присоединяемого срока прежний размер удержаний.</p>
            <p class="block_3">Окончательное наказание в виде обязательных работ или исправительных работ, назначенное по совокупности преступлений или совокупности приговоров, не может превышать предельные сроки, установленные для этих видов наказаний в части 2 статьи 49 и части 2 статьи 50 УК РФ.</p>
            <p class="block_4">Ограничение свободы</p>
            <p class="block_3">17. Исходя из положений части 1 статьи 53 УК РФ в приговоре осужденному к наказанию в виде ограничения свободы должны быть обязательно установлены ограничение на изменение места жительства или пребывания и ограничение на выезд за пределы территории соответствующего муниципального образования без согласия уголовно-исполнительной инспекции, а также должна быть возложена на него обязанность являться в уголовно-исполнительную инспекцию для регистрации.</p>
            <p class="block_3">Суд не вправе установить осужденному ограничения и возложить на него обязанности, не предусмотренные статьей 53 УК РФ.</p>
            <p class="block_3">18. В случае назначения ограничения свободы в качестве основного наказания в приговоре необходимо устанавливать территорию, за пределы которой осужденному запрещается выезжать и в пределах которой ему запрещается посещать определенные места без согласия уголовно-исполнительной инспекции. Если в состав населенного пункта, в котором проживает осужденный, входят несколько муниципальных образований, то суд вправе установить соответствующие ограничения в пределах территории такого населенного пункта. Если населенный пункт является частью муниципального образования, то ограничения устанавливаются в пределах территории муниципального образования, а не населенного пункта.</p>
            <p class="block_3">Исходя из положений части 4 статьи 50 УИК РФ вопрос о возможности выезда осужденного, который проживает и (или) работает и (или) учится в разных муниципальных образованиях, за пределы территории соответствующего муниципального образования в целях осуществления трудовой деятельности или получения образования разрешается уголовно-исполнительной инспекцией.</p>
            <p class="block_3">В случае назначения ограничения свободы в качестве дополнительного наказания к лишению свободы при установлении ограничений на выезд за пределы территории соответствующего муниципального образования и на посещение определенных мест, расположенных в пределах соответствующего муниципального образования, указание конкретного муниципального образования в приговоре не требуется. В таком случае суд, установив соответствующие ограничения, указывает в приговоре, что они действуют в пределах того муниципального образования, где осужденный будет проживать после отбывания лишения свободы. Исходя из положений части 3 статьи 47.1 УИК РФ наименование муниципального образования будет определяться той уголовно-исполнительной инспекцией, в которой осужденный должен будет встать на учет в соответствии с предписанием, полученным при освобождении из учреждения, в котором он отбывал лишение свободы.</p>
            <p class="block_3">19. При установлении ограничения на посещение определенных мест суду следует указывать признаки таких мест (например, мест общественного питания, в которых разрешено потребление алкогольной продукции, детских учреждений).</p>
            <p class="block_3">Ограничение в виде запрета на посещение мест проведения массовых мероприятий и участие в них может касаться как всех массовых мероприятий, так и тех из них, посещение которых и участие в которых, по мнению суда, будут препятствовать достижению целей наказания. Массовыми являются, например, общественно-политические (собрания, митинги, уличные шествия, демонстрации и др.), культурно-зрелищные (фестивали, профессиональные праздники, народные гуляния и др.) и спортивные (олимпиады, спартакиады, универсиады, соревнования по различным видам спорта и др.) мероприятия.</p>
            <p class="block_3">Возлагая на осужденного обязанность являться для регистрации в уголовно-исполнительную инспекцию, суд должен указать конкретное число явок в течение месяца.</p>
            <p class="block_3">20. При решении вопроса о возможности применения ограничения свободы с учетом положений части 6 статьи 53 УК РФ суду следует иметь в виду, что одно лишь отсутствие регистрации по месту жительства или пребывания лица не может являться основанием для вывода об отсутствии у него места постоянного проживания на территории Российской Федерации. Отсутствие регистрации по месту жительства или пребывания лица может являться основанием для вывода об отсутствии у него места постоянного проживания на территории Российской Федерации в совокупности с данными о его личности, которые, например, указывают на склонность лица к постоянной смене места жительства или неспособность обеспечить себе постоянное место проживания.</p>
            <p class="block_3">21. В случае сложения ограничения свободы, назначенного в качестве основного наказания, с наказанием в виде обязательных работ или исправительных работ судам следует учитывать положения части 2 статьи 72 УК РФ (240 часов обязательных работ или три месяца исправительных работ соответствуют двум месяцам ограничения свободы).</p>
            <p class="block_3">Ограничение свободы, назначенное в качестве дополнительного наказания, подлежит самостоятельному исполнению.</p>
            <p class="block_3">22. При назначении ограничения свободы в качестве основного или дополнительного наказания за каждое или некоторые из преступлений, образующих совокупность, срок ограничения свободы необходимо указывать за каждое из таких преступлений, а соответствующие ограничения и обязанность - после назначения окончательного наказания.</p>
            <p class="block_4">Принудительные работы</p>
            <p class="block_3">22.1. Исходя из положений части 1 статьи 53.1 УК РФ при назначении наказания принудительные работы применяются как альтернатива лишению свободы лишь в случаях, когда совершено преступление небольшой или средней тяжести либо впервые тяжкое преступление и только когда данный вид наказания наряду с лишением свободы прямо предусмотрен санкциями соответствующих статей Особенной части УК РФ.</p>
            <p class="block_3">Судам следует иметь в виду, что в тех случаях, когда в силу требований закона осужденному не может быть назначено наказание в виде лишения свободы (например, часть 1 статьи 56 УК РФ), принудительные работы не назначаются.</p>
            <p class="block_3">22.2. В соответствии с положениями пункта 7.1 части 1 статьи 299 УПК РФ при постановлении обвинительного приговора суд обязан разрешить вопрос о том, имеются ли основания для замены наказания в виде лишения свободы принудительными работами в случаях и порядке, установленных статьей 53.1 УК РФ.</p>
            <p class="block_3">При наличии таких оснований суд должен привести мотивы, по которым пришел к выводу о возможности исправления осужденного без реального отбывания наказания в местах лишения свободы и применения положений статьи 53.1 УК РФ. В резолютивной части приговора вначале следует указать на назначение наказания в виде лишения свободы на определенный срок, а затем - на замену лишения свободы принудительными работами.</p>
            <p class="block_3">22.3. При замене лишения свободы принудительными работами дополнительное наказание, предусмотренное к лишению свободы, в том числе и в качестве обязательного, не назначается. Суд, заменив лишение свободы принудительными работами, должен решить вопрос о назначении дополнительного наказания, предусмотренного санкцией соответствующей статьи Особенной части УК РФ к принудительным работам.</p>
            <p class="block_3">22.4. Если суд придет к выводу о возможности применения принудительных работ как альтернативы лишению свободы к лицу, совершившему два и более преступления, то такое решение принимается за совершение каждого преступления, а не при определении окончательного наказания по совокупности преступлений.</p>
            <p class="block_3">В случае назначения наказания по совокупности преступлений, за каждое из которых суд в соответствии со статьей 53.1 УК РФ заменил лишение свободы принудительными работами, сложению подлежат только сроки принудительных работ. Проценты удержаний не складываются.</p>
            <p class="block_3">22.5. Обратить внимание судов на то, что при исчислении сроков погашения судимости в отношении лиц, которым назначено наказание в виде принудительных работ в качестве альтернативы лишению свободы в соответствии со статьей 53.1 УК РФ, необходимо руководствоваться положениями пункта "б" части 3 статьи 86 УК РФ о погашении судимости по истечении одного года после отбытия наказания.</p>
            <p class="block_3">22.6. Судам следует иметь в виду, что при замене наказания в случае злостного уклонения от его отбывания в порядке исполнения приговора, предусмотренном пунктом 2 статьи 397 УПК РФ, штраф, обязательные работы, исправительные работы, ограничение свободы заменяются принудительными работами без предварительной замены лишением свободы. Принудительные работы могут быть также применены при замене неотбытой части наказания в виде лишения свободы (статья 80 УК РФ).</p>
            <p class="block_3">В этих случаях принудительные работы применяются судом независимо от того, предусмотрено ли данное наказание санкцией статьи Особенной части УК РФ, по которой было назначено заменяемое наказание, при условии соблюдения требований части 7 статьи 53.1 УК РФ. Данное правило с учетом положений части 5 статьи 46 УК РФ не распространяется на замену штрафа в размере, исчисляемом исходя из величины, кратной стоимости предмета или сумме коммерческого подкупа или взятки, назначенного в качестве основного наказания.</p>
            <p class="block_4">Ограничение по военной службе и содержание в дисциплинарной</p>
            <p class="block_4">воинской части</p>
            <p class="block_3">23. В соответствии с частью 1 статьи 51 УК РФ ограничение по военной службе назначается только военнослужащим, проходящим военную службу по контракту. К военнослужащим, проходящим службу по призыву, этот вид наказания применяться не может. Ограничение по военной службе относится к основным наказаниям и назначается в случаях, когда это прямо предусмотрено соответствующими статьями Особенной части УК РФ за преступления против военной службы, либо вместо исправительных работ, предусмотренных соответствующими статьями Особенной части УК РФ.</p>
            <p class="block_3">Согласно части 2 статьи 51 УК РФ из денежного довольствия осужденного к ограничению по военной службе производятся удержания в доход государства в размере, установленном приговором суда, но не свыше 20 процентов.</p>
            <p class="block_3">При назначении наказания в виде ограничения по военной службе по совокупности преступлений и совокупности приговоров применяются правила назначения по совокупности преступлений и совокупности приговоров наказания в виде исправительных работ.</p>
            <p class="block_3">24. Содержание в дисциплинарной части назначается как в случаях, прямо предусмотренных соответствующими статьями УК РФ (за совершение преступлений против военной службы), так и в случаях, когда характер преступления и личность виновного свидетельствуют о возможности замены лишения свободы на срок не свыше двух лет содержанием в дисциплинарной воинской части на тот же срок (часть 1 статья 55 УК РФ).</p>
            <p class="block_3">При замене лишения свободы на содержание в дисциплинарной воинской части военнослужащему должно быть назначено наказание в виде лишения свободы, которое со ссылкой на часть 1 статьи 55 УК РФ заменяется на содержание в дисциплинарной воинской части на тот же срок.</p>
            <p class="block_3">Замена лишения свободы на содержание в дисциплинарной воинской части возможна и в том случае, когда в соответствии со статьей 64 УК РФ назначено лишение свободы ниже низшего предела, предусмотренного соответствующей статьей Особенной части УК РФ.</p>
            <p class="block_3">25. Если военнослужащий совершил преступление в период прохождения военной службы, но на момент вынесения приговора утратил статус военнослужащего, ему не может быть назначено ограничение по военной службе или содержание в дисциплинарной воинской части. При этом в случае отсутствия в санкции статьи Особенной части УК РФ другого вида наказания, ему следует назначить более мягкое наказание, чем предусмотрено санкцией соответствующей статьи. Ссылка на статью 64 УК РФ в таких случаях не требуется.</p>
            <p class="block_4">Лишение свободы на определенный срок</p>
            <p class="block_3">26. В случае, если в санкции статьи наряду с лишением свободы предусмотрены другие виды наказаний, решение суда о назначении лишения свободы должно быть мотивировано в приговоре.</p>
            <p class="block_3">При наличии обстоятельств, препятствующих в соответствии с частью 1 статьи 56 УК РФ назначению наказания в виде лишения свободы, военнослужащим, иностранным гражданам, лицам без гражданства, а также лицам, не имеющим места постоянного проживания на территории Российской Федерации, по статьям Особенной части УК РФ, в санкциях которых предусмотрено только лишение свободы и ограничение свободы, следует назначать более мягкое наказание, чем предусмотрено соответствующей статьей, без ссылки на статью 64 УК РФ.</p>
            <p class="block_4">Общие начала назначения наказания</p>
            <p class="block_3">27. В соответствии с общими началами назначения наказания (часть 1 статьи 60 УК РФ) более строгий вид наказания из числа предусмотренных за совершенное преступление назначается только в случае, если менее строгий вид наказания не сможет обеспечить достижение целей наказания.</p>
            <p class="block_3">В том случае, когда осужденному в силу положений, установленных законом, не может быть назначен ни один из предусмотренных соответствующей статьей Особенной части УК РФ видов наказаний (например, обязательные работы - в силу части 4 статьи 49 УК РФ, исправительные работы - в силу части 5 статьи 50 УК РФ, арест - в связи с его неприменением в настоящее время, лишение свободы - в силу части 1 статьи 56 УК РФ), ему следует назначить любое более мягкое наказание, чем предусмотрено санкцией соответствующей статьи. Ссылка на статью 64 УК РФ в таком случае не требуется.</p>
            <p class="block_3">(в ред. Постановления Пленума Верховного Суда РФ от 18.12.2018 N 43)</p>
            <p class="block_3">В силу части 3 статьи 60 УК РФ при назначении наказания судам наряду с характером и степенью общественной опасности преступления, данными о личности виновного, обстоятельствами, смягчающими и отягчающими наказание, также надлежит учитывать влияние назначенного наказания на исправление осужденного и условия жизни его семьи (например, возможную утрату членами семьи осужденного средств к существованию в силу возраста, состояния здоровья), при этом могут быть приняты во внимание и фактические семейные отношения.</p>
            <p class="block_4">Учет обстоятельств, смягчающих и отягчающих наказание</p>
            <p class="block_3">28. Установление обстоятельств, смягчающих и отягчающих наказание, имеет важное значение при назначении лицу, совершившему преступление, как основного, так и дополнительного наказания. В связи с этим в приговоре следует указывать, какие обстоятельства суд признает смягчающими и отягчающими наказание.</p>
            <p class="block_3">В соответствии с частью 2 статьи 61 УК РФ перечень обстоятельств, смягчающих наказание, не является исчерпывающим. В качестве обстоятельства, смягчающего наказание, суд вправе признать признание вины, в том числе и частичное, раскаяние в содеянном, наличие несовершеннолетних детей при условии, что виновный принимает участие в их воспитании, материальном содержании и преступление не совершено в отношении их, наличие на иждивении виновного престарелых лиц, его состояние здоровья, наличие инвалидности, государственных и ведомственных наград, участие в боевых действиях по защите Отечества и др.</p>
            <p class="block_3">Предусмотренный статьей 63 УК РФ перечень обстоятельств, отягчающих наказание, является исчерпывающим и расширительному толкованию не подлежит. С учетом этого, обстоятельства, отягчающие наказание, в приговоре должны быть указаны таким образом, как они предусмотрены в уголовном законе.</p>
            <p class="block_3">29. Под явкой с повинной, которая в силу пункта "и" части 1 статьи 61 УК РФ является обстоятельством, смягчающим наказание, следует понимать добровольное сообщение лица о совершенном им или с его участием преступлении, сделанное в письменном или устном виде.</p>
            <p class="block_3">Не может признаваться добровольным заявление о преступлении, сделанное лицом в связи с его задержанием по подозрению в совершении этого преступления. Признание лицом своей вины в совершении преступления в таких случаях может быть учтено судом в качестве иного смягчающего обстоятельства в порядке части 2 статьи 61 УК РФ или, при наличии к тому оснований, как активное способствование раскрытию и расследованию преступления.</p>
            <p class="block_3">Добровольное сообщение лица о совершенном им или с его участием преступлении признается явкой с повинной и в том случае, когда лицо в дальнейшем в ходе предварительного расследования или в судебном заседании не подтвердило сообщенные им сведения.</p>
            <p class="block_3">Заявление лица, задержанного по подозрению в совершении конкретного преступления, об иных совершенных им преступлениях следует признавать явкой с повинной и учитывать при назначении наказания при осуждении за эти преступления.</p>
            <p class="block_3">При совокупности совершенных преступлений явка с повинной как обстоятельство, смягчающее наказание, учитывается при назначении наказания за преступление, в связи с которым лицо явилось с повинной.</p>
            <p class="block_3">30. Активное способствование раскрытию и расследованию преступления следует учитывать в качестве смягчающего наказание обстоятельства, предусмотренного пунктом "и" части 1 статьи 61 УК РФ, если лицо о совершенном с его участием преступлении либо о своей роли в преступлении представило органам дознания или следствия информацию, имеющую значение для раскрытия и расследования преступления (например, указало лиц, участвовавших в совершении преступления, сообщило их данные и место нахождения, сведения, подтверждающие их участие в совершении преступления, а также указало лиц, которые могут дать свидетельские показания, лиц, которые приобрели похищенное имущество; указало место сокрытия похищенного, место нахождения орудий преступления, иных предметов и документов, которые могут служить средствами обнаружения преступления и установления обстоятельств уголовного дела).</p>
            <p class="block_3">Под действиями, направленными на заглаживание вреда, причиненного потерпевшему (пункт "к" части 1 статьи 61 УК РФ), следует понимать оказание в ходе предварительного расследования или судебного производства по уголовному делу какой-либо помощи потерпевшему (например, оплату лечения), а также иные меры, направленные на восстановление нарушенных в результате преступления прав и законных интересов потерпевшего.</p>
            <p class="block_3">31. В соответствии с частью 1.1 статьи 63 УК РФ само по себе совершение преступления в состоянии опьянения, вызванном употреблением алкоголя, наркотических средств, психотропных веществ или их аналогов, новых потенциально опасных психоактивных веществ либо других одурманивающих веществ, не является основанием для признания такого состояния обстоятельством, отягчающим наказание. В описательно-мотивировочной части приговора должны быть указаны мотивы, по которым суд пришел к выводу о необходимости признания указанного состояния лица в момент совершения преступления отягчающим обстоятельством.</p>
            <p class="block_3">Судам следует иметь в виду, что при совершении преступлений, предусмотренных частями 2, 4, 6 статьи 264 и статьей 264.1 УК РФ, состояние опьянения устанавливается в соответствии с примечанием 2 к статье 264 УК РФ. В остальных случаях состояние лица может быть подтверждено как медицинскими документами, так и показаниями подсудимого, потерпевшего или иными доказательствами.</p>
            <p class="block_3">32. Обратить внимание судов на то, что исходя из положений части 2 статьи 63 УК РФ обстоятельства, относящиеся к признакам состава преступления, предусмотренного соответствующей статьей Особенной части УК РФ, должны учитываться при оценке судом характера общественной опасности содеянного. Однако эти же обстоятельства не могут быть повторно учтены при назначении наказания, например:</p>
            <p class="block_3">в случае совершения преступления против половой неприкосновенности потерпевших, не достигших четырнадцатилетнего возраста, квалифицированного по части 5 статьи 131 УК РФ или по части 5 статьи 132 УК РФ, лицом, имеющим судимость за ранее совершенное преступление против половой неприкосновенности несовершеннолетнего, суд не вправе учитывать такую судимость в качестве отягчающего наказание обстоятельства, предусмотренного пунктом "а" части 1 статьи 63 УК РФ;</p>
            <p class="block_3">в случае посягательства на жизнь сотрудника правоохранительного органа, квалифицированного по статье 317 УК РФ, суд не вправе учитывать наступление тяжких последствий в результате совершения преступления (смерть потерпевшего или потерпевших) в качестве отягчающего наказание обстоятельства, предусмотренного пунктом "б" части 1 статьи 63 УК РФ;</p>
            <p class="block_3">в случае умышленного причинения тяжкого вреда здоровью потерпевшего с использованием взрывчатых веществ, квалифицированного по пункту "в" части 2 статьи 111 УК РФ по признаку совершения преступления общеопасным способом, суд не вправе учитывать указанный способ также в качестве отягчающего наказание обстоятельства, предусмотренного пунктом "к" части 1 статьи 63 УК РФ;</p>
            <p class="block_3">в случае совершения сотрудником органа внутренних дел преступления с использованием своего служебного положения (например, преступления, предусмотренного частью 3 статьи 160, статьей 286 УК РФ) суд не вправе учитывать данные, характеризующие субъект преступления, в качестве отягчающего наказание обстоятельства, предусмотренного пунктом "о" части 1 статьи 63 УК РФ.</p>
            <p class="block_3">По смыслу статьи 63 УК РФ, обстоятельства, отягчающие наказание, учитываются при назначении наказания за преступление с любой формой вины, если иное прямо не предусмотрено законом (например, пунктом "о" части 1 статьи 63 УК РФ) или не вытекает из него (например, из пункта "н" части 1 статьи 63 УК РФ).</p>
            <p class="block_4">Порядок исчисления сроков наказания при применении статей</p>
            <p class="block_4">62, 64, 65, 66 и 68 УК РФ</p>
            <p class="block_3">33. Под наиболее строгим видом наказания в статьях 62, 65, 66, 68 УК РФ следует понимать тот из перечисленных в санкции статьи вид наказания, который является наиболее строгим из применяемых в соответствии с действующим уголовным законом видов наказаний с учетом положений статьи 44 УК РФ (например, в этих целях арест не учитывается). При этом не имеет значения, может ли данный вид наказания быть назначен виновному с учетом положений Общей части УК РФ (например, части 1 статьи 56 УК РФ) или Особенной части УК РФ (например, пункта 2 примечаний к статье 134 УК РФ).</p>
            <p class="block_3">Правила статей 62, 65, 66 и 68 УК РФ не распространяются на случаи назначения менее строгого вида наказания, указанного в санкции статьи Особенной части УК РФ, за совершенное преступление и дополнительного наказания.</p>
            <p class="block_3">34. При применении статей 62, 65 и 68 УК РФ в случае совершения неоконченного преступления указанная в этих нормах часть наказания исчисляется от срока или размера наказания, которые могут быть назначены по правилам статьи 66 УК РФ.</p>
            <p class="block_3">Если в результате применения статей 66 и (или) 62 УК РФ либо статей 66 и 65 УК РФ срок или размер наказания, который может быть назначен осужденному, окажется менее строгим, чем низший предел наиболее строгого вида наказания, предусмотренного санкцией соответствующей статьи Особенной части УК РФ, то наказание назначается ниже низшего предела без ссылки на статью 64 УК РФ. В таких случаях верхний предел назначаемого наказания не должен превышать срок или размер наказания, который может быть назначен с учетом положений указанных статей (например, за преступление, предусмотренное частью 3 статьи 162 УК РФ, с учетом положений части 2 статьи 62 УК РФ лишение свободы назначается на срок не более 6 лет, хотя низший предел санкции составляет 7 лет).</p>
            <p class="block_3">Таким же образом разрешается вопрос назначения наказания в случае совпадения верхнего предела наказания, которое может быть назначено осужденному в результате применения указанных норм, с низшим пределом наиболее строгого вида наказания, предусмотренного санкцией соответствующей статьи Особенной части УК РФ.</p>
            <p class="block_3">Судам следует иметь в виду, что в таких случаях верхний предел наказания, которое может быть назначено осужденному в результате применения указанных норм, является для него максимальным размером, с учетом которого необходимо применять и другие правила назначения наказания, установленные законом.</p>
            <p class="block_3">35. Суд обязан мотивировать применение норм, ограничивающих срок или размер наказания определенной частью наиболее строгого вида наказания, в описательно-мотивировочной части приговора. В резолютивной части приговора ссылка на статьи 62, 65, 66 и 68 УК РФ не требуется даже в том случае, когда в результате применения положений статей 62, 65, 66 УК РФ назначенное наказание окажется ниже низшего предела наиболее строгого вида наказания, предусмотренного санкцией статьи Особенной части УК РФ. В случае применения статьи 64 УК РФ ссылка на нее в резолютивной части приговора обязательна.</p>
            <p class="block_4">Статья 62 УК РФ</p>
            <p class="block_3">36. По смыслу закона, правила, изложенные в части 1 статьи 62 УК РФ, могут применяться судами при наличии хотя бы одного из перечисленных в пунктах "и" и (или) "к" части 1 статьи 61 УК РФ смягчающих обстоятельств, если при этом отсутствуют отягчающие наказание обстоятельства.</p>
            <p class="block_3">Если наряду с обстоятельствами, указанными в части 1 статьи 62 УК РФ, суд установит наличие других смягчающих обстоятельств, наказание должно назначаться с учетом всех смягчающих обстоятельств.</p>
            <p class="block_3">37. Правила части 3 статьи 62 УК РФ о неприменении положений части 1 этой статьи не распространяются на лиц, которым в силу закона не назначается пожизненное лишение свободы, в том числе на лиц, совершивших неоконченное преступление. При этом следует иметь в виду, что часть 3 статьи 62 УК РФ не содержит запрета на применение наказания в виде пожизненного лишения свободы при наличии условий, предусмотренных частью 1 этой статьи.</p>
            <p class="block_3">38. Положения частей 2 и 4 статьи 62 УК РФ применяются, если лицом выполнены условия досудебного соглашения о сотрудничестве (статья 63.1 УК РФ).</p>
            <p class="block_3">При назначении наказания лицу, с которым заключено досудебное соглашение о сотрудничестве, в соответствии с частью 2 или частью 4 статьи 62 УК РФ положения части 1 указанной статьи о сроке и размере наказания учету не подлежат.</p>
            <p class="block_3">Правила назначения наказания, установленные частью 4 статьи 62 УК РФ, применяются и при наличии обстоятельств, отягчающих наказание.</p>
            <p class="block_3">В случае установления исключительных обстоятельств, а равно при активном содействии подсудимого раскрытию группового преступления суд вправе применить положения статьи 64 УК РФ, в том числе при наличии отягчающих обстоятельств.</p>
            <p class="block_3">По правилам части 2 или части 4 статьи 62 УК РФ следует назначать наказание и в тех случаях, когда суд по основаниям, не связанным с выполнением обвиняемым обязательств досудебного соглашения о сотрудничестве, рассмотрел дело в общем порядке.</p>
            <p class="block_3">39. При установлении обстоятельств, предусмотренных как частью 5, так и частью 1 статьи 62 УК РФ, применяется совокупность правил смягчения наказания: вначале применяются положения части 5 статьи 62 УК РФ, затем - части 1 статьи 62 УК РФ. Таким образом, максимально возможное наказание в этих случаях не должно превышать: две трети от двух третьих - при рассмотрении уголовного дела в порядке, предусмотренном главой 40 УПК РФ, и две трети от одной второй - в случае, указанном в статье 226.9 УПК РФ.</p>
            <p class="block_4">Статья 64 УК РФ</p>
            <p class="block_3">40. Суд вправе признать исключительными обстоятельствами, дающими основание для применения статьи 64 УК РФ, как отдельные смягчающие обстоятельства, так и их совокупность, указав в приговоре основания принятого решения. Статья 64 УК РФ может применяться и при наличии обстоятельств, отягчающих наказание.</p>
            <p class="block_3">В случае назначения на основании статьи 64 УК РФ основного наказания ниже низшего предела, предусмотренного санкцией статьи Особенной части УК РФ, срок или размер наказания не может быть ниже низшего предела, установленного для данного вида наказания в Общей части УК РФ.</p>
            <p class="block_3">По смыслу части 1 статьи 64 УК РФ, назначению более мягкого вида основного наказания, чем предусмотрено статьей Особенной части УК РФ, не препятствует наличие в санкции статьи альтернативных видов наказаний.</p>
            <p class="block_3">При назначении более мягкого вида основного наказания по правилам статьи 64 УК РФ следует учитывать ограничения, установленные в Общей части УК РФ для назначения того или иного вида наказания.</p>
            <p class="block_3">41. При назначении наказания с применением статьи 64 УК РФ в резолютивной части приговора должна быть сделана ссылка на указанную норму при назначении наказания за каждое конкретное преступление. Указания на эту норму при назначении окончательного наказания по совокупности преступлений не требуется.</p>
            <p class="block_4">Статья 65 УК РФ</p>
            <p class="block_3">42. По смыслу закона, с учетом особенностей признания подсудимого заслуживающим снисхождения наличие вердикта присяжных заседателей о снисхождении, а также смягчающих наказание обстоятельств, предусмотренных пунктами "и" и (или) "к" части 1 статьи 61 УК РФ, и отсутствие обстоятельств, отягчающих наказание, не влечет за собой последовательного применения положений части 1 статьи 62 и части 1 статьи 65 УК РФ. Применению подлежит часть 1 статьи 65 УК РФ. Вместе с тем в таких случаях судья вправе применить правила назначения наказания, предусмотренные не только статьей 65 УК РФ, но и (с учетом обстоятельств, указанных в пунктах "и" и "к" части 1 статьи 61 УК РФ) статьей 64 УК РФ (часть 2 статьи 349 УПК РФ).</p>
            <p class="block_3">По смыслу части 2 статьи 349 УПК РФ, при наличии оснований, предусмотренных статьей 64 УК РФ, судья вправе назначить более мягкое наказание как лицу, которое признано заслуживающим снисхождения, так и лицу, которое не признано заслуживающим снисхождения.</p>
            <p class="block_4">Статья 66 УК РФ</p>
            <p class="block_3">43. При назначении наказания за неоконченное преступление надлежит соблюдать сроки и размеры наказания, которые, в соответствии с частями 2 и 3 статьи 66 УК РФ, исчисляются от максимального срока или размера наиболее строгого вида наказания, предусмотренного за оконченное преступление. При этом пожизненное лишение свободы за приготовление к преступлению и покушение на преступление не назначается (часть 4 статьи 66 УК РФ).</p>
            <p class="block_4">Статья 68 УК РФ</p>
            <p class="block_3">44. При решении вопроса о наличии рецидива преступлений судам следует иметь в виду, что основанием для признания рецидива преступлений является судимость только за умышленное преступление. При этом судимости, указанные в части 4 статьи 18 УК РФ, не учитываются.</p>
            <p class="block_3">Наличие у лица, совершившего тяжкое преступление, судимости за преступление небольшой тяжести не образует рецидива преступлений. Однако совершение умышленного преступления небольшой тяжести лицом, имеющим судимость за преступление средней тяжести, тяжкое или особо тяжкое преступление, образует рецидив преступлений.</p>
            <p class="block_3">Снятая, в том числе в порядке, установленном частью 1 статьи 74 УК РФ, или погашенная до совершения нового преступления судимость не образует рецидива преступлений. Однако наличие судимости (за исключением судимостей, перечисленных в части 4 статьи 18 УК РФ), снятой или погашенной после совершения нового преступления в порядке, установленном статьей 86 УК РФ, образует рецидив преступлений, поскольку наличие рецидива преступлений устанавливается на момент совершения преступления.</p>
            <p class="block_3">Обратить внимание судов на то, что в случае назначения наказания по правилам части 5 статьи 69 УК РФ преступление, совершенное до постановления предыдущего приговора, не образует рецидива преступлений. Отсутствует рецидив преступлений и в тех случаях, когда новое преступление совершено после постановления предыдущего приговора, но до вступления его в законную силу.</p>
            <p class="block_3">45. Отмена условного осуждения при постановлении приговора, по которому наказание назначается с применением статьи 70 УК РФ, не образует рецидива преступлений.</p>
            <p class="block_3">Отмена условного осуждения образует рецидив преступлений только в том случае, когда решение об отмене условного осуждения и о направлении осужденного для отбывания наказания в места лишения свободы было принято до совершения им нового преступления. При этом не имеет значения, по каким основаниям прежде отменялось условное осуждение - в соответствии с частью 4 или частью 5 статьи 74 УК РФ при назначении наказания по совокупности приговоров или в случаях, предусмотренных частью 3 этой статьи, на основании постановления суда. Не имеет значения и то, было ли осужденным начато реальное отбывание лишения свободы.</p>
            <p class="block_3">Если по первому приговору лицо было осуждено за умышленное преступление (кроме преступления небольшой тяжести) к лишению свободы условно, при вынесении второго приговора за новое преступление суд, на основании части 5 статьи 74 УК РФ, отменил условное осуждение и назначил наказание в соответствии со статьей 70 УК РФ, то при постановлении третьего приговора за вновь совершенное преступление первая и вторая судимости учитываются при определении наличия рецидива преступлений.</p>
            <p class="block_3">В случае совершения лицом умышленного тяжкого или особо тяжкого преступления в течение оставшейся неотбытой части наказания в силу пункта "в" части 7 статьи 79 УК РФ специального решения об отмене условно-досрочного освобождения не требуется, а наказание подсудимому назначается по правилам, предусмотренным статьей 70 УК РФ. В остальных случаях суд обязан мотивировать необходимость отмены (или сохранения) условно-досрочного освобождения.</p>
            <p class="block_3">46. В описательно-мотивировочной части приговора необходимо указывать вид рецидива преступлений. При признании рецидива преступлений не имеет значения, были преступления оконченными или неоконченными, а также каков характер участия лица в этих преступлениях (исполнитель, организатор, подстрекатель или пособник). Кроме того, для признания рецидива не имеет значения наличие или отсутствие в обвинительном заключении, обвинительном акте или обвинительном постановлении указания на рецидив преступлений.</p>
            <p class="block_3">По смыслу статьи 18 УК РФ, совершение особо тяжкого преступления лицом, имеющим судимость за тяжкое преступление, за которое он отбывал лишение свободы, образует опасный рецидив преступлений (часть 2 статьи 18 УК РФ).</p>
            <p class="block_3">При признании рецидива преступлений опасным или особо опасным (части 2 и 3 статьи 18 УК РФ) осуждение к реальному лишению свободы включает в себя и условное осуждение к лишению свободы, если условное осуждение отменялось и лицо направлялось для отбывания наказания в места лишения свободы до совершения им нового преступления.</p>
            <p class="block_3">47. Согласно части 2 статьи 68 УК РФ при рецидиве преступлений лицу, совершившему преступление, за которое предусмотрены альтернативные виды наказаний, назначается только наиболее строгий вид наказания, предусмотренный соответствующей статьей Особенной части УК РФ. Назначение менее строгого как предусмотренного, так и не предусмотренного санкцией соответствующей статьи Особенной части УК РФ вида наказания допускается лишь при наличии исключительных обстоятельств, указанных в статье 64 УК РФ (часть 3 статьи 68 УК РФ).</p>
            <p class="block_3">48. Исходя из положений частей 2 и 3 статьи 68 УК РФ наказание при рецидиве преступлений не может быть ниже низшего предела санкции соответствующей статьи, даже если одна третья часть максимального срока наиболее строгого вида наказания, предусмотренного за совершенное оконченное преступление, составляет менее минимального размера наиболее строгого вида наказания, предусмотренного за конкретное преступление (например, за преступление, предусмотренное частью 3 статьи 161 УК РФ, с учетом положений части 2 статьи 68 УК РФ не может быть назначено менее 6 лет лишения свободы - низшего предела этого вида наказания за данное преступление, хотя одна треть от максимального наказания за это преступление составляет 4 года).</p>
            <p class="block_3">Судам следует иметь в виду, что при любом виде рецидива преступлений срок наказания за неоконченное преступление может быть ниже низшего предела санкции соответствующей статьи Особенной части УК РФ. При этом ссылка на статью 64 УК РФ не требуется.</p>
            <p class="block_3">В том случае, когда одна третья часть превышает минимальный размер наиболее строгого вида наказания, предусмотренного за совершенное преступление, суд, в соответствии с частью 3 статьи 68 УК РФ, может назначить наказание на срок менее одной третьей части, но не ниже низшего предела санкции соответствующей статьи Особенной части УК РФ, если установит смягчающие обстоятельства, предусмотренные статьей 61 УК РФ. При этом в качестве смягчающих могут быть учтены и обстоятельства, признанные таковыми в соответствии с частью 2 статьи 61 УК РФ.</p>
            <p class="block_3">Если размер одной третьей части максимального срока наиболее строгого вида наказания совпадает с низшим пределом санкции соответствующей статьи и судом установлены смягчающие обстоятельства, предусмотренные статьей 61 УК РФ, а основания для применения статьи 64 УК РФ не установлены, суд не вправе назначить осужденному наказание ниже низшего предела санкции соответствующей статьи.</p>
            <p class="block_3">При назначении наказания с применением статьи 64 УК РФ в резолютивной части приговора следует ссылаться не на часть 3 статьи 68 УК РФ, а на статью 64 УК РФ.</p>
            <p class="block_3">49. В случае рассмотрения уголовного дела в особом порядке, предусмотренном главой 40 или 40.1 УПК РФ, при любом виде рецидива предусмотренная частями 2 и 3 статьи 68 УК РФ одна треть исчисляется:</p>
            <p class="block_3">за оконченное преступление - от максимального срока наиболее строгого вида наказания, предусмотренного за совершенное преступление санкцией соответствующей статьи;</p>
            <p class="block_3">за неоконченное преступление - от максимального срока наиболее строгого вида наказания, предусмотренного за совершенное преступление, который может быть назначен с учетом положений статьи 66 УК РФ.</p>
            <p class="block_4">Назначение наказания по совокупности преступлений</p>
            <p class="block_4">и по совокупности приговоров</p>
            <p class="block_3">50. Если лицо совершило несколько неоконченных преступлений, то за каждое из них назначается наказание в соответствии со статьей 66 УК РФ. Наказание по совокупности преступлений при этом не может превышать более чем наполовину максимальный срок или размер наказания, которое может быть назначено по указанным правилам за наиболее тяжкое из совершенных неоконченных преступлений.</p>
            <p class="block_3">51. В случае совершения лицом нескольких преступлений, за каждое из которых назначено наказание с применением положений статьи 62 УК РФ или статьи 65 УК РФ, срок или размер наказания по совокупности преступлений не могут превышать более чем наполовину максимальный срок или размер наказания, которое может быть назначено за наиболее тяжкое из совершенных преступлений без учета правил, установленных указанными статьями. При этом суды в целях обеспечения принципа справедливости при назначении окончательного наказания должны учитывать, что за каждое из совершенных преступлений наказание назначено с учетом правил статьи 62 или 65 УК РФ.</p>
            <p class="block_3">52. Решая вопрос о назначении наказания в соответствии с частью 5 статьи 69 УК РФ лицу, совершившему другое преступление до вынесения приговора по первому делу, суд применяет общие правила назначения наказания по совокупности преступлений. При этом окончательное наказание, назначаемое путем частичного или полного сложения, должно быть строже наиболее строгого из наказаний, назначенных за входящие в совокупность преступления.</p>
            <p class="block_3">По смыслу закона, при назначении наказания по правилам части 5 статьи 69 УК РФ значение имеет не момент вступления предыдущего приговора в законную силу, а время его вынесения, поэтому правила части 5 статьи 69 УК РФ применяются и в том случае, когда на момент постановления приговора по рассматриваемому делу первый приговор не вступил в законную силу.</p>
            <p class="block_3">53. Если по делу будет установлено, что осужденный виновен еще и в других преступлениях, одни из которых совершены до, а другие - после вынесения первого приговора, то наказание по второму приговору назначается вначале по совокупности преступлений, совершенных до вынесения первого приговора, после этого - по правилам части 5 статьи 69 УК РФ, затем по совокупности преступлений, совершенных после вынесения первого приговора. Окончательное наказание назначается по правилам статьи 70 УК РФ путем частичного или полного присоединения к наказанию, назначенному по совокупности преступлений, совершенных после вынесения первого приговора, неотбытой части наказания, назначенного по правилам части 5 статьи 69 УК РФ.</p>
            <p class="block_3">В тех случаях, когда в отношении условно осужденного лица будет установлено, что оно виновно еще и в другом преступлении, совершенном до вынесения приговора по первому делу, правила части 5 статьи 69 УК РФ применены быть не могут, поскольку в статье 74 УК РФ дан исчерпывающий перечень обстоятельств, на основании которых возможна отмена условного осуждения. В таких случаях приговоры по первому и второму делам исполняются самостоятельно.</p>
            <p class="block_3">Если одни преступления совершены до, а другие - после вынесения первого приговора, в соответствии с которым осужденному назначено наказание с применением статьи 73 УК РФ, то суду вначале следует назначить наказание по совокупности преступлений, совершенных после вынесения первого приговора, при наличии оснований, предусмотренных статьей 74 УК РФ, отменить условное осуждение и назначить наказание по совокупности приговоров (статья 70 УК РФ), затем - по совокупности преступлений, совершенных до вынесения первого приговора, и окончательное наказание назначить по правилам части 5 статьи 69 УК РФ.</p>
            <p class="block_3">54. При осуждении лица за длящееся или продолжаемое преступление, которое началось до и продолжалось после вынесения приговора, по которому это лицо осуждено и не отбыло наказание, за совершение длящегося или продолжаемого преступления по второму приговору суд должен назначить наказание по правилам статьи 70 УК РФ.</p>
            <p class="block_3">Правила статьи 70 УК РФ применяются и тогда, когда лицо в период испытательного срока совершило новое преступление, за которое оно осуждено после истечения испытательного срока по первому приговору.</p>
            <p class="block_3">55. При решении вопроса о назначении наказания по совокупности приговоров следует выяснять, какая часть основного или дополнительного наказания реально не отбыта лицом по предыдущему приговору на момент постановления приговора, и указать это во вводной части приговора.</p>
            <p class="block_3">Неотбытым наказанием следует считать весь срок назначенного наказания по предыдущему приговору при условном осуждении; срок, на который осужденный был фактически условно-досрочно освобожден от дальнейшего отбывания наказания; назначенное наказание, которое отсрочено в порядке, предусмотренном статьями 82, 82.1 УК РФ.</p>
            <p class="block_3">В случае совершения нового преступления лицом, отбывающим наказание в виде лишения свободы, неотбытой частью наказания следует считать срок, оставшийся на момент избрания меры пресечения в виде содержания под стражей за вновь совершенное преступление. Если указанная мера пресечения не избиралась, неотбытой частью наказания является срок, оставшийся ко времени постановления последнего приговора.</p>
            <p class="block_3">56. В случае совершения лицом нового преступления после провозглашения приговора за предыдущее преступление судам следует исходить из того, что, поскольку вынесение приговора завершается его публичным провозглашением, правила назначения наказания по совокупности приговоров (статья 70 УК РФ) применяются и тогда, когда на момент совершения осужденным лицом нового преступления первый приговор не вступил в законную силу. При этом следует иметь в виду, что совершение нового преступления до вступления предыдущего приговора в законную силу не образует рецидива преступлений.</p>
            <p class="block_3">Неотбытое по предыдущему приговору либо назначенное по новому приговору дополнительное наказание присоединяется к основному наказанию, назначенному по совокупности приговоров.</p>
            <p class="block_3">57. Срок отбывания окончательного наказания в виде лишения свободы, назначенного по правилам части 5 статьи 69 и (или) статьи 70 УК РФ, исчисляется со дня постановления последнего приговора.</p>
            <p class="block_3">При этом производится зачет времени предварительного содержания под стражей по последнему делу в порядке меры пресечения или задержания, а также времени нахождения по этому делу под домашним арестом или пребывания в медицинской организации, оказывающей медицинскую помощь в стационарных условиях, или в медицинской организации, оказывающей психиатрическую помощь в стационарных условиях.</p>
            <p class="block_3">В срок наказания, назначенного по правилам части 5 статьи 69 УК РФ, должно быть, кроме того, зачтено наказание, отбытое полностью или частично по первому приговору.</p>
            <p class="block_3">В срок наказания, назначенного по правилам статьи 70 УК РФ, в случае отмены условного осуждения по предыдущему приговору должно быть, кроме того, зачтено время предварительного содержания под стражей по первому делу в порядке меры пресечения или задержания, а также время нахождения по первому делу под домашним арестом или пребывания в медицинской организации, оказывающей медицинскую помощь в стационарных условиях, или в медицинской организации, оказывающей психиатрическую помощь в стационарных условиях.</p>
            <p class="block_3">Если по первому приговору назначенное наказание было заменено более строгим видом наказания, то в окончательное наказание должно быть зачтено как отбытое первоначально назначенное наказание до его замены более строгим видом наказания, так и отбытое наказание более строгого вида.</p>
            <p class="block_3">58. Суды первой, апелляционной и кассационной инстанций вправе переквалифицировать преступное деяние с одной статьи на несколько других статей или частей статей уголовного закона, предусматривающих ответственность за менее тяжкие преступления, если этим не ухудшается положение осужденного и не нарушается его право на защиту. При этом наказание, назначенное по совокупности преступлений, не может быть более строгим, чем максимальное наказание, предусмотренное санкцией статьи УК РФ, по которой было квалифицировано деяние в обвинительном заключении, обвинительном акте, обвинительном постановлении.</p>
            <p class="block_3">Суд апелляционной или кассационной инстанции вместо примененных по приговору правил поглощения наказаний, назначенных по совокупности преступлений, вправе применить правила их сложения в случаях, когда этими судебными инстанциями смягчается наказание за одно или несколько преступлений. При этом наказание не должно превышать размера наказания, назначенного по приговору, с учетом изменений, внесенных в него последующими судебными инстанциями.</p>
            <p class="block_4">Назначение дополнительного наказания</p>
            <p class="block_3">59. В качестве дополнительного не может быть применено наказание, которое назначено лицу в качестве основного за то же преступление.</p>
            <p class="block_3">При назначении наказания по статьям уголовного закона, предусматривающим возможность применения дополнительных наказаний по усмотрению суда, в приговоре следует указать основания их применения с приведением соответствующих мотивов. Если суд придет к выводу об отсутствии оснований для назначения дополнительных наказаний, указав на это в описательно-мотивировочной части приговора, то в резолютивной его части не требуется указывать, что основное наказание назначается без того или иного вида дополнительного наказания (например, лишение свободы без штрафа, без ограничения свободы).</p>
            <p class="block_3">Если закон, по которому квалифицировано совершенное преступление, предусматривает обязательное назначение дополнительного наказания (например, лишения права занимать определенные должности или заниматься определенной деятельностью к штрафу по части 1 статьи 290 УК РФ, либо штрафа к лишению свободы по части 2 статьи 290 УК РФ, либо ограничения свободы к лишению свободы по части 3 статьи 131 УК РФ), то его неприменение судом допускается либо при наличии условий, предусмотренных статьей 64 УК РФ, либо в силу положений Общей части УК РФ о неприменении соответствующего вида наказания. Принятое решение должно быть мотивировано в описательно-мотивировочной части приговора.</p>
            <p class="block_3">60. Если подсудимый признается виновным в совершении нескольких преступлений, то в соответствии со статьей 69 УК РФ и с пунктом 4 части 1 статьи 308 УПК РФ в резолютивной части приговора надлежит указывать вид и размер назначенных основного и дополнительного наказаний отдельно за каждое преступление и окончательное наказание по совокупности преступлений.</p>
            <p class="block_3">Дополнительное наказание не может быть определено по совокупности преступлений, если оно не назначено ни за одно из преступлений, входящих в совокупность.</p>
            <p class="block_3">В случае, когда за два или более преступления наряду с основным наказанием назначается один и тот же вид дополнительного наказания, окончательный его срок или размер при частичном или полном сложении наказаний не может превышать максимальный срок или размер, предусмотренный для данного вида наказания Общей частью УК РФ. Если же за различные преступления, входящие в совокупность, судом назначены разные виды дополнительного наказания, то они с приведением соответствующих размеров и сроков должны быть указаны в приговоре и при назначении окончательного наказания по совокупности преступлений.</p>
            <p class="block_3">Срок или размер дополнительного наказания, назначенного по совокупности приговоров, также не может превышать максимальный срок или размер, предусмотренный соответствующей статьей Общей части УК РФ.</p>
            <p class="block_4">Условное осуждение</p>
            <p class="block_3">61. Для достижения целей исправления условно осужденного и предупреждения совершения им новых преступлений в течение испытательного срока суд вправе возложить на осужденного исполнение как обязанностей, предусмотренных частью 5 статьи 73 УК РФ, так и других обязанностей, например обязанности не покидать место своего жительства в ночное время, если это не связано с выполнением трудовых обязанностей, обязанности в установленный судом срок загладить вред, причиненный преступлением.</p>
            <p class="block_3">При этом судам следует иметь в виду, что в силу части 4 статьи 188 УИК РФ все условно осужденные обязаны отчитываться перед уголовно-исполнительными инспекциями или командованием воинских частей о своем поведении, исполнять возложенные на них обязанности, являться по вызову в уголовно-исполнительную инспекцию, поэтому в приговоре дополнительно возлагать на осужденных указанные обязанности не требуется.</p>
            <p class="block_3">При постановлении приговора об условном назначении наказания в виде лишения свободы вид исправительного учреждения не указывается.</p>
            <p class="block_3">62. Если суд придет к выводу о возможности постановления приговора об условном осуждении лица, совершившего два или более преступления, такое решение принимается не за каждое преступление, а при окончательном назначении наказания по совокупности преступлений.</p>
            <p class="block_3">С учетом того, что в соответствии с частью 4 статьи 73 УК РФ при условном осуждении могут быть назначены дополнительные наказания, условным может быть признано лишь основное наказание.</p>
            <p class="block_3">62.1. По смыслу части 3.1 статьи 73 УК РФ в случае назначения наказания в виде содержания в дисциплинарной воинской части условно испытательный срок устанавливается в пределах оставшегося срока военной службы на день провозглашения приговора и может быть менее шести месяцев.</p>
            <p class="block_3">63. В соответствии с частью 7 статьи 73 УК РФ в течение испытательного срока суд по представлению органа, осуществляющего контроль за поведением условно осужденного, может отменить полностью или частично либо дополнить ранее установленные для условно осужденного обязанности.</p>
            <p class="block_3">При отмене условного осуждения и назначении наказания по совокупности приговоров вид исправительного учреждения назначается с учетом тяжести как преступления, совершенного в период испытательного срока, так и преступления, за совершение которого было постановлено назначить наказание условно, а также иных обстоятельств, влияющих на назначение вида исправительного учреждения (например, наличия рецидива преступлений).</p>
            <p class="block_3">По смыслу части 1 статьи 74 УК РФ, условное осуждение может быть отменено со снятием с осужденного судимости по истечении не менее половины установленного испытательного срока и лишь после отбытия им дополнительного наказания.</p>
            <p class="block_3">64. При условном осуждении по второму приговору за преступление, совершенное до провозглашения первого приговора, по которому также было применено условное осуждение, суд в резолютивной части второго приговора должен указать на самостоятельность исполнения указанных приговоров, поскольку испытательный срок, устанавливаемый при условном осуждении, не является наказанием и не может быть ни поглощен более длительным испытательным сроком, ни частично или полностью сложен.</p>
            <p class="block_3">65. При изменении в апелляционном порядке приговора, по которому применена статья 73 УК РФ и назначенное наказание постановлено считать условным, нельзя назначить реальное наказание, даже если вид такого наказания является более мягким, за исключением случаев рассмотрения уголовного дела по представлению прокурора либо жалобе потерпевшего, содержащим соответствующие доводы.</p>
            <p class="block_3">66. При решении вопроса о возможности отмены или сохранения условного осуждения в отношении лица, совершившего в период испытательного срока новое преступление по неосторожности либо умышленное преступление небольшой тяжести или средней тяжести, необходимо учитывать характер и степень общественной опасности первого и второго преступлений, а также данные о личности осужденного и его поведении во время испытательного срока. При необходимости для выяснения таких данных в судебное заседание может быть вызван представитель органа, осуществляющего контроль за поведением условно осужденного. Установив, что условно осужденный в период испытательного срока вел себя отрицательно, не выполнял возложенных на него обязанностей, нарушал общественный порядок и т.п., суд, в силу части 4 статьи 74 УК РФ, может отменить условное осуждение с мотивировкой принятого решения и назначить наказание по совокупности приговоров.</p>
            <p class="block_3">Вывод о возможности сохранения условного осуждения излагается в описательно-мотивировочной части приговора, а в его резолютивной части указывается на то, что приговор в части условного осуждения по первому приговору исполняется самостоятельно. В случае сохранения условного осуждения по первому приговору назначение реального наказания по второму приговору не исключается.</p>
            <p class="block_3">При совершении в течение испытательного срока нового умышленного тяжкого или особо тяжкого преступления условное осуждение должно быть отменено (часть 5 статьи 74 УК РФ). При принятии судом решения об отмене условного осуждения в соответствии с частями 4 или 5 статьи 74 УК РФ наказание назначается по правилам, предусмотренным статьей 70 УК РФ, и в любом случае должно быть реальным.</p>
            <p class="block_4">Заключительные положения</p>
            <p class="block_3">67. С принятием настоящего постановления признать утратившими силу:</p>
            <p class="block_3">постановление Пленума Верховного Суда Российской Федерации от 11 января 2007 года N 2 "О практике назначения судами Российской Федерации уголовного наказания" (в редакции постановлений Пленума Верховного Суда Российской Федерации от 3 апреля 2008 года N 5, от 29 октября 2009 года N 21, от 2 апреля 2013 года N 6, от 3 декабря 2013 года N 33);</p>
            <p class="block_3">постановление Пленума Верховного Суда Российской Федерации от 3 апреля 2008 года N 5 "О внесении изменения в постановление Пленума Верховного Суда Российской Федерации от 11 января 2007 года N 2 "О практике назначения судами Российской Федерации уголовного наказания";</p>
            <p class="block_3">постановление Пленума Верховного Суда Российской Федерации от 29 октября 2009 года N 20 "О некоторых вопросах судебной практики назначения и исполнения уголовного наказания" (в редакции постановления Пленума Верховного Суда Российской Федерации от 23 декабря 2010 года N 31);</p>
            <p class="block_3">постановление Пленума Верховного Суда Российской Федерации от 29 октября 2009 года N 21 "О внесении изменений и дополнений в постановление Пленума Верховного Суда Российской Федерации от 11 января 2007 года N 2 "О практике назначения судами Российской Федерации уголовного наказания";</p>
            <p class="block_3">пункт 12 постановления Пленума Верховного Суда Российской Федерации от 23 декабря 2010 года N 31 "Об изменении и дополнении некоторых постановлений Пленума Верховного Суда Российской Федерации по уголовным делам";</p>
            <p class="block_3">пункт 1 постановления Пленума Верховного Суда Российской Федерации от 2 апреля 2013 года N 6 "О внесении изменений в некоторые постановления Пленума Верховного Суда Российской Федерации";</p>
            <p class="block_3">пункт 1 постановления Пленума Верховного Суда Российской Федерации от 3 декабря 2013 года N 33 "О внесении изменений в постановления Пленума Верховного Суда Российской Федерации от 11 января 2007 года N 2 "О практике назначения судами Российской Федерации уголовного наказания" и от 9 июля 2013 года N 24 "О судебной практике по делам о взяточничестве и об иных коррупционных преступлениях".</p>
            <p class="block_5">&nbsp;</p>
            <p class="block_6">Председатель<br class="calibre2" />Верховного Суда<br class="calibre2" />Российской Федерации<br class="calibre2" />В.М. Лебедев</p>
            <p class="block_7">Секретарь Пленума,<br class="calibre3" />судья Верховного Суда<br class="calibre3" />Российской Федерации<br class="calibre3" />В.В. Момотов</p>
            <p class="block_5">&nbsp;</p>
            <p class="block_8">&nbsp;</p>

        </div>

    </div>
</template>

<script>
export default {
    name: "apunishment",
    data (){
        return{
            page:{
                title:'Пленумы | О наказании',
                description: 'Static description',
                keywords: 'keywords',
                content: ""
            }
        }
    },
    created: function () {
        this.axios.get(`${encodeURI(window.hostname)}/api/getPage?pageName=${encodeURI("apunishment")}`).then(
            response => {
                this.page = response.data;
            }
        );
        this.page.title = "Пленумы | О наказании";
    }
}
</script>

<style scoped>
    @page { margin: 1in }
    .block_ {
        display: block;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 8pt;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-align: center;
    }
    .block_1 {
        display: block;
        font-size: 1.125em;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 8pt;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-align: center;
        text-indent: 0
    }
    .block_2 {
        display: block;
        line-height: 1.2;
        margin-bottom: 8pt;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-align: center;
        text-indent: 35.5pt
    }
    .block_3 {
        display: block;
        line-height: 1.2;
        margin-bottom: 8pt;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-indent: 35.5pt
    }
    .block_4 {
        display: block;
        font-size: 1.125em;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 8pt;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-align: center;
        text-indent: 35.5pt
    }
    .block_5 {
        display: block;
        font-family: serif;
        font-size: 0.75em;
        line-height: 1.08;
        margin-bottom: 8pt;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-align: right;
        text-indent: 35.5pt
    }
    .block_6 {
        display: block;
        line-height: 1.2;
        margin-bottom: 8pt;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-align: right;
        text-indent: 35.5pt
    }
    .block_7 {
        display: block;
        margin-bottom: 8pt;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-align: right;
        text-indent: 35.5pt
    }
    .block_8 {
        display: block;
        font-family: serif;
        font-size: 0.75em;
        line-height: 1.08;
        margin-bottom: 8pt;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-indent: 0
    }
    .calibre {
        color: black;
        display: block;
        font-family: "Times New Roman", serif;
        font-size: 1em;
        margin-bottom: 0;
        margin-left: 5pt;
        margin-right: 5pt;
        margin-top: 0;
        padding-left: 0;
        padding-right: 0
    }
    .calibre1 {
        font-weight: bold;
        line-height: 1.2
    }
    .calibre2 {
        display: block;
        line-height: 1.2
    }
    .calibre3 {
        display: block
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        .mycontainer {
            padding: 15px;
        }

    }

</style>
