<template>
    <b-card class="col-lg-12" :border-variant="isFinished ? 'success' : 'secondary'">


        <div v-if="IsUsePunishment(articleName.text)" class="row justify-content-center">
            <b-button v-if="articleName.info !== ''"
                      @click="showMsgBoxInfo(articleName.text, articleName.info)"
                      style="width: 24px;height: 24px; border-radius: 12px; margin: 2px; padding: 0px; font-size: 15px;"
                      class="justify-content-lg-end"
                      variant="danger">i</b-button>
            <div v-else></div>
            <b-card-text>{{articleName.text}}, наказание: </b-card-text>
        </div>
        <div v-else></div>

        <div v-if="IsUsePunishment(fine.text)" class="row justify-content-center">
            <b-button v-if="fine.info !== ''"
                      @click="toast(fine.text, fine.info)"
                      style="width: 24px;height: 24px; border-radius: 12px; margin: 2px; padding: 0px; font-size: 15px;"
                      class="justify-content-lg-end"
                      variant="danger">i</b-button>
            <div v-else></div>
            <b-card-text class="text-muted">Штраф: {{fine.text}} </b-card-text>
        </div>
        <div v-else></div>

        <div v-if="IsUsePunishment(disqualification.text)" class="row justify-content-center">
            <b-button v-if="disqualification.info !== ''"
                      @click="toast(disqualification.text, disqualification.info)"
                      style="width: 24px;height: 24px; border-radius: 12px; margin: 2px; padding: 0px; font-size: 15px;"
                      class="justify-content-lg-end"
                      variant="danger">i</b-button>
            <div v-else></div>
            <b-card-text class="text-muted">Лишение права занимать определенные должности или заниматься определенной деятельностью: {{disqualification.text}} </b-card-text>
        </div>
        <div v-else></div>

        <div v-if="IsUsePunishment(compulsoryWork.text)" class="row justify-content-center">
            <b-button v-if="compulsoryWork.info !== ''"
                      @click="showMsgBoxInfo('Обязательные работы', compulsoryWork.info)"
                      style="width: 24px;height: 24px; border-radius: 12px; margin: 2px 10px 2px 2px; padding: 0px; font-size: 15px;"
                      class="justify-content-lg-end"
                      variant="danger">i</b-button>
            <div v-else></div>
            <b-card-text class="text-muted">Обязательные работы: {{compulsoryWork.text}} </b-card-text>
        </div>
        <div v-else></div>

        <div v-if="IsUsePunishment(correctionalWork.text)" class="row justify-content-center">
            <b-button v-if="correctionalWork.info !== ''"
                      @click="showMsgBoxInfo('Исправительные работы', correctionalWork.info)"
                      style="width: 24px;height: 24px; border-radius: 12px; margin: 2px; padding: 0px; font-size: 15px;"
                      class="justify-content-lg-end"
                      variant="danger">i</b-button>
            <div v-else></div>
            <b-card-text class="text-muted">Исправительные работы: {{correctionalWork.text}} </b-card-text>
        </div>
        <div v-else></div>

        <div v-if="IsUsePunishment(militaryRestriction.text)" class="row justify-content-center">
            <b-button v-if="militaryRestriction.info !== ''"
                      @click="showMsgBoxInfo('Ограничение по военной службе', militaryRestriction.info)"
                      style="width: 24px;height: 24px; border-radius: 12px; margin: 2px; padding: 0px; font-size: 15px;"
                      class="justify-content-lg-end"
                      variant="danger">i</b-button>
            <div v-else></div>
            <b-card-text class="text-muted">Ограничение по военной службе: {{militaryRestriction.text}} </b-card-text>
        </div>
        <div v-else></div>

        <div v-if="IsUsePunishment(restrictionFreedom.text)" class="row justify-content-center">
            <b-button v-if="restrictionFreedom.info !== ''"
                      @click="showMsgBoxInfo('Ограничение Свободы', restrictionFreedom.info)"
                      style="width: 24px;height: 24px; border-radius: 12px; margin: 2px; padding: 0px; font-size: 15px;"
                      class="justify-content-lg-end"
                      variant="danger">i</b-button>
            <div v-else></div>
            <b-card-text class="text-muted">Ограничение Свободы: {{restrictionFreedom.text}} </b-card-text>
        </div>
        <div v-else></div>

        <div v-if="IsUsePunishment(imposingWork.text)" class="row justify-content-center">
            <b-button v-if="imposingWork.info !== ''"
                      @click="showMsgBoxInfo(imposingWork.text, imposingWork.info)"
                      style="width: 24px;height: 24px; border-radius: 12px; margin: 2px; padding: 0px; font-size: 15px;"
                      class="justify-content-lg-end"
                      variant="danger">i</b-button>
            <div v-else></div>
            <b-card-text class="text-muted">Принудительные работы: {{imposingWork.text}} </b-card-text>
        </div>
        <div v-else></div>

        <div v-if="IsUsePunishment(disciplinaryBattalion.text)" class="row justify-content-center">
            <b-button v-if="disciplinaryBattalion.info !== ''"
                      @click="showMsgBoxInfo('Содержание в дисциплинарной воинской части', disciplinaryBattalion.info)"
                      style="width: 24px;height: 24px; border-radius: 12px; margin: 2px; padding: 0px; font-size: 15px;"
                      class="justify-content-lg-end"
                      variant="danger">i</b-button>
            <div v-else></div>
            <b-card-text class="text-muted">Содержание в дисциплинарной воинской части: {{disciplinaryBattalion.text}} </b-card-text>
        </div>
        <div v-else></div>

        <div v-if="IsUsePunishment(deprivationLiberty.text)" class="row justify-content-center">
            <b-button v-if="deprivationLiberty.info !== ''"
                      @click="showMsgBoxInfo('Лишение Свободы', deprivationLiberty.info)"
                      style="width: 24px;height: 24px; border-radius: 12px; margin: 2px; padding: 0px; font-size: 15px;"
                      class="justify-content-lg-end"
                      variant="danger">i</b-button>
            <div v-else></div>
            <b-card-text class="text-muted">Лишение Свободы: {{deprivationLiberty.text}} </b-card-text>
        </div>
        <div v-else></div>

        <div v-if="IsUsePunishment(pzhd.text)" class="row justify-content-center">
            <b-button v-if="pzhd.info !== ''"
                      @click="showMsgBoxInfo(pzhd.text, pzhd.info)"
                      style="width: 24px;height: 24px; border-radius: 12px; margin: 2px; padding: 0px; font-size: 15px;"
                      class="justify-content-lg-end"
                      variant="danger">i</b-button>
            <div v-else></div>
            <b-card-text class="text-muted">Пожизненное лишение свободы: {{GetStringAskTo(pzhd.text)}} </b-card-text>
        </div>
        <div v-else></div>

    </b-card>
</template>

<script>
    export default {
        name: "CurrentPunishments",
        props: {
            articleName: {
                text: String,
                info: String,
            },
            fine: {
                text: String,
                info: String,
            },
            disqualification: {
                text: String,
                info: String,
            },
            compulsoryWork: {
                text: String,
                info: String,
            },
            correctionalWork: {
                text: String,
                info: String,
            },
            militaryRestriction: {
                text: String,
                info: String,
            },
            restrictionFreedom: {
                text: String,
                info: String,
            },
            imposingWork: {
                text: String,
                info: String,
            },
            disciplinaryBattalion: {
                text: String,
                info: String,
            },
            deprivationLiberty: {
                text: String,
                info: String,
            },
            pzhd: {
                text: String,
                info: String,
            },
            isFinished: Boolean,
        },

        methods:{
            /**
             * @return {boolean}
             */
            IsUsePunishment: function(message){
                return (message !== "") && (message !== "0");
            },
            /**
             * @return {string}
             */
            GetStringAskTo: function(message){
                if (message === ""){
                    return "Нет"
                } else {
                    return  "Да"
                }
            },

            toast(title, message, append = false) {
                this.$bvToast.toast(`${message}`, {
                    title: title,
                    toaster: `b-toaster-top-center`,
                    solid: true,
                    appendToast: append,
                    noAutoHide: false,
                    variant: `info`
                })
            },

            showMsgBoxInfo(title, message) {
                const h = this.$createElement;
                const messageVNode = h('div', { domProps: { innerHTML: message } });
                this.$bvModal.msgBoxOk(messageVNode, {
                    title: title,
                    size: 'lg',
                    buttonSize: 'sm',
                    footerClass: 'p-1',
                    okVariant: 'success',
                    hideHeaderClose: true,
                    centered: true,
                    scrollable: true,
                })
            },

        }
    }
</script>

<style scoped>

</style>
