<template>
    <div class="kodex">
        <vue-headful :title="page.title"
                     :description="page.description"
                     :keywords="page.keywords"/>
        <div class="mr-lg-1" v-if="isNotLoading">
            <div class="container">
                <div class="text-center m-sm-3 m-3">
                    <h3 align="center">Уголовный Кодекс Российской Федерации</h3>
                    <h6>(в редакции от {{this.dateEditionUkRf}})</h6>
                </div>
                <div>
                    <b-container class="bv-example-row justify-content-center" >
                        <b-row class="justify-content-center pb-3">
                            <AutocompleteArticle v-bind:hostname="this.hostname" v-bind:searchAll="true" @updateParent="showContentArticleFromSearch"/>
                        </b-row>
                    </b-container>
                </div>
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-1 variant="success">{{uk.parts[0].part_name}}</b-button>
                    </b-card-header>
                    <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <div v-for="(section, i) in uk.parts[0].sections" :key="i">
                                <p style="padding-left: 1em; font-size:16px; margin-bottom: 0; margin-top: 0.5rem">{{section.section_name}}</p>
                                <div v-for="(chapter, i) in section.chapters" :key="i">
                                    <p style="padding-left: 1em; font-size:16px; margin-bottom: 0; margin-top: 0.5rem">Глава {{chapter.chapter_num}}. {{chapter.chapter_name}}</p>
                                    <div style="padding-top: 0.1rem" v-for="(article, i) in chapter.articles" :key="i">
                                        <a href="javascript:void(0)" @click="showMsgBoxInfo(article.article_num)">{{article.article_name}}</a>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accordion-2 variant="success">{{uk.parts[1].part_name}}</b-button>
                    </b-card-header>
                    <b-collapse id="accordion-2" visible accordion="my-accordion-1" role="tabpanel">
                        <b-card-body>
                            <div v-for="(section, i) in uk.parts[1].sections" :key="i">
                                <p style="padding-left: 1em; font-size:16px; margin-bottom: 0; margin-top: 0.5rem">{{section.section_name}}</p>
                                <div v-for="(chapter, i) in section.chapters" :key="i">
                                    <p style="padding-left: 1em; font-size:16px; margin-bottom: 0; margin-top: 0.5rem">Глава {{chapter.chapter_num}}. {{chapter.chapter_name}}</p>
                                    <div style="padding-top: 0.1rem" v-for="(article, i) in chapter.articles" :key="i">
                                        <a href="javascript:void(0)" @click="showMsgBoxInfo(article.article_num)">{{article.article_name}}</a>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </div>
        <div v-else>
            <b-spinner label="Loading..." style="margin: 20% 50% " variant="primary"></b-spinner>
        </div>
    </div>
</template>

<script>
    import AutocompleteArticle from "../components/AutocompleteArticle.vue";
    export default {
        components:{
            AutocompleteArticle
        },
        name: "kodex",
        data (){
            return{
                hostname: window.hostname,
                page:{
                    title:'',
                    description: '',
                    keywords: '',
                    content: ""
                },
                isNotLoading: true,
                uk:{
                    parts: [{
                        part_name: "",
                        sections:[{
                            section_name: "",
                            chapters: [{
                                chapter_name: "",
                                chapter_num: "",
                                articles:[{
                                    article_name: "",
                                    article_num: "",
                                }]
                            }]
                        }]

                    }]

                },
                current_article:{
                    article_name: "",
                    article_content: "",
                },
                dateEditionUkRf: "0"
            }
        },
        created: function () {
            this.changeLoading();
            this.axios.post(`${encodeURI(window.hostname)}/api/getUkList`)
                .then(response => {
                    this.uk = response.data;
                    this.changeLoading();
                })
                .catch(e => {
                    this.errors.push(e.toString());
                });
            this.axios.get(`${encodeURI(window.hostname)}/api/getPage?pageName=${encodeURI("kodex")}`).then(
                response => {
                    this.page = response.data;
                }
            );
            this.page.title = "Юридический прогноз | Уголовный Кодекс РФ";
            this.page.description = "Уголовный Кодекс РФ";
            this.axios.get(`${encodeURI(window.hostname)}/api/getUkRfEdition`).then(response => (this.dateEditionUkRf = response.data));
        },
        methods:{
            showContentArticleFromSearch(data) {
                this.showMsgBoxInfo(data.value)
            },

            changeLoading(){
                this.isNotLoading = this.isNotLoading === false;
            },

            getArticleContent: function(artNum){
                this.current_article.article_name = "";
                this.current_article.article_content = "";
                this.axios.get(`${encodeURI(window.hostname)}/api/getUkArticleContent?input=${encodeURI(artNum)}`)
                .then(
                    response => {
                        this.current_article = response.data;
                    }
                );
            },
            sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            },
            async showMsgBoxInfo(artNum) {
                const h = this.$createElement;
                this.getArticleContent(artNum);
                await this.sleep(1200);
                const messageVNode = h('div', { domProps: { innerHTML:  this.current_article.article_content } });
                this.$bvModal.msgBoxOk(messageVNode, {
                    title: "Статья " + artNum + ". " + this.current_article.article_name,
                    size: 'lg',
                    buttonSize: 'sm',
                    footerClass: 'p-1',
                    okVariant: 'success',
                    hideHeaderClose: true,
                    centered: true,
                    scrollable: true,
                })
            },
        }
    }
</script>

<style scoped>

</style>
