<template>
    <div class="UserGuide">
        <vue-headful title="Юридический прогноз | Руководство пользователя"
                     description="Руководство пользователя Основной Сервис сайта 
                     «Юридический прогноз» - это алгоритм, разработанный для определения и 
                     расчета сроков или размеров применимых видов уголовного наказания в 
                     зависимости от параметров, которые задает Пользователь."
                     :keywords="page.keywords"/>
        <div v-html="page.content"></div>
    </div>
</template>

<script>
    export default {
        name: "UserGuide",
        data (){
            return{
                page:{
                    title:'',
                    description: '',
                    keywords: '',
                    content: ""
                }
            }
        },
        created: function () {
            this.axios.get(`${encodeURI(window.hostname)}/api/getPage?pageName=${encodeURI("UserGuide")}`).then(
                response => {
                    this.page = response.data;
                }
            );
        }
    }
</script>

<style scoped>
</style>
