<template>
    <div>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" integrity="sha256-h20CPZ0QyXlBuAw7A+KluUYx/3pK+c7lYEpqLTlxjYQ=" crossorigin="anonymous" />
        <div class="share-network-list" style="color: #ffffff">
            <ShareNetwork
                    v-for="network in networks"
                    :network="network.network"
                    :key="network.network"
                    :style="{backgroundColor: network.color}"
                    :url="sharing.url"
                    :title="sharing.title"
                    :description="sharing.description"
                    :quote="sharing.quote"
                    :hashtags="sharing.hashtags"
                    :twitterUser="sharing.twitterUser"
            >
                <i :class="network.icon"></i>
                <span>{{ network.name }}</span>
            </ShareNetwork>
        </div>
    </div>
</template>

<script>
    export default {
        name: "shareSocial",
        data () {
            return {
                sharing: {
                    url: 'https://aisud.ru',
                    title: 'Юридический прогноз!',
                    description: 'Сервис предназначен для расчета сроков или размеров наказания за преступления, предусмотренные Уголовным Кодексом РФ.',
                    quote: '',
                    hashtags: 'aisud,Юридиеский прогноз,УК РФ,Тест, решение судью'
                },
                networks: [
                    { network: 'odnoklassniki', name: 'Odnoklassniki', icon: 'fab fah fa-lg fa-odnoklassniki', color: '#ed812b' },
                    { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc' },
                    { network: 'viber', name: 'Viber', icon: 'fab fah fa-lg fa-viber', color: '#59267c' },
                    { network: 'vk', name: 'Vk', icon: 'fab fah fa-lg fa-vk', color: '#4a76a8' },
                    { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
                ]
            }
        }
    }
</script>

<style>
    body {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    }
    h1 {
        text-align: center;
        margin: 50px 0 80px;
    }
    .share-network-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 1000px;
        margin: auto;
    }
    a[class^="share-network-"] {
        flex: none;
        color: #FFFFFF;
        background-color: #333;
        border-radius: 3px;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        cursor: pointer;
        margin: 0 10px 10px 0;
    }
    a[class^="share-network-"] .fah {
        background-color: rgba(222, 222, 222, 0.15);
        padding: 10px;
        flex: 0 1 auto;
    }
    a[class^="share-network-"] span {
        padding: 0 10px;
        flex: 1 1 0%;
        font-weight: 500;
    }
</style>
