<template>
    <div class="contacts">
        <vue-headful :title="page.header.title"
                     :description="page.header.description"
                     :keywords="page.header.keywords"/>
        <div class="text-center m-sm-3 m-3">
            <h3 align="center">Раздел находится в разработке</h3>
        </div>
    </div>
</template>

<script>
    export default {
        name: "contacts",
        props: {
            hostname: String,
        },
        data (){
            return{
                page:{
                    header:{
                        title:'Юридический прогноз | Контакты',
                        description: 'Контакты',
                        keywords: 'keywords',
                    },
                    content:{

                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
