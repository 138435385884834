<template>
    <div class="container">
        <vue-headful :title="page.title"
                     :description="page.description"
                     :keywords="page.keywords"/>
        <div v-html="page.content">

        </div>
        <div class="text-center m-sm-5 m-5">
            <b-button href="/test" style="width: 70%;" variant="outline-success">Перейти к тесту</b-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "GeneralInformation",
        data (){
            return{
                page:{
                    title: '',
                    description: '',
                    keywords: '',
                    content: ""
                }
            }
        },
        created: function () {
            this.axios.get(`${encodeURI(window.hostname)}/api/getPage?pageName=${encodeURI("GeneralInformation")}`).then(
                response => {
                    this.page = response.data;
                }
            );
            this.page.title = "Юридический прогноз | Руководство пользователя"
            this.page. description = "Руководство пользователя Основной Сервис сайта «Юридический прогноз» - это алгоритм, разработанный для определения и расчета сроков или размеров применимых видов уголовного наказания в зависимости от параметров, которые задает Пользователь."
        }
    }
</script>

<style scoped>
    .GeneralInformation{
        filter: none;
    }
</style>
