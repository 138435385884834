<template>
    <div class="apre-trial-agreement">
        <vue-headful :title="page.title"
                     :description="page.description"
                     :keywords="page.keywords"/>
        <div class="calibre" id="calibre_link-0">
            <p class="block_">
                <font FACE="Times New Roman, serif"><font SIZE=3><font COLOR="#000000"><b>Постановление
                Пленума Верховного Суда Российской
                Федерации</b></font></font></font> </p>
                <p class="block_">
                <font FACE="Times New Roman, serif"><font SIZE=3><font COLOR="#000000"><font SIZE=4 STYLE="font-size: 13pt"><b>&quot;О
                практике применения судами особого
                порядка судебного разбирательства
                уголовных дел при заключении досудебного
                соглашения о сотрудничестве&quot;</b></font></font></font></font> </p>
                <p class="block_">
                <font FACE="Times New Roman, serif"><font SIZE=3><font COLOR="#000000">(от
                28 июня 2012 г. N 16 с изменениями от 29.06.2021)</font></font></font> </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>В связи с
                возникшими у судов вопросами по применению
                норм&nbsp;главы
                40.1&nbsp;УПК РФ, регламентирующих особый
                порядок судебного разбирательства
                уголовных дел при заключении досудебного
                соглашения о сотрудничестве, и в целях
                обеспечения единства судебной практики
                Пленум Верховного Суда Российской
                Федерации, руководствуясь&nbsp;статьей
                126&nbsp;Конституции Российской
                Федерации,&nbsp;статьями
                2&nbsp;и&nbsp;5&nbsp;Федерального
                конституционного закона от 5 февраля
                2014 года N 3-ФКЗ &quot;О Верховном Суде
                Российской Федерации&quot;, </font></font><font FACE="Times New Roman, serif"><font SIZE=3><b>постановляет</b></font></font><font FACE="Times New Roman, serif"><font SIZE=3>
                дать судам следующие разъяснения:</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>1. Обратить
                внимание судов на то, что нормы&nbsp;главы
                40.1&nbsp;УПК РФ регламентируют особенности
                не только судебного разбирательства,
                но и досудебного производства по
                уголовному делу. Строгое соблюдение
                всех требований этой&nbsp;главы&nbsp;является
                обязательным условием вынесения
                законного, обоснованного и справедливого
                приговора.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>При проведении
                судебного разбирательства и постановлении
                приговора в отношении подсудимого, с
                которым заключено досудебное соглашение
                о сотрудничестве, подлежат соблюдению
                общие для всех форм уголовного
                судопроизводства принципы осуществления
                правосудия. Недопустимо понижение
                уровня гарантий прав и законных интересов
                участников судебного разбирательства,
                установленных в том числе&nbsp;главой
                40.1&nbsp;УПК РФ.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>2. Разъяснить
                судам, что нормы&nbsp;главы
                40.1&nbsp;УПК РФ не содержат каких-либо
                ограничений относительно заключения
                досудебного соглашения о сотрудничестве
                одновременно с несколькими обвиняемыми
                (подозреваемыми), которые привлекаются
                к ответственности по одному уголовному
                делу. При этом заключение досудебного
                соглашения о сотрудничестве с одним
                или несколькими из них является правом
                прокурора.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>3. Судам
                следует иметь в виду, что по смыслу
                положений&nbsp;статей
                317.1,&nbsp;317.2&nbsp;317.3&nbsp;317.4&nbsp;УПК
                РФ досудебное соглашение о сотрудничестве
                может быть заключено с обвиняемым
                (подозреваемым) при расследовании
                уголовного дела в форме предварительного
                следствия, в том числе и в случаях,
                предусмотренных в&nbsp;части
                4 статьи 150&nbsp;УПК РФ.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>4. Исходя из
                того, что закон не предусматривает
                возможность принятия судебного решения
                в отношении несовершеннолетнего в
                особом порядке, положения&nbsp;главы
                40.1&nbsp;УПК РФ не применяются в отношении
                подозреваемых или обвиняемых, не
                достигших к моменту совершения
                преступления возраста восемнадцати
                лет.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>Если
                несовершеннолетний содействовал
                следствию в раскрытии и расследовании
                преступления, изобличении и уголовном
                преследовании других соучастников
                преступления, розыске имущества, добытого
                в результате преступления, судам следует
                учитывать эти обстоятельства при
                назначении несовершеннолетнему
                наказания.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>5. По смыслу&nbsp;части
                1 статьи 317.4&nbsp;УПК РФ, в целях применения
                судом предусмотренного&nbsp;статьей
                317.7&nbsp;УПК РФ особого порядка проведения
                судебного заседания и вынесения судебного
                решения уголовное дело в отношении
                обвиняемого, с которым заключено
                досудебное соглашение о сотрудничестве,
                подлежит выделению в отдельное
                производство.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>В случаях,
                когда такое уголовное дело не было
                выделено в отдельное производство и
                поступило в суд в отношении всех
                обвиняемых, судья назначает предварительное
                слушание для решения вопроса о возвращении
                уголовного дела прокурору в порядке,
                установленном&nbsp;статьей
                237&nbsp;УПК РФ.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>6. В силу
                конституционных принципов независимости,
                объективности и беспристрастности
                судей, а также по смыслу положений&nbsp;статьи
                63&nbsp;УПК РФ судья, принимавший участие
                в рассмотрении уголовного дела в
                отношении лица, с которым заключено
                досудебное соглашение о сотрудничестве,
                не может участвовать в рассмотрении
                уголовного дела в отношении соучастников
                преступления, в совершении которого
                обвинялось такое лицо, в том числе и
                тех, с которыми также заключено досудебное
                соглашение о сотрудничестве. Судья,
                принимавший участие в рассмотрении
                уголовного дела в отношении соучастников
                преступления, в совершении которого
                обвиняется лицо, с которым заключено
                досудебное соглашение о сотрудничестве,
                не может участвовать в рассмотрении
                уголовного дела в отношении такого
                лица.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>7. По поступившему
                в суд уголовному делу в отношении
                обвиняемого, с которым заключено
                досудебное соглашение о сотрудничестве,
                судья выясняет вопросы, указанные
                в&nbsp;статье
                228&nbsp;УПК РФ, и принимает одно из решений
                в соответствии с&nbsp;частью
                1 статьи 227&nbsp;УПК РФ. Исходя из того,
                что в&nbsp;статье
                317.6&nbsp;УПК РФ указаны условия применения
                особого порядка, а не назначения
                уголовного дела к рассмотрению, судье
                при отсутствии оснований для принятия
                решений, указанных в&nbsp;пункте
                1&nbsp;или&nbsp;пункте
                2 части 1 статьи 227&nbsp;УПК РФ, следует
                назначить судебное заседание в особом
                порядке, предусмотренном&nbsp;главой
                40.1&nbsp;УПК РФ.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>8. По уголовному
                делу в отношении обвиняемого, с которым
                заключено досудебное соглашение о
                сотрудничестве, поступившему с
                представлением прокурора об особом
                порядке проведения судебного заседания,
                судья проверяет, имеются ли в материалах
                дела другие необходимые для его
                рассмотрения в особом порядке документы:
                ходатайство о заключении досудебного
                соглашения о сотрудничестве, постановление
                следователя о возбуждении перед
                прокурором ходатайства о заключении с
                подозреваемым или обвиняемым досудебного
                соглашения о сотрудничестве, постановление
                прокурора об удовлетворении ходатайства
                о заключении досудебного соглашения о
                сотрудничестве, досудебное соглашение
                о сотрудничестве (часть
                2 статьи 317.4&nbsp;УПК РФ), а также вручена
                ли обвиняемому и его защитнику копия
                представления прокурора об особом
                порядке проведения судебного заседания
                и вынесения судебного решения (часть
                3 статьи 317.5&nbsp;УПК РФ).</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>При отсутствии
                указанных документов, содержащих в том
                числе сведения о разъяснении последствий,
                предусмотренных&nbsp;частью
                2.1 статьи 317.3&nbsp;УПК РФ, а также сведений
                о вручении обвиняемому и его защитнику
                представления прокурора судья принимает
                решение о назначении предварительного
                слушания для рассмотрения вопроса о
                возвращении уголовного дела прокурору
                в порядке, установленном&nbsp;статьей
                237&nbsp;УПК РФ.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>Уголовное
                дело не подлежит возвращению прокурору,
                если при проведении предварительного
                слушания государственный обвинитель
                представит необходимые документы, в
                том числе подтверждающие факт вручения
                обвиняемому и его защитнику представления
                прокурора, тем самым устранив препятствия
                рассмотрения уголовного дела судом.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>9. В ходе
                предварительного слушания, когда оно
                проводится по основаниям, указанным
                в&nbsp;части
                2 статьи 229&nbsp;УПК РФ, суд проверяет,
                соблюдены ли условия, предусмотренные&nbsp;частями
                1&nbsp;и&nbsp;2
                статьи 317.6&nbsp;УПК РФ. При этом в порядке,
                установленном&nbsp;статьей
                234&nbsp;УПК РФ, вопросы, связанные с
                оценкой судом значения сотрудничества
                с обвиняемым, указанные в&nbsp;части
                4 статьи 317.7&nbsp;УПК РФ, обсуждению не
                подлежат.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>9.1. При проверке
                соблюдения условия, указанного в&nbsp;пункте
                1 части 2 статьи 317.6&nbsp;УПК РФ, суду
                следует иметь в виду, что прокурор,
                государственный обвинитель вправе
                подтвердить активное содействие
                обвиняемого следствию в изобличении и
                уголовном преследовании не только
                других соучастников преступления, в
                котором обвиняется это лицо, но и
                участников иного преступления,
                совершенного без участия подсудимого
                (пункт
                3 части 1 статьи 317.5,&nbsp;пункт
                3 части 4 статьи 317.7&nbsp;УПК РФ).</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>10. Суд вправе
                решить вопрос о рассмотрении уголовного
                дела в закрытом судебном заседании в
                случаях, предусмотренных&nbsp;частью
                2 статьи 241&nbsp;УПК РФ. Постановление о
                рассмотрении уголовного дела в закрытом
                судебном заседании может быть вынесено
                в отношении всего судебного разбирательства
                либо соответствующей его части (часть
                3 статьи 241&nbsp;УПК РФ).</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>В интересах
                обеспечения безопасности участников
                судебного разбирательства, их близких
                родственников, родственников или близких
                лиц (пункт
                4 части 2 статьи 241&nbsp;УПК РФ) такое
                решение может быть принято и в случаях,
                когда в отношении этих лиц меры
                безопасности не применялись.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>11. Исходя
                из&nbsp;части
                4 статьи 231&nbsp;УПК РФ о месте, дате и
                времени судебного заседания государственный
                обвинитель, подсудимый, его защитник,
                а также потерпевший и его представитель,
                гражданский истец и гражданский ответчик
                и их представители должны быть извещены
                не менее чем за 5 суток до его начала.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>Извещение
                указанных лиц посредством СМС-сообщения
                допускается в случае их согласия на
                уведомление таким способом и при фиксации
                факта отправки и доставки СМС-извещения
                адресату. Факт согласия на получение
                СМС-извещения подтверждается распиской,
                в которой наряду с данными об участнике
                судопроизводства и его согласием на
                уведомление подобным способом указывается
                номер мобильного телефона, на который
                оно направляется.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>12. В соответствии
                с&nbsp;частью
                2 статьи 317.7&nbsp;УПК РФ судебное заседание
                проводится с обязательным участием
                подсудимого и его защитника. В силу
                этого отказ подсудимого от защитника
                не может быть принят судом, а ходатайство
                подсудимого о судебном разбирательстве
                в его отсутствие не может быть
                удовлетворено.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>Неявка
                потерпевшего, его законного представителя,
                представителя, а также гражданского
                истца, гражданского ответчика и их
                представителей в судебное заседание
                без уважительных причин при условии их
                надлежащего извещения не является
                препятствием для рассмотрения уголовного
                дела.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>13. По
                смыслу&nbsp;части
                2 статьи 317.6&nbsp;и&nbsp;статьи
                317.7&nbsp;УПК РФ, потерпевший (его законный
                представитель, представитель), гражданский
                истец и его представитель вправе
                участвовать в исследовании рассматриваемых
                судом вопросов, в том числе высказывать
                свое мнение по вопросу об особом порядке
                судебного разбирательства при досудебном
                соглашении о сотрудничестве. Суду
                надлежит проверить все заявленные
                доводы и принять по ним мотивированное
                решение. При этом следует иметь в виду,
                что возражение потерпевшего (его
                законного представителя, представителя),
                гражданского истца и его представителя
                против особого порядка проведения
                судебного заседания в отношении
                подсудимого, с которым заключено
                досудебное соглашение о сотрудничестве,
                само по себе не является основанием для
                рассмотрения дела в общем порядке.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>Постановление
                суда, принятое по ходатайству потерпевшего
                (его законного представителя,
                представителя), гражданского истца и
                его представителя, может быть обжаловано
                сторонами в вышестоящий суд посредством
                принесения жалобы на итоговое судебное
                решение.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>14. В тех
                случаях, когда по делу не проводилось
                предварительное слушание, суд в ходе
                судебного разбирательства проверяет,
                соблюдены ли условия, предусмотренные&nbsp;частью
                2 статьи 317.6&nbsp;УПК РФ. Если будет
                установлено, что эти условия не соблюдены,
                то суд принимает решение о прекращении
                особого порядка судебного разбирательства
                и назначает судебное разбирательство
                в общем порядке (часть
                3 статьи 317.6&nbsp;УПК РФ).</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>15. Разъяснить
                судам, что по смыслу положений&nbsp;статьи
                317.7&nbsp;УПК РФ и&nbsp;статьи
                316&nbsp;УПК РФ одним из условий постановления
                в отношении подсудимого, с которым
                заключено досудебное соглашение о
                сотрудничестве, обвинительного приговора
                является его согласие с предъявленным
                обвинением. В случае, если подсудимый
                не согласен с предъявленным обвинением,
                суд принимает решение о прекращении
                особого порядка судебного разбирательства
                и назначает судебное разбирательство
                в общем порядке.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>Отсутствие
                в досудебном соглашении о сотрудничестве
                указания на все преступления, в которых
                обвиняется подсудимый, не является
                основанием для возвращения уголовного
                дела прокурору в порядке, установленном&nbsp;статьей
                237&nbsp;УПК РФ, при условии согласия
                подсудимого с обвинением в полном
                объеме.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>16. Обратить
                внимание судов, что в судебном
                разбирательстве по уголовному делу в
                отношении подсудимого, с которым
                заключено досудебное соглашение о
                сотрудничестве, суд не проводит в общем
                порядке исследование и оценку
                доказательств, собранных по уголовному
                делу (часть
                5 статьи 316&nbsp;УПК РФ).</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>Проверяя
                обстоятельства, указанные в&nbsp;пунктах
                1,&nbsp;3
                части 4 статьи 317.7&nbsp;УПК РФ, суд
                исследует не только имеющиеся в уголовном
                деле материалы (справки, заявление о
                явке с повинной, протоколы и копии
                протоколов следственных действий и
                т.д.), но и иные представленные сторонами
                документы, в том числе относящиеся к
                другим уголовным делам, возбужденным
                в результате сотрудничества с подсудимым
                (материалы оперативно-розыскной
                деятельности, материалы проверки
                сообщения о преступлении, копии
                постановлений о возбуждении уголовного
                дела и (или) о предъявлении обвинения,
                копии протокола обыска, документа,
                подтверждающего факт получения
                потерпевшим похищенного у него имущества,
                и др.).</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>При исследовании
                характера и пределов содействия
                подсудимого следствию (пункт
                1 части 4 статьи 317.7&nbsp;УПК РФ) следует
                иметь в виду, что такое содействие может
                выражаться, в частности, в даче показаний
                об обстоятельствах совершенного
                преступления и о соучастниках преступления
                с указанием их роли; сообщении о других
                преступлениях, в том числе совершенных
                с участием иных лиц; указании очевидцев
                преступления, мест уничтожения или
                хранения орудий преступления, сокрытия
                трупа, похищенного имущества; выдаче
                предметов и документов, представлении
                аудио-, видеозаписи сведений, имеющих
                значение для дела; участии в проведении
                оперативно-розыскных мероприятий.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>Исследование
                обстоятельств, характеризующих личность
                подсудимого, смягчающих и отягчающих
                наказание, может проводиться в том числе
                путем оглашения дополнительно
                представленных сторонами материалов,
                а также допросов свидетелей.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>16.1. Проведение
                судебного разбирательства в особом
                порядке, когда с обвиняемым заключено
                досудебное соглашение о сотрудничестве,
                не освобождает суд от обязанности
                исследовать вопросы, касающиеся
                гражданского иска, и принять по нему
                решение.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>В частности,
                суд при постановлении обвинительного
                приговора вправе удовлетворить
                гражданский иск, если его требования
                вытекают из обвинения, с которым
                согласился обвиняемый, и не имеется
                препятствий для разрешения его судом
                по существу. При наличии соответствующих
                оснований гражданский иск может быть
                оставлен без рассмотрения, производство
                по нему прекращено, в его удовлетворении
                может быть отказано либо по иску принято
                решение о передаче его на рассмотрение
                в порядке гражданского судопроизводства.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>Вопросы,
                указанные в&nbsp;пунктах
                10.1&nbsp;-&nbsp;12
                части 1 статьи 299&nbsp;УПК РФ, разрешаются
                судом на основании материалов уголовного
                дела с указанием в приговоре мотивов
                принятого решения. При необходимости
                в целях уточнения обстоятельств, значимых
                для разрешения указанных вопросов, суд
                оглашает имеющиеся в деле и дополнительно
                представленные сторонами материалы.</font></font> </p>
                <p STYLE="text-indent: 0in; margin-bottom: 0in; background: #ffffff; line-height: 0.22in">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>17. Если в ходе
                исследования судом степени угрозы
                личной безопасности, которой подвергались
                подсудимый в результате сотрудничества
                со стороной обвинения, его близкие
                родственники, родственники и близкие
                лица (пункт
                4 части 4 статьи 317.7&nbsp;УПК РФ), установлено
                наличие оснований, предусмотренных
                в&nbsp;части
                3 статьи 11&nbsp;УПК РФ, суд вправе применить
                к ним любые меры государственной защиты
                потерпевших, свидетелей и иных участников
                уголовного судопроизводства,
                предусмотренные законодательством
                Российской Федерации, если такое решение
                не было принято органами предварительного
                расследования или прокурором.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>В соответствии
                с&nbsp;пунктами
                4&nbsp;и&nbsp;5
                статьи 18&nbsp;Федерального закона от
                20 августа 2004 года N 119-ФЗ &quot;О государственной
                защите потерпевших, свидетелей и иных
                участников уголовного судопроизводства&quot;
                орган, осуществляющий меры безопасности,
                сам избирает необходимые меры, определяет
                способы их применения и информирует об
                этом суд.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>18. Прения
                сторон, в том числе по вопросам, указанным
                в&nbsp;части
                4 статьи 317.7&nbsp;УПК РФ, проводятся в
                порядке, предусмотренном&nbsp;статьей
                292&nbsp;УПК РФ, после чего подсудимому
                предоставляется последнее слово.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>19. По
                смыслу&nbsp;статьи
                63.1&nbsp;УК РФ,&nbsp;части
                4 статьи 317.6&nbsp;и&nbsp;части
                5 статьи 317.7&nbsp;УПК РФ, если установлено,
                что подсудимым были представлены ложные
                сведения или сокрыты от следователя
                либо прокурора иные существенные
                обстоятельства совершения преступления
                либо его содействие следствию заключалось
                лишь в сообщении сведений о его собственном
                участии в преступной деятельности или
                подсудимым не соблюдены все условия и
                не выполнены все обязательства,
                предусмотренные заключенным с ним
                досудебным соглашением о сотрудничестве,
                суд выносит постановление о прекращении
                особого порядка судебного разбирательства,
                назначении рассмотрения уголовного
                дела в общем порядке и вызове в судебное
                заседание лиц по спискам, представленным
                сторонами. Такое постановление выносится
                судом по правилам&nbsp;части
                2 статьи 256&nbsp;УПК РФ, не предусматривающим
                для этого обязательного удаления суда
                в совещательную комнату и изложения
                принятого решения в виде отдельного
                процессуального документа.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>При этом
                возвращения к стадии назначения судебного
                заседания и вынесения постановления в
                соответствии со&nbsp;статьей
                231&nbsp;УПК РФ, а также повторного
                проведения выполненных судом в ходе
                судебного производства следственных
                и иных процессуальных действий не
                требуется.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>Суд, переходя
                к общему порядку судебного разбирательства,
                обеспечивает сторонам возможность
                дополнительной подготовки к судебному
                заседанию и с этой целью, с учетом мнения
                сторон, объявляет перерыв в судебном
                заседании либо выносит постановление
                о его отложении на определенный срок.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>В случае
                принятия решения о прекращении особого
                порядка судебного разбирательства суд
                по ходатайству стороны или по собственной
                инициативе при наличии к тому оснований
                вправе возвратить уголовное дело
                прокурору для соединения уголовных
                дел.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>20. Исключен.
                </font></font>
                 </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>21. Если суд
                придет к выводу, что в силу тех или иных
                предусмотренных законом оснований
                невозможно проведение судебного
                разбирательства в порядке,
                установленном&nbsp;главой
                40.1&nbsp;УПК РФ, то по уголовным делам о
                преступлениях, указанных в&nbsp;части
                1 статьи 314&nbsp;УПК РФ, в целях обеспечения
                права подсудимого на рассмотрение
                уголовного дела в особом порядке
                судебного разбирательства при согласии
                его с предъявленным обвинением суд
                вправе при наличии соответствующего
                ходатайства подсудимого и согласия
                государственного обвинителя и потерпевшего
                продолжить разбирательство с соблюдением
                требований&nbsp;главы
                40&nbsp;УПК РФ.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>22. Разъяснить,
                что с учетом положений&nbsp;части
                6 статьи 317.7&nbsp;УПК РФ о том, что
                описательно-мотивировочная часть
                обвинительного приговора должна
                содержать описание преступного деяния,
                в совершении которого обвиняется
                подсудимый, суд постановляет обвинительный
                приговор и назначает подсудимому
                наказание, только если придет к выводу,
                что обвинение, с которым согласился
                подсудимый, является обоснованным и
                подтверждается доказательствами,
                собранными по делу.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>22.1. Обвинительный
                приговор должен содержать выводы суда
                о соблюдении подсудимым условий и
                выполнении обязательств, предусмотренных
                заключенным с ним досудебным соглашением
                о сотрудничестве.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>В приговоре,
                в частности, указывается, что обвинение
                обоснованно, подтверждается собранными
                по делу доказательствами, а подсудимый
                понимает существо предъявленного ему
                обвинения и соглашается с ним в полном
                объеме; досудебное соглашение о
                сотрудничестве было заключено им
                добровольно и при участии защитника;
                какое содействие следствию им оказано,
                в чем именно оно выразилось; приводятся
                результаты проведенного в судебном
                заседании исследования обстоятельств,
                указанных в&nbsp;части
                4 статьи 317.7&nbsp;УПК РФ.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>23.&nbsp;Глава
                40.1&nbsp;УПК РФ не содержит норм, запрещающих
                принимать по делу, рассматриваемому в
                особом порядке в связи с досудебным
                соглашением о сотрудничестве, иные,
                кроме обвинительного приговора, судебные
                решения. В частности, содеянное подсудимым
                может быть переквалифицировано, а
                уголовное дело прекращено судом (в связи
                с изменением уголовного закона, истечением
                сроков давности, актом об амнистии и
                т.д.), если для этого не требуется
                исследования собранных по делу
                доказательств и фактические обстоятельства
                при этом не изменяются.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>24. Судам
                следует иметь в виду, что при назначении
                наказания лицу, с которым заключено
                досудебное соглашение о сотрудничестве,
                в соответствии с&nbsp;частью
                2&nbsp;или&nbsp;частью
                4 статьи 62&nbsp;УК РФ положения&nbsp;части
                первой&nbsp;указанной статьи о сроке и
                размере наказания учету не подлежат.
                Положения&nbsp;частей
                2&nbsp;и&nbsp;4
                статьи 62&nbsp;УК РФ не распространяются
                на дополнительные виды наказаний.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>В случае
                установления исключительных обстоятельств,
                а равно при активном содействии
                подсудимого раскрытию группового
                преступления суд вправе применить
                положения&nbsp;статьи
                64&nbsp;УК РФ, в том числе при наличии
                отягчающих обстоятельств.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>Назначая
                наказание лицу, с которым заключено
                досудебное соглашение о сотрудничестве,
                за неоконченное преступление, суд
                руководствуется положениями как&nbsp;статьи
                66&nbsp;УК РФ, так и&nbsp;частью
                2&nbsp;или&nbsp;частью
                4 статьи 62&nbsp;УК РФ.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>При наличии
                совокупности преступлений положения&nbsp;части
                2&nbsp;или&nbsp;части
                4 статьи 62&nbsp;УК РФ применяются при
                назначении наказания за каждое из
                совершенных преступлений.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>Решая вопрос
                о назначении наказания лицу, с которым
                заключено досудебное соглашение о
                сотрудничестве, суд не должен учитывать
                положения&nbsp;части
                7 статьи 316&nbsp;УПК РФ.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>25. В тех
                случаях, когда по уголовному делу
                установлено соблюдение всех условий и
                выполнение всех обязательств,
                предусмотренных в досудебном соглашении
                о сотрудничестве, однако суд в силу тех
                или иных оснований выносит постановление
                о прекращении особого порядка судебного
                разбирательства (например, если содеянное
                подсудимым подлежит переквалификации
                и для этого требуется исследование
                собранных по делу доказательств), при
                судебном разбирательстве в общем порядке
                суду следует назначать подсудимому
                наказание по правилам&nbsp;части
                2&nbsp;или&nbsp;части
                4 статьи 62&nbsp;УК РФ.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>26. Обратить
                внимание судов на то, что с учетом
                положений&nbsp;части
                10 статьи 316&nbsp;УПК РФ процессуальные
                издержки, предусмотренные&nbsp;статьей
                131&nbsp;УПК РФ, взысканию с подсудимого
                не подлежат.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>27. Приговор,
                постановленный в соответствии с
                требованиями&nbsp;статьи
                317.7&nbsp;УПК РФ, не может быть обжалован
                сторонами в суд вышестоящей инстанции
                в связи с несоответствием выводов суда,
                изложенных в приговоре, фактическим
                обстоятельствам дела. Поэтому при
                отсутствии иных доводов и оснований
                для пересмотра судебных решений по делу
                в судах апелляционной и кассационной
                инстанций производство по таким жалобам
                подлежит прекращению.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>28. Вступивший
                в законную силу приговор, постановленный
                в отношении лица, с которым заключено
                досудебное соглашение о сотрудничестве,
                не может предрешать вопрос о виновности
                соучастников, совершивших преступление
                совместно с таким лицом.</font></font> </p>
                <p class="block_1">
                <br>
                 </p>
                <p class="block_1">
                <font FACE="Times New Roman, serif"><font SIZE=3>29. Признать
                утратившим силу&nbsp;пункт 10&nbsp;постановления
                Пленума Верховного Суда Российской
                Федерации от 22 декабря 2009 г. N 28 &quot;О
                применении судами норм уголовно-процессуального
                законодательства, регулирующих подготовку
                уголовного дела к судебному разбирательству&quot;
                (с изменениями, внесенными постановлением
                Пленума от 9 февраля 2012 г. N 3).</font></font> </p>
                <p class="block_2">
                <br><br>
                 </p>
                <p class="block_2">
                <font FACE="Times New Roman, serif"><font SIZE=3><font COLOR="#000000">Председатель<br>Верховного
                Суда<br>Российской Федерации<br>В. Лебедев</font></font></font> </p>
                <p class="block_2">
                <font FACE="Times New Roman, serif"><font SIZE=3><font COLOR="#000000">Секретарь
                Пленума,<br>судья Верховного Суда<br>Российской
                Федерации<br>В. Дорошков</font></font></font> </p>
                <p STYLE="margin-bottom: 0.11in"><br><br>
             </p>

        </div>
    </div>
</template>

<script>
export default {
    name: "apre-trial-agreement",
    data (){
        return{
            page:{
                title:'',
                description: '',
                keywords: '',
                content: ""
            }
        }
    },
    created: function () {
        this.axios.get(`${encodeURI(window.hostname)}/api/getPage?pageName=${encodeURI("apre-trial-agreement")}`).then(
            response => {
                this.page = response.data;
            }
        );
        this.page.title = "Пленумы | О применении досудебного соглашения";
    }
}
</script>

<style scoped>
    .block_ {
        text-indent: 0in;
        margin-top: 0.19in;
        margin-bottom: 0.19in;
        background: #ffffff;
        line-height: 100%;
        text-align: center;
        text-indent: 0;
    }
    .block_1 {
        text-indent: 0.38in;
        margin-bottom: 0in;
        background: #ffffff;
        line-height: 0.22in;
    }
    .block_2 {
        text-indent: 0.49in;
        margin-top: 0.19in;
        margin-bottom: 0.19in;
        background: #ffffff;
        line-height: 100%;
        text-align: right;
        text-indent: 0
    }
    .block_3 {
        display: block;
        line-height: 1.2;
        margin-bottom: 8pt;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-indent: 35.5pt
    }
    .block_4 {
        display: block;
        font-family: serif;
        font-size: 0.75em;
        line-height: 1.08;
        margin-bottom: 8pt;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-indent: 35.5pt
    }
    .block_5 {
        display: block;
        line-height: 1.2;
        margin-bottom: 8pt;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        text-align: right;
        text-indent: 35.5pt
    }
    .calibre {
        color: black;
        display: block;
        font-family: "Times New Roman", serif;
        font-size: 1em;
        margin-bottom: 0;
        margin-left: 5pt;
        margin-right: 5pt;
        margin-top: 0;
        padding-left: 0;
        padding-right: 0
    }
    .calibre1 {
        font-weight: bold;
        line-height: 1.2
    }
    .calibre2 {
        display: block;
        line-height: 1.2
    }

    @page {
        margin-bottom: 5pt;
        margin-top: 5pt
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        .mycontainer {
            padding: 15px;
        }

    }
</style>
